import React, { Fragment, memo, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { arrayPush, change, Field, FieldArray, getFormValues } from "redux-form";
import AmountField from "../../fields/AmountField";
import { itemTypes } from "../itemTypes";
import RenderField from "../../fields/RenderField";
import RenderTPP from "./renderTPP";
import RenderActionsCol from "./renderActionsCol";
import {
  getLineAmount,
  lineAmountAfterLossOfOpportunity,
  lineCapitalisedAmount,
  lineVictimAmount,
  lineVictimDiscountedAmount,
} from "../calculations";
import BtnInputAmount from "../../buttons/BtnInputAmount";
import NumberFormat from "react-number-format";
import LineSummary from "./renderLineSummary";
import DisplayDiscountingForm from "../../discounting/DisplayDiscountingForm";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IndirectAnnuityCapitalisation from "./IndirectAnnuityCapitalisation";
import useMoveUpAndDown from './useMoveUpAndDown';
import { MoveDown } from "./movableIcon";
import { MoveUp } from "./movableIcon";
import { getOverlapStatus } from '../../fields/checkOverlappingPeriods';

const IndirectItemTableBody = props => {
  const { fields, item, victim, indirectVictim, values, dispatch, meta, entry, itemId, victimId, caseValues, editAllPIndperiodRate, editAllPIndDaysPerYear } = props;
  const indirectEntry = useMemo(() => entry + itemId, [entry, itemId]);

  const colSpan = itemTypes.get(item.itemType).length;
  const { moveFieldDown, moveFieldUp, handleEnterLine, handleLeaveLine, hoveredIndex, highlightedIndex } = useMoveUpAndDown(fields);

  // let changeCheckArray = [values[indirectEntry], victim, null, null];
  let changeCheckArray = [values[indirectEntry], victim, null, null];
  if (values["TPP"] && values["TPP"][indirectEntry])
    changeCheckArray[2] = values["TPP"][indirectEntry];
  if (entry === "PInd") {
    if (values["TPP"] && values["TPP"]["PInd0"]) changeCheckArray[2] = values["TPP"]["PInd0"];
    if (itemId === 0 && values["PInd1"]) changeCheckArray[3] = values["PInd1"];
    else if (itemId === 1 && values["PInd0"]) changeCheckArray[3] = values["PInd0"];

  }

  let tppCaption = "";
  if (indirectEntry.indexOf("DNAPRDI") === 0 || indirectEntry.indexOf("AUT") === 0)
    tppCaption = "le préjudice"
  else
    tppCaption = "la dépense"
  tppCaption += " n° "

  const getLineRefVictim = i => {
    let refVictim = victim;
    const caseROCE = victim.rateOfCompensationEntitlement;

    if (values[indirectEntry] && values[indirectEntry][i] && values[indirectEntry][i].refVictim && values[indirectEntry][i].refVictim === "indirect") {
      refVictim = caseValues.indirectVictims[victimId].victim;
      refVictim.rateOfCompensationEntitlement = caseROCE;
      refVictim.birthDate = refVictim.birth_date; // to compensate for bad choice of variable names in direct victim. To be fixed!!!
    }

    return refVictim;
  }

  useEffect(() => {
    if (fields.length === 0) {
      let firstLine = {};

      if (item.annuityCapitalisation === true) {
        firstLine.annuityPercentage = 100;
        firstLine.annuityPeriodicity = 12;
      }

      if (item.itemType === "TPF" || item.itemType === "TPT") {
        firstLine.periodType = "days";
        firstLine.daysPerYear = 365;
        firstLine.weeksPerYear = 52;
        firstLine.monthsPerYear = 12;
        // if (fields.name.indexOf("PInd0") >= 0 && victim && victim.consolidationDate) {
        //     let consolidationDate = new Date(victim.consolidationDate.split("-")[0],parseInt(victim.consolidationDate.split("-")[1])-1,victim.consolidationDate.split("-")[2]);
        //     firstLine.startDate = formatDateField(consolidationDate.getTime() + 86400000);
        // }
      }
      fields.push(firstLine);
    }
  }, changeCheckArray);

  const addTPPLine = index => {
    let newLine = {};
    dispatch(arrayPush("caseDataForm", `${fields.name}[${index}].tpp`, newLine));
  };

  const removeLine = index => {
    fields.remove(index);
  };

  const removeDiscounting = (line, index) => {
    let newLineValues = line;
    delete newLineValues.discounting;
    dispatch(change("caseDataForm", `${fields.name}[${index}]`, newLineValues));
  }

     const displayWarningIndirect = (entry, values, index) => {
            const types = ["PAcc", "PInd"];
            return types.some(type => getOverlapStatus(entry, values, index, type));
        };
  return (
    <tbody onMouseLeave={handleLeaveLine}>
      <Fragment>
        {fields.map((line, index) => (
          <Fragment key={index}>
            <tr key={index}
              style={{ position: "relative" }}
              className={index === highlightedIndex ? "blink" : ""}
              onMouseEnter={() => handleEnterLine(index)}
            >
              <th scope="row" className="col-num align-middle d-flex justify-content-between">
                {item.multiLine && 
                <>
                  {hoveredIndex === index && <div style={{ position: "absolute", left: "-20px", top: "-10px" }}>
                    <MoveUp
                      moveFieldUp={() => moveFieldUp(index)}
                      disabled={index === 0}
                    />
                    <MoveDown
                      moveFieldDown={() => moveFieldDown(index)}
                      disabled={index === fields.length - 1}
                    /></div>}
                  {<span className="d-flex justify-content-center align-items-center" style={{ position: 'relative' }}>{index + 1}.</span>}
                </>
                }
              </th>
              {itemTypes.get(item.itemType).length === 0 ? (
                <td />
              ) : (
                itemTypes.get(item.itemType).map((field, k) => (
                  <td key={k} className={`main-line${field.className ? field.className : ""}`}>
                    <div className="input-group input-group-sm flex-nowrap">
                      <RenderField
                        entry={indirectEntry}
                        item={item}
                        line={line}
                        index={index}
                        field={field}
                        values={values}
                        victim={victim}
                        victimId={victimId}
                        editAllPIndperiodRate={editAllPIndperiodRate}
                        editAllPIndDaysPerYear={editAllPIndDaysPerYear}
                        indirectVictim={indirectVictim}
                        readOnly={
                          item.computedAmount ? values[indirectEntry][index].amountDirectInput : false
                        }
                                                displayWarningIndirect = {displayWarningIndirect(entry, values, index)}
                      />
                    </div>
                  </td>
                ))
              )}
              <td className="col-amount main-line">
                <div className="d-flex">
                  {item.computedAmount && !values[indirectEntry][index].amountDirectInput ? (
                    <AmountField fieldValue={getLineAmount(values, indirectEntry, index, item)} />
                  ) : (
                    <Field
                      name={`${line}.lineAmount`}
                      component={AmountField}
                      readOnly={
                        item.computedAmount ? !values[indirectEntry][index].amountDirectInput : false
                      }
                    />
                  )}
                  {item.computedAmount && (
                    <BtnInputAmount
                      switchInputAmount={() => {
                        dispatch(
                          change(
                            "caseDataForm",
                            `${fields.name}[${index}].lineAmount`,
                            !values[indirectEntry][index].amountDirectInput
                              ? getLineAmount(values, indirectEntry, index, item)
                                .toFixed(2)
                                .replace(".", ",")
                              : null,
                          ),
                        );
                        dispatch(
                          change(
                            "caseDataForm",
                            `${fields.name}[${index}].amountDirectInput`,
                            !values[indirectEntry][index].amountDirectInput,
                          ),
                        );
                      }}
                      amountDirectInput={values[indirectEntry][index].amountDirectInput}
                    />
                  )}
                  {(item.itemType === "DSA" || indirectEntry.indexOf("DNAPRDI") === 0 || indirectEntry.indexOf("PROV") === 0 ||
                                        (item.itemType.indexOf("AUT") === 0 && parseInt(entry.charAt(3)) < 3))
                                        &&
                                        <DisplayDiscountingForm
                                          lineValues={values[indirectEntry][index]}
                                          amount={Math.max(0, lineVictimAmount(values, indirectEntry, index, item, victim))}
                                          entry={indirectEntry}
                                          index={index}
                                          victimId={victimId}
                                        />
                  }
                </div>
              </td>
              <RenderActionsCol
                item={item}
                lineAmount={getLineAmount(values, indirectEntry, index, item).toFixed(2)}
                line={line}
                removeLine={() => removeLine(index)}
                addTPPLine={() => addTPPLine(index)}
              />
            </tr>
            {values[indirectEntry][index].discounting && values[indirectEntry][index].discounting.indexValue && (indirectEntry === "AUT2Cap0") &&
            <tr>
              <td className="col-num pt-0 pb-0" />
              <td colSpan={colSpan} className="text-right align-bottom pt-0 pb-0">
                <em>Préjudice annuel actualisé de la victime</em>
              </td>
              <td className="col-amount text-left align-bottom pt-0 pb-0">
                <AmountField readOnly
                  fieldValue={values[indirectEntry][index].lineAmount ? parseFloat(values[indirectEntry][index].lineAmount.replace(",", ".").replace(/\s/g, "")) * values[indirectEntry][index].discounting.indexValue : 0}
                />
              </td>
              <td className="col-act pt-0 pb-0">
                <button
                  type="button"
                  className="btn btn-outline-danger btn-sm p-0"
                  onClick={() => removeDiscounting(values[indirectEntry][index], index)}
                  title="Supprimer l'actualisation"
                >
                  <HighlightOffIcon />
                </button>
              </td>
            </tr>
            }
            {values[indirectEntry][index].discounting && values[indirectEntry][index].discounting.indexValue && !values[indirectEntry][index].amountDirectInput && (item.itemType === "PGPFProj") && values[indirectEntry][index].periodRate &&
            <tr>
              <td className="col-num pt-0 pb-0" />
              <td className="text-right pt-0">
                <em>Montant actualisé</em> :&nbsp;<NumberFormat
                  displayType="text"
                  thousandSeparator=" "
                  className="discountedTotal pr-1"
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={true}
                  type="text"
                  value={parseFloat(values[indirectEntry][index].periodRate.replace(",", ".").replace(/\s/g, "")) * values[indirectEntry][index].discounting.indexValue}
                  suffix=" €"
                />
                <button
                  type="button"
                  className="btn btn-outline-danger btn-sm p-0 ml-1"
                  onClick={() => removeDiscounting(values[indirectEntry][index], index)}
                  title="Supprimer l'actualisation"
                >
                  <HighlightOffIcon />
                </button>
              </td>
              <td colSpan={colSpan}></td>
              <td className="col-act"></td>
            </tr>
            }

            {values[indirectEntry][index].tpp &&
                            values[indirectEntry][index].tpp.length > 0 &&
                            indirectEntry.indexOf("PROV") !== 0 && (
                            <FieldArray
                name={`${line}.tpp`}
                colSpan={colSpan}
                line={line}
                component={RenderTPP}
                entry={indirectEntry}
                lineIndex={index}
                tppValues={values[indirectEntry][index].tpp}
                entryMeta={meta[indirectEntry]}
                annCap={item.annuityCapitalisation}
                caption={(indirectEntry.indexOf("AUT") === 0 && values[indirectEntry][index].label && values[indirectEntry][index].label.trim().length > 0) ? values[indirectEntry][index].label : tppCaption + (index + 1)}
                lineAmount={lineAmountAfterLossOfOpportunity(values, indirectEntry, index, item)}
              />
            )}
            {(!item.annuityCapitalisation && item.multiLine) && (
              <LineSummary
                colSpan={colSpan}
                values={values}
                entry={indirectEntry}
                lineIndex={index}
                item={item}
                victim={victim}
              />
            )}
            {item.annuityCapitalisation && (
              <IndirectAnnuityCapitalisation
                colSpan={colSpan}
                item={item}
                line={line}
                lineIndex={index}
                entry={indirectEntry}
                values={values}
                victim={victim}
                indirectVictim={caseValues.indirectVictims[victimId].victim}
                meta={meta}
                victimId={victimId}
                lineAmount={Math.max(
                  lineVictimDiscountedAmount(values, indirectEntry, index, item, victim),
                  0,
                )}
                lineCapitalisedAmount={lineCapitalisedAmount(
                  values,
                  indirectEntry,
                  index,
                  item,
                  getLineRefVictim(index),
                )}
              />
            )}
          </Fragment>
        ))}
      </Fragment>
    </tbody>
  );
};

IndirectItemTableBody.propTypes = {
  fields: PropTypes.object,
  item: PropTypes.object,
  victim: PropTypes.object,
  values: PropTypes.object,
  meta: PropTypes.object,
  dispatch: PropTypes.func,
  entry: PropTypes.string,
  itemId: PropTypes.number,
  caseValues: PropTypes.object,
  victimId: PropTypes.number,
};


const mapStateToProps = (state, ownProps) => {
  let caseValues = getFormValues("caseDataForm")(state);
  let values = {};
  if (caseValues && caseValues.indirectVictims && caseValues.indirectVictims[ownProps.victimId])
    values = caseValues.indirectVictims[ownProps.victimId].caseData;

  return { caseValues, values };
};


function memoCompare(prev, next) {
  const prevStr = JSON.stringify(prev, null, " ")
  const nextStr = JSON.stringify(next, null, " ")

  return prevStr == nextStr
}

export default connect(mapStateToProps)(memo(IndirectItemTableBody, memoCompare));
