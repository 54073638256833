import React from "react";
import PropTypes from "prop-types";

export default function ItemTableFooterCascadeTotal(props) {
  const { colSpan, entryName, total, cascadedTotal, victim } = props;
  let cascadeColSpan = colSpan + 1;

  let frenchCascadedTotal = Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(Math.abs(cascadedTotal))

  let remainder = total;
  //if (cascadedTotal < 0) remainder += cascadedTotal

  let frenchRemainder = Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(Math.abs(remainder))

  let frenchTotal = Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(Math.abs(total))

  return (
    <tr>
      <td className="col-num" />
      <td colSpan={cascadeColSpan} className="text-right align-bottom">
        <small className="text-danger">
          {victim.disableCascade && remainder < 0 && <strong>Le reliquat de paiement de tiers payeur(s) non imputé de {frenchTotal} n'est pas reporté automatiquement sur les autres postes de préjudice.</strong>}

          {entryName === "IP0" && !victim.disableCascade && (
            <React.Fragment>
              {cascadedTotal < 0 &&
                <React.Fragment>
                  Le reliquat de paiement de tiers payeur(s) non imputé sur les PGPF de {frenchCascadedTotal} est imputé sur ce poste.<br />
                </React.Fragment>
              }
              {total < 0 &&
                <strong>Le reliquat de paiement de tiers payeur(s) non imputé de {frenchTotal} n'est pas reporté automatiquement sur les autres postes de préjudice.</strong>
              }
            </React.Fragment>
          )}
          {/* {entryName === "DFP0" && !victim.disableCascade && (
            <React.Fragment>
              {cascadedTotal < 0 &&
                <React.Fragment>
                  Le reliquat de paiement de tiers payeur(s) non imputé sur les PGPF et l'IP de {frenchCascadedTotal} est imputé sur ce poste.<br />
                </React.Fragment>
              }
              {total < 0 &&
                <strong>Le reliquat de paiement de tiers payeur(s) non imputé de {frenchRemainder} n'est pas reporté automatiquement sur les autres postes de préjudice.</strong>
              }
            </React.Fragment>
          )} */}
        </small>
      </td>
      <td className="col-act" />
    </tr>
  );
}
ItemTableFooterCascadeTotal.propTypes = {
  colSpan: PropTypes.number,
  entryName: PropTypes.string,
  total: PropTypes.number,
  annuityTotal: PropTypes.number,
  cascadedTotal: PropTypes.number,
  victim: PropTypes.object,
};
