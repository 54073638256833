var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { change, submit, Field, reduxForm, FormSection } from "redux-form";
import { AppBar, Backdrop, Box, Fade, Grid, IconButton, InputAdornment, Modal, Paper, Tab, Tabs, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, } from "@material-ui/core";
import { ThemeProvider } from '@material-ui/core/styles';
import { TrendingUp, GetApp, Save, Close, Info } from '@material-ui/icons/';
import { formatFrenchDate } from "./utils";
import axios from "axios";
import { localTheme, palette, useStyles } from "./theme";
import { legalInterestRates } from "./constants";
import { EditableTabLabel } from "./EditableTabLabel";
import { RenderSwitchField, RenderTextField, RenderInterestRateSelection, RenderTextFieldWithDropdown } from "./RenderFields";
import { StyledTableCell, StyledTableRow } from "./TableComponents";
import { calculateInterest } from "./calculations";
import { helpEntries } from "../physical_injury/constants";
var DEFAULT_NEW_INTEREST = {
    title: "",
    version: 1 // interests feature version as of 2025-02-12
};
var CaseInterestsButton = function (props) {
    var _a, _b;
    var interests = props.interests, token = props.token, caseId = props.caseId, dirty = props.dirty;
    var dispatch = useDispatch();
    var classes = useStyles();
    var _c = React.useState(false), open = _c[0], setOpen = _c[1];
    var _d = React.useState(false), deleteDialogOpen = _d[0], setDeleteDialogOpen = _d[1];
    var _e = React.useState(null), tabToDelete = _e[0], setTabToDelete = _e[1];
    var handleOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var handleDeleteTab = function (index) {
        setTabToDelete(index);
        setDeleteDialogOpen(true);
    };
    var handleConfirmDelete = function () {
        if (tabToDelete !== null) {
            var newInterestsList = interests.interestsList;
            newInterestsList.splice(tabToDelete, 1);
            if (newInterestsList.length === 0) {
                newInterestsList = [DEFAULT_NEW_INTEREST];
            }
            dispatch(change("interestsForm", "interestsList", newInterestsList));
            dispatch(change("interestsForm", "activeTab", Math.min(interests.activeTab, newInterestsList.length - 1)));
            setDeleteDialogOpen(false);
            setTabToDelete(null);
        }
    };
    var handleCancelDelete = function () {
        setDeleteDialogOpen(false);
        setTabToDelete(null);
    };
    var handleAddInterests = function () {
        var newInterests = DEFAULT_NEW_INTEREST;
        var newInterestsList = interests.interestsList;
        newInterestsList.push(newInterests);
        dispatch(change("interestsForm", "interestsList", newInterestsList));
        dispatch(change("interestsForm", "activeTab", newInterestsList.length - 1));
    };
    var currentInterestsTab = interests.activeTab || 0;
    var handleChangeTab = function (event, newValue) {
        dispatch(change("interestsForm", "activeTab", newValue));
    };
    var getInterestTitle = function (index) {
        var interestTitle = "Calcul d'intérêts n° " + (index + 1);
        if (interests.interestsList && interests.interestsList[index] && interests.interestsList[index].title) {
            interestTitle = interests.interestsList[index].title;
        }
        return interestTitle;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { type: "button", className: "btn btn-secondary btn-sm", style: { border: '1px solid #40A5F4', color: '#40A5F4', fontWeight: 800 }, onClick: handleOpen },
            React.createElement(TrendingUp, null),
            "\u00A0 Calcul d'int\u00E9r\u00EAts (b\u00EAta)"),
        React.createElement(ThemeProvider, { theme: localTheme },
            React.createElement(Modal, { "aria-labelledby": "transition-modal-title", "aria-describedby": "transition-modal-description", className: classes.modal, open: open, onClose: handleClose, closeAfterTransition: true, BackdropComponent: Backdrop, BackdropProps: {
                    timeout: 500,
                } },
                React.createElement(Fade, { in: open },
                    React.createElement(Box, { style: { height: '100%', position: 'relative' } },
                        React.createElement(Box, { className: classes.paper, style: { position: 'relative', boxShadow: 'none' } },
                            React.createElement(IconButton, { "aria-label": "close", className: classes.closeButton, onClick: handleClose, style: { position: 'absolute', top: 0, right: 0, zIndex: 1 } },
                                React.createElement(Close, null)),
                            React.createElement(Typography, { id: "transition-modal-title", variant: "h2", component: "h2" }, "Calculs d'int\u00E9r\u00EAts (version b\u00EAta)"),
                            React.createElement("div", { className: "sticky-top sticky-offset-tabs d-flex" },
                                React.createElement("div", { style: { width: "100%", marginTop: 15 } },
                                    React.createElement(AppBar, { position: "sticky", color: "transparent", variant: "outlined", className: classes.appBar },
                                        React.createElement(Tabs, { value: currentInterestsTab, onChange: handleChangeTab, indicatorColor: "primary", textColor: "primary", variant: "scrollable", scrollButtons: "auto", "aria-label": "scrollable auto tabs example", style: { minHeight: "52px" } },
                                            interests.interestsList ?
                                                interests.interestsList.map(function (item, i) {
                                                    return React.createElement(Tab, { key: i, label: React.createElement(EditableTabLabel, { tabId: i, currentTitle: getInterestTitle(i), onSave: function (tabId, newTitle) {
                                                                dispatch(change("interestsForm", "interestsList[".concat(tabId, "].title"), newTitle));
                                                            }, onDelete: handleDeleteTab }), className: currentInterestsTab === i ? classes.editingTab : classes.tabWrapper });
                                                }) : null,
                                            React.createElement(Tab, { label: "+\u00A0Ajouter", className: "".concat(classes.tabWrapper, " ").concat(classes.addButtonTab), onClick: function () { return handleAddInterests(); } }))),
                                    React.createElement(Dialog, { open: deleteDialogOpen, onClose: handleCancelDelete },
                                        React.createElement(DialogTitle, null, "Confirmer la suppression du calcul"),
                                        React.createElement(DialogContent, null,
                                            "\u00CAtes-vous s\u00FBr(e) de vouloir supprimer l'onglet ",
                                            React.createElement("strong", null, getInterestTitle(tabToDelete)),
                                            " ?"),
                                        React.createElement(DialogActions, null,
                                            React.createElement(Button, { onClick: handleCancelDelete, color: "primary" }, "Annuler"),
                                            React.createElement(Button, { onClick: handleConfirmDelete, color: "primary", variant: "contained" }, "Confirmer"))),
                                    interests.interestsList ?
                                        interests.interestsList.map(function (item, i) {
                                            return React.createElement(InterestsTab, { interestData: item, visible: currentInterestsTab === i, index: i, key: i });
                                        })
                                        :
                                            null))),
                        React.createElement(Box, { className: classes.footer, style: {
                                paddingRight: localTheme.spacing(4),
                                paddingLeft: localTheme.spacing(4),
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            } },
                            React.createElement(Box, { style: { flexGrow: 1 } }, interests.interestsList && interests.interestsList[currentInterestsTab] && displayInterestResults(interests.interestsList[currentInterestsTab]) && ((_a = interests.interestsList[currentInterestsTab].results) === null || _a === void 0 ? void 0 : _a.summary) ?
                                React.createElement(Table, { size: "small", style: {
                                        width: 'auto',
                                        borderCollapse: 'separate',
                                        borderSpacing: '0',
                                        marginRight: '20px'
                                    } },
                                    React.createElement(TableBody, null,
                                        React.createElement(TableRow, null,
                                            React.createElement(TableCell, { style: {
                                                    backgroundColor: palette.secondary.light,
                                                    padding: '8px 16px',
                                                    fontWeight: 'bold',
                                                    border: 'none',
                                                    minWidth: '120px',
                                                    color: palette.secondary.main
                                                } }, "Total int\u00E9r\u00EAts"),
                                            React.createElement(TableCell, { align: "right", style: {
                                                    backgroundColor: palette.secondary.light,
                                                    padding: '8px 16px',
                                                    borderLeft: 'none',
                                                    borderTop: 'none',
                                                    borderBottom: 'none',
                                                    borderRight: "1px solid ".concat(palette.secondary.extraLight),
                                                    minWidth: '120px',
                                                    fontWeight: 'bold',
                                                    color: palette.secondary.main
                                                } }, interests.interestsList[currentInterestsTab].results.summary.interestsTotal && Intl.NumberFormat("fr-FR", {
                                                style: "currency",
                                                currency: "EUR"
                                            }).format(interests.interestsList[currentInterestsTab].results.summary.interestsTotal)),
                                            React.createElement(TableCell, { style: {
                                                    backgroundColor: palette.secondary.light,
                                                    padding: '8px 16px',
                                                    fontWeight: 'normal',
                                                    border: 'none',
                                                    minWidth: '120px',
                                                    color: palette.secondary.main
                                                } }, "Cr\u00E9ance"),
                                            React.createElement(TableCell, { align: "right", style: {
                                                    backgroundColor: palette.secondary.light,
                                                    padding: '8px 16px',
                                                    border: 'none',
                                                    minWidth: '120px',
                                                    fontWeight: 'normal',
                                                    color: palette.secondary.main
                                                } }, interests.interestsList[currentInterestsTab].results.summary.baseAmount && Intl.NumberFormat("fr-FR", {
                                                style: "currency",
                                                currency: "EUR"
                                            }).format(interests.interestsList[currentInterestsTab].results.summary.baseAmount)),
                                            React.createElement(TableCell, { style: {
                                                    backgroundColor: '#ffffff',
                                                    padding: '8px 16px',
                                                    fontWeight: 'bold',
                                                    border: "1px solid ".concat(palette.secondary.light),
                                                    borderRight: 'none',
                                                    minWidth: '120px',
                                                    color: palette.secondary.main
                                                } }, "Total"),
                                            React.createElement(TableCell, { align: "right", style: {
                                                    backgroundColor: '#ffffff',
                                                    padding: '8px 16px',
                                                    border: "1px solid ".concat(palette.secondary.light),
                                                    borderLeft: 'none',
                                                    borderTopRightRadius: '8px',
                                                    borderBottomRightRadius: '8px',
                                                    minWidth: '120px',
                                                    fontWeight: 'bold',
                                                    color: palette.secondary.main
                                                } }, interests.interestsList[currentInterestsTab].results.summary.total && Intl.NumberFormat("fr-FR", {
                                                style: "currency",
                                                currency: "EUR"
                                            }).format(interests.interestsList[currentInterestsTab].results.summary.total)))))
                                : React.createElement(React.Fragment, null)),
                            React.createElement(Box, { style: { display: 'flex', gap: localTheme.spacing(2) } },
                                React.createElement(Button, { variant: "contained", color: "primary", startIcon: React.createElement(Save, null), onClick: function () { dispatch(submit("caseDataForm")); } }, "Enregistrer"),
                                React.createElement(Button, { variant: "contained", color: "primary", startIcon: React.createElement(GetApp, null), disabled: !(((_b = interests.interestsList) === null || _b === void 0 ? void 0 : _b.length) >= currentInterestsTab && interests.interestsList[currentInterestsTab] && displayInterestResults(interests.interestsList[currentInterestsTab])), onClick: handleDownload(currentInterestsTab, caseId, token, dirty, dispatch) }, "T\u00E9l\u00E9charger")))))))));
};
var handleDownload = function (interestId, caseId, token, dirty, dispatch) {
    var url = "/api/gen/".concat(caseId, "/?t=interests&i=").concat(interestId);
    return function () {
        // Function to perform the download
        var performDownload = function () {
            var header = {
                Authorization: "Token ".concat(token)
            };
            axios({
                url: url,
                method: 'GET',
                responseType: 'blob',
                headers: header,
            })
                .then(function (response) {
                var _a = response.headers['content-disposition'].split('filename='), filename = _a[1];
                var url = window.URL.createObjectURL(new Blob([response.data]));
                var link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        };
        // If there are unsaved changes, save the form first and wait for it to complete
        if (dirty) {
            // Dispatch the submit action to save the form
            dispatch(submit("caseDataForm"));
            // Use a delay to allow the save operation to complete
            // This is a fallback approach since we can't rely on promises in this context
            setTimeout(performDownload, 1000);
        }
        else {
            // No unsaved changes, proceed with download immediately
            performDownload();
        }
    };
};
function InterestsTab(props) {
    var visible = props.visible, index = props.index, interestData = props.interestData;
    if (!visible) {
        return null;
    }
    return React.createElement("div", null,
        React.createElement(FormSection, { name: "interestsList[".concat(index, "]"), component: InterestsForm, interestData: interestData, interestId: index }));
}
var displayInterestResults = function (interestData) {
    return interestData.baseAmount &&
        interestData.baseAmount > 0 &&
        interestData.startDate &&
        interestData.endDate &&
        interestData.referenceRate && interestData.referenceRate.selected &&
        (interestData.referenceRate.selected !== 'custom' || (interestData.referenceRate.input && interestData.referenceRate.input > 0));
};
function InterestsForm(props) {
    var _a;
    var interestId = props.interestId, interestData = props.interestData;
    var classes = useStyles();
    var dispatch = useDispatch();
    var _b = React.useState(false), showMajoriseTooltip = _b[0], setShowMajoriseTooltip = _b[1];
    var _c = React.useState(false), showAnatocismTooltip = _c[0], setShowAnatocismTooltip = _c[1];
    var handleMultiplyChange = function (multiplyRate) {
        if (multiplyRate) {
            if (interestData.startDate) {
                dispatch(change("interestsForm", "interestsList[".concat(interestId, "].multiplyStartFrom"), interestData.startDate));
            }
        }
        else {
            dispatch(change("interestsForm", "interestsList[".concat(interestId, "].multiplyStartFrom"), ''));
            dispatch(change("interestsForm", "interestsList[".concat(interestId, "].multiplyBy"), ''));
        }
    };
    var handleMajoriseChange = function (majoriseRate) {
        if (majoriseRate && interestData.startDate) {
            // Create a UTC date and add 2 months
            var date = new Date(interestData.startDate + 'T00:00:00.000Z');
            var newDate = new Date(date.setMonth(date.getUTCMonth() + 2));
            var newDay = newDate.getUTCDate();
            var newMonth = newDate.getUTCMonth();
            var newYear = newDate.getFullYear();
            // Create new date in YYYY-MM-DD format
            var majoriseStartFrom = "".concat(newYear, "-").concat(String(newMonth + 1).padStart(2, '0'), "-").concat(String(newDay).padStart(2, '0'));
            dispatch(change("interestsForm", "interestsList[".concat(interestId, "].majoriseStartFrom"), majoriseStartFrom));
            setShowMajoriseTooltip(true);
            setTimeout(function () { return setShowMajoriseTooltip(false); }, 3000);
        }
        else {
            dispatch(change("interestsForm", "interestsList[".concat(interestId, "].majoriseStartFrom"), ''));
            dispatch(change("interestsForm", "interestsList[".concat(interestId, "].majoriseBy"), { value: "", unit: "points" }));
        }
    };
    var handleAnatocismChange = function (anatocism) {
        if (anatocism && interestData.startDate) {
            // Create a UTC date
            var date = new Date(interestData.startDate + 'T00:00:00.000Z');
            var startDay = date.getUTCDate();
            var startMonth = date.getUTCMonth();
            var nextYear = date.getUTCFullYear() + 1;
            // Handle February 29th special case
            var targetDay = startDay;
            if (startMonth === 1 && startDay === 29) { // February is month 1 (0-based)
                // Check if next year is not a leap year
                var nextYearFeb29 = new Date(Date.UTC(nextYear, 1, 29));
                if (nextYearFeb29.getUTCMonth() !== 1) { // If March, then Feb 29 didn't exist
                    targetDay = 28; // Use Feb 28th instead
                }
            }
            var anatocismStartFrom = "".concat(nextYear, "-").concat(String(startMonth + 1).padStart(2, '0'), "-").concat(String(targetDay).padStart(2, '0'));
            dispatch(change("interestsForm", "interestsList[".concat(interestId, "].anatocismStartFrom"), anatocismStartFrom));
            setShowAnatocismTooltip(true);
            setTimeout(function () { return setShowAnatocismTooltip(false); }, 3000);
        }
        else {
            dispatch(change("interestsForm", "interestsList[".concat(interestId, "].anatocismStartFrom"), ''));
        }
    };
    useEffect(function () {
        dispatch(change("interestsForm", "interestsList[".concat(interestId, "].results"), calculateInterest(interestData, legalInterestRates)));
    }, [
        interestData.baseAmount,
        interestData.startDate,
        interestData.endDate,
        interestData.referenceRate,
        interestData.multiplyRate,
        interestData.multiplyBy,
        interestData.multiplyStartFrom,
        interestData.majoriseRate,
        interestData.majoriseBy,
        interestData.majoriseStartFrom,
        interestData.anatocism,
        interestData.anatocismStartFrom,
    ]);
    var displayActualRateCol = interestData.multiplyRate || interestData.majoriseRate;
    var displayResults = displayInterestResults(interestData);
    var majoriseUnitDropdownItems = [
        { key: 'points', value: 'pt(s)' },
        { key: 'percent', value: '%' }
    ];
    var isPaos = ((_a = interestData.referenceRate) === null || _a === void 0 ? void 0 : _a.selected) === 'paos';
    return React.createElement("div", { className: 'mt-4' },
        React.createElement("div", { style: { display: "flex", gap: 16, marginBottom: 15 } },
            React.createElement(Grid, { container: true, justify: "flex-start", spacing: 2, alignItems: "stretch", alignContent: "stretch" },
                React.createElement(Grid, { item: true },
                    React.createElement(Field, { name: "baseAmount", component: RenderTextField, label: "Cr\u00E9ance", type: "number", required: true, placeholder: "Montant", InputProps: {
                            endAdornment: React.createElement(InputAdornment, { position: "end" }, "\u20AC"),
                            style: { maxWidth: 150 }
                        } })),
                React.createElement(Grid, { item: true },
                    React.createElement(Field, { name: "referenceRate", component: RenderInterestRateSelection, label: "Choix du taux", required: true, style: { maxWidth: 150 } })),
                React.createElement(Grid, { item: true },
                    React.createElement(Field, { name: "startDate", component: RenderTextField, label: isPaos ? "Date de demande" : "Date de début", type: "date", required: true, InputProps: {
                            style: { maxWidth: 150 },
                            inputProps: { max: interestData.endDate ? interestData.endDate : "" }
                        } }),
                    isPaos && React.createElement(Tooltip, { title: helpEntries.paosStartDate, leaveDelay: 200 },
                        React.createElement(Info, { fontSize: "small", style: { marginLeft: 4, marginTop: 8, fill: palette.secondary.main500 } }))),
                React.createElement(Grid, { item: true },
                    React.createElement(Field, { name: "endDate", component: RenderTextField, label: isPaos ? "Date de règlement" : "Date de fin", type: "date", required: true, InputProps: {
                            style: { maxWidth: 150 },
                            inputProps: { min: interestData.startDate ? interestData.startDate : "" }
                        } })),
                React.createElement(Grid, { item: true, className: classes.commentField },
                    React.createElement(Field, { name: "comment", component: RenderTextField, label: "Commentaire", multiline: true, type: "text", className: "w-100", helperText: "Ce commentaire sera ins\u00E9r\u00E9 \u00E0 la suite du titre dans l'export Word" })))),
        !isPaos &&
            React.createElement("div", { style: { marginLeft: -15, display: "flex", gap: 8 } },
                React.createElement(Field, { name: "multiplyRate", component: RenderSwitchField, onChange: function (event, newValue, previousValue, name) { return handleMultiplyChange(newValue); }, label: "Multiplier le taux d'int\u00E9r\u00EAt" }),
                interestData.multiplyRate &&
                    React.createElement(React.Fragment, null,
                        React.createElement(Field, { name: "multiplyBy", component: RenderTextField, label: "par", type: "number", placeholder: "x", InputProps: {
                                style: { maxWidth: "60px", minWidth: "50px" },
                            } }),
                        React.createElement(Field, { name: "multiplyStartFrom", component: RenderTextField, label: "\u00E0 compter du", type: "date", InputProps: {
                                style: { marginRight: 15, maxWidth: 150, minWidth: 140 },
                                inputProps: {
                                    min: interestData.startDate ? interestData.startDate : "",
                                    max: interestData.endDate ? interestData.endDate : ""
                                }
                            } })),
                React.createElement(Field, { name: "majoriseRate", component: RenderSwitchField, onChange: function (event, newValue, previousValue, name) { return handleMajoriseChange(newValue); }, label: "Majorer le taux d'int\u00E9r\u00EAt" }),
                interestData.majoriseRate &&
                    React.createElement(React.Fragment, null,
                        React.createElement(Field, { name: "majoriseBy", component: RenderTextFieldWithDropdown, label: "de", type: "number", placeholder: "x", defaultDropdownKey: "points", arrayIndex: interestId, dropdownItems: majoriseUnitDropdownItems, InputProps: {
                                style: { maxWidth: "105px", minWidth: "90px" },
                            } }),
                        React.createElement(Tooltip, { open: showMajoriseTooltip, title: "Suggestion : date de d\u00E9but + 2 mois", placement: "top", arrow: true },
                            React.createElement("div", null,
                                React.createElement(Field, { name: "majoriseStartFrom", component: RenderTextField, label: "\u00E0 compter du", type: "date", onFocus: function () {
                                        // Return a dummy function instead of trying to use stopPropagation
                                        return function () { };
                                    }, InputProps: {
                                        style: { marginRight: 15, maxWidth: 150, minWidth: 140 },
                                        inputProps: {
                                            min: interestData.startDate ? interestData.startDate : "",
                                            max: interestData.endDate ? interestData.endDate : ""
                                        }
                                    } })))),
                React.createElement(Field, { name: "anatocism", component: RenderSwitchField, onChange: function (event, newValue, previousValue, name) { return handleAnatocismChange(newValue); }, label: "Anatocisme", helpText: "anatocism" }),
                interestData.anatocism &&
                    React.createElement(Tooltip, { open: showAnatocismTooltip, title: "Suggestion : date de d\u00E9but + 1 an", placement: "top", arrow: true },
                        React.createElement("div", null,
                            React.createElement(Field, { name: "anatocismStartFrom", component: RenderTextField, label: "\u00E0 compter du", type: "date", onFocus: function () {
                                    // Return a dummy function instead of trying to use stopPropagation
                                    return function () { };
                                }, InputProps: {
                                    style: { maxWidth: 150, minWidth: 140 },
                                    inputProps: {
                                        min: interestData.startDate ? interestData.startDate : "",
                                        max: interestData.endDate ? interestData.endDate : ""
                                    }
                                } })))),
        displayResults ?
            React.createElement(React.Fragment, null,
                React.createElement(Paper, { className: classes.root, style: { minWidth: 1030, marginTop: 0, borderBottom: "1px solid ".concat(palette.secondary.main200), }, elevation: 0 },
                    React.createElement(TableContainer, { className: classes.container },
                        React.createElement(Table, { size: "small", className: classes.table, stickyHeader: true, "aria-label": "customized table" },
                            React.createElement(TableHead, null,
                                React.createElement(TableRow, null,
                                    React.createElement(StyledTableCell, { align: "center", style: { width: isPaos ? 150 : 210 } }, isPaos ? "Mois" : "Période"),
                                    !isPaos && React.createElement(StyledTableCell, { align: "center", style: { width: 115 } }, "Nb de jours"),
                                    React.createElement(StyledTableCell, { align: "center", style: { width: 180 } },
                                        "Cr\u00E9ance",
                                        interestData.anatocism ? " avec intérêts échus (anatocisme)" : ""),
                                    React.createElement(StyledTableCell, { align: "center", style: { width: 115 } }, "Tx d'int\u00E9r\u00EAt de base"),
                                    (isPaos || displayActualRateCol) && React.createElement(StyledTableCell, { align: "center", style: { width: 115 } }, "Tx d'int\u00E9r\u00EAt appliqu\u00E9"),
                                    React.createElement(StyledTableCell, { align: "center", style: { width: 150 } }, "Int\u00E9r\u00EAts calcul\u00E9s sur la p\u00E9riode"),
                                    React.createElement(StyledTableCell, { align: "center", style: { width: 150 } },
                                        "Int\u00E9r\u00EAts",
                                        interestData.anatocism ? " échus" : "",
                                        " cumul\u00E9s"),
                                    React.createElement(StyledTableCell, { align: "left" }, "Commentaires sur la p\u00E9riode"))),
                            React.createElement(TableBody, null, interestData.results && interestData.results.periods && interestData.results.periods.map(function (row, i) {
                                var _a;
                                return (React.createElement(StyledTableRow, { key: i },
                                    React.createElement(StyledTableCell, { align: "center" }, isPaos ? row.startDate : "".concat(formatFrenchDate(row.startDate), " - ").concat(formatFrenchDate(row.endDate))),
                                    !isPaos && React.createElement(StyledTableCell, { align: "center" }, row.nbDays),
                                    React.createElement(StyledTableCell, { align: "right" }, Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(row.periodBaseAmount)),
                                    React.createElement(StyledTableCell, { align: "center" }, (isPaos && row.baseInterestRate === 0) ? "n/a" : ((_a = row.baseInterestRateSourceUrl) === null || _a === void 0 ? void 0 : _a.length) > 0 ?
                                        React.createElement("a", { href: row.baseInterestRateSourceUrl, target: "_blank", title: row.baseInterestRateSource.length > 0 ? row.baseInterestRateSource : "Voir la source", rel: "noreferrer" }, Intl.NumberFormat("fr-FR", {
                                            style: "percent",
                                            minimumFractionDigits: isPaos ? 4 : 2,
                                            maximumFractionDigits: isPaos ? 4 : 2
                                        }).format(row.baseInterestRate / 100))
                                        :
                                            Intl.NumberFormat("fr-FR", {
                                                style: "percent",
                                                minimumFractionDigits: isPaos ? 4 : 2,
                                                maximumFractionDigits: isPaos ? 4 : 2
                                            }).format(row.baseInterestRate / 100)),
                                    (isPaos || displayActualRateCol) && React.createElement(StyledTableCell, { align: "center" }, (isPaos && row.baseInterestRate === 0) ? "n/a" : Intl.NumberFormat("fr-FR", {
                                        style: "percent",
                                        minimumFractionDigits: isPaos ? 4 : 2,
                                        maximumFractionDigits: isPaos ? 4 : 2
                                    }).format(row.interestRate / 100)),
                                    React.createElement(StyledTableCell, { align: "right" }, Intl.NumberFormat("fr-FR", {
                                        style: "currency",
                                        currency: "EUR"
                                    }).format(row.interestAmount)),
                                    React.createElement(StyledTableCell, { align: "right" }, Intl.NumberFormat("fr-FR", {
                                        style: "currency",
                                        currency: "EUR"
                                    }).format(row.cumulativeInterestAmount)),
                                    React.createElement(StyledTableCell, { align: "left", style: { whiteSpace: 'pre-line' } }, row.comment.replace("\n/g", '<br>'))));
                            })))))) : React.createElement(React.Fragment, null));
}
var mapStateToProps = function (state) {
    var newInterestCalc = DEFAULT_NEW_INTEREST;
    var initialValues = { activeTab: 0, interestsList: [newInterestCalc] };
    var token = '';
    var caseId = 0;
    var dirty = false;
    if (state.auth && state.auth.token)
        token = state.auth.token;
    if (state.cases && state.cases.case && state.cases.case.id)
        caseId = state.cases.case.id;
    if (state.form && state.form.interestsForm && state.form.interestsForm.dirty)
        dirty = state.form.interestsForm.dirty;
    if (state.cases && state.cases.case && state.cases.case.interests_data) {
        var interestsList = state.cases.case.interests_data.interestsList || [newInterestCalc];
        // Handle existing data: transform majoriseBy and multiplyBy, and set multiplyStartFrom for interests without version attribute
        interestsList.forEach(function (interest) {
            if (!interest.version) {
                // Handle multiplyStartFrom
                if (interest.multiplyRate && !interest.multiplyStartFrom && interest.startDate) {
                    interest.multiplyStartFrom = interest.startDate;
                }
                // Handle multiplyBy transformation from string to number
                if (typeof interest.multiplyBy === 'string') {
                    interest.multiplyBy = parseFloat(interest.multiplyBy);
                }
                // Handle majoriseBy transformation from number to object
                if (typeof interest.majoriseBy === 'number') {
                    interest.majoriseBy = {
                        value: interest.majoriseBy,
                        unit: 'points'
                    };
                }
                // Set version after transformations
                interest.version = 1;
            }
        });
        initialValues = {
            activeTab: state.cases.case.interests_data.activeTab,
            interestsList: interestsList
        };
    }
    return { initialValues: initialValues, token: token, caseId: caseId, dirty: dirty };
};
var validate = function (values) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var _j, _k, _l, _m, _o, _p, _q;
    var errors = {};
    var interestsList = values.interestsList, activeTab = values.activeTab;
    if (interestsList && interestsList[activeTab]) {
        var currentInterest = interestsList[activeTab];
        if (currentInterest.startDate && currentInterest.endDate) {
            var startDate = new Date(currentInterest.startDate + 'T00:00:00.000Z');
            var endDate = new Date(currentInterest.endDate + 'T00:00:00.000Z');
            if (startDate > endDate)
                errors.interestsList = __assign(__assign({}, (errors.interestsList || {})), (_a = {}, _a[activeTab] = {
                    startDate: 'date de début > date de fin'
                }, _a));
        }
        if (currentInterest.startDate && currentInterest.multiplyRate && currentInterest.multiplyStartFrom) {
            var startDate = new Date(currentInterest.startDate + 'T00:00:00.000Z');
            var endDate = new Date(currentInterest.endDate + 'T00:00:00.000Z');
            var multiplyDate = new Date(currentInterest.multiplyStartFrom + 'T00:00:00.000Z');
            if (startDate > multiplyDate)
                errors.interestsList = __assign(__assign({}, (errors.interestsList || {})), (_b = {}, _b[activeTab] = __assign(__assign({}, (((_j = errors.interestsList) === null || _j === void 0 ? void 0 : _j[activeTab]) || {})), { multiplyStartFrom: 'date de multiplication < date de début' }), _b));
            else if (multiplyDate > endDate)
                errors.interestsList = __assign(__assign({}, (errors.interestsList || {})), (_c = {}, _c[activeTab] = __assign(__assign({}, (((_k = errors.interestsList) === null || _k === void 0 ? void 0 : _k[activeTab]) || {})), { multiplyStartFrom: "date de multiplication > date de fin" }), _c));
        }
        if (currentInterest.startDate && currentInterest.majoriseBy && currentInterest.majoriseStartFrom) {
            var startDate = new Date(currentInterest.startDate + 'T00:00:00.000Z');
            var endDate = new Date(currentInterest.endDate + 'T00:00:00.000Z');
            var majoriseDate = new Date(currentInterest.majoriseStartFrom + 'T00:00:00.000Z');
            if (startDate > majoriseDate)
                errors.interestsList = __assign(__assign({}, (errors.interestsList || {})), (_d = {}, _d[activeTab] = __assign(__assign({}, (((_l = errors.interestsList) === null || _l === void 0 ? void 0 : _l[activeTab]) || {})), { majoriseStartFrom: 'date de majoration < date de début' }), _d));
            else if (majoriseDate > endDate)
                errors.interestsList = __assign(__assign({}, (errors.interestsList || {})), (_e = {}, _e[activeTab] = __assign(__assign({}, (((_m = errors.interestsList) === null || _m === void 0 ? void 0 : _m[activeTab]) || {})), { majoriseStartFrom: "date de majoration > date de fin" }), _e));
        }
        if (currentInterest.startDate && currentInterest.anatocism && currentInterest.anatocismStartFrom) {
            var startDate = new Date(currentInterest.startDate + 'T00:00:00.000Z');
            var endDate = new Date(currentInterest.endDate + 'T00:00:00.000Z');
            var anatocismDate = new Date(currentInterest.anatocismStartFrom + 'T00:00:00.000Z');
            if (startDate > anatocismDate)
                errors.interestsList = __assign(__assign({}, (errors.interestsList || {})), (_f = {}, _f[activeTab] = __assign(__assign({}, (((_o = errors.interestsList) === null || _o === void 0 ? void 0 : _o[activeTab]) || {})), { anatocismStartFrom: "date d'anatocisme < date de début" }), _f));
            else if (anatocismDate > endDate)
                errors.interestsList = __assign(__assign({}, (errors.interestsList || {})), (_g = {}, _g[activeTab] = __assign(__assign({}, (((_p = errors.interestsList) === null || _p === void 0 ? void 0 : _p[activeTab]) || {})), { anatocismStartFrom: "date d'anatocisme > date de fin" }), _g));
            else {
                // Create a new date for comparison to avoid modifying startDate
                var minAnatocismDate = new Date(startDate.getTime());
                minAnatocismDate.setUTCFullYear(minAnatocismDate.getUTCFullYear() + 1);
                if (anatocismDate < minAnatocismDate)
                    errors.interestsList = __assign(__assign({}, (errors.interestsList || {})), (_h = {}, _h[activeTab] = __assign(__assign({}, (((_q = errors.interestsList) === null || _q === void 0 ? void 0 : _q[activeTab]) || {})), { anatocismStartFrom: "début d'anatocisme < 1 an" }), _h));
            }
        }
    }
    return errors;
};
CaseInterestsButton = reduxForm({
    form: "interestsForm",
    enableReinitialize: true,
    validate: validate
})(CaseInterestsButton);
export default connect(mapStateToProps)(CaseInterestsButton);
