import { directVictimModel, indirectVictimModel } from "./dataModels.js";
import { getCascadedTotal, lineVictimAmount, subEntryTotal, victimSummary } from "./calculations.js";
import { getHouseholdIncomesList, getPRevEntryTotal } from "./case_data_form/household_income/calculations.js";


export function getEntryNameAndIndexFromEntryName(entryName) {
  const entryNameRegex = /(\w+)(\d)/
  const matches = entryName.match(entryNameRegex)
  if (matches == null) {
    return {}
  }
  return { name: matches[1], index: matches[2] }
}

export function getMetaValueFromEntryName(values, entryName) {
  const { name, index } = getEntryNameAndIndexFromEntryName(entryName)

  if (name === undefined || index === undefined) {
    return null
  }

  if (values._meta == null || values._meta[name] == null || values._meta[name].items == null) {
    return null
  }
  return values._meta[name].items[index]
}

export function getLineVictimAmountCalculated(values, fields, index) {
  const metaValue = getMetaValueFromEntryName(values, fields.name)
  if (!metaValue) {
    return 0
  }

  if (metaValue.lineCalculatations == null || metaValue.lineCalculatations.length <= index || metaValue.lineCalculatations[index] == null || metaValue.lineCalculatations[index].lineVictimAmountCalculated == null) {
    return 0
  }

  return metaValue.lineCalculatations[index].lineVictimAmountCalculated
}

export function calcSingle(caseValues, victim, entries) {
  if (Object.keys(caseValues).length === 0) {
    return caseValues
  }

  const clonedValues = JSON.parse(JSON.stringify(caseValues))
  clonedValues._meta = clonedValues._meta || {}

  for (const value of directVictimModel) {
    if (value.items == null || value.items.length === 0) {
      continue
    }

    for (let i = 0; i < value.items.length; i++) {
      const item = value.items[i]
      const entryName = value.id + i

      const calcVal = subEntryTotal(clonedValues, victim, entryName, item)
      clonedValues._meta[value.id] = clonedValues._meta[value.id] || {}
      clonedValues._meta[value.id].items = clonedValues._meta[value.id].items || []
      clonedValues._meta[value.id].items[i] = calcVal

      clonedValues._meta[value.id].items[i].cascadedTotal = getCascadedTotal(clonedValues, victim, entryName)

      if (clonedValues[entryName] != null) {
        clonedValues._meta[value.id].items[i].lineCalculatations = clonedValues._meta[value.id].items[i].lineCalculatations || {}

        for (let entryIndex = 0; entryIndex < clonedValues[entryName].length; entryIndex++) {
          clonedValues._meta[value.id].items[i].lineCalculatations[entryIndex] = clonedValues._meta[value.id].items[i].lineCalculatations[entryIndex] || {}
          clonedValues._meta[value.id].items[i].lineCalculatations[entryIndex].lineVictimAmountCalculated = lineVictimAmount(clonedValues, entryName, entryIndex, item, victim);
        }
      }
    }
  }

  // currentVictim is being taken into account when calculating the summary, removing this here to get the main victim summary
  const oldCurrentVictim = clonedValues.currentVictim
  delete clonedValues.currentVictim
  const victimSummaryData = victimSummary(entries, clonedValues, victim);

  clonedValues.currentVictim = oldCurrentVictim

  clonedValues._meta.victimSummary = victimSummaryData

  // indirect victims

  clonedValues.indirectVictims = clonedValues.indirectVictims || []

  for (let i = 0; i < clonedValues.indirectVictims.length; i++) {
    const indirectVictimRaw = clonedValues.indirectVictims[i]
    calculateIndirectVictim(clonedValues, victim, indirectVictimRaw, i)
  }

  return clonedValues
}

function calculateIndirectVictim(clonedValues, victim, indirectVictimRaw, victimIndex) {
  const householdIncome = clonedValues.householdIncome
  const indirectVictims = clonedValues.indirectVictims
  const values = indirectVictimRaw.caseData
  const entries = indirectVictimRaw.caseDataForm;
  const indirectVictim = indirectVictimRaw.victim;

  values._meta = values._meta || {}

  for (const value of indirectVictimModel) {
    if (value.items == null || value.items.length === 0) {
      continue
    }

    for (let i = 0; i < value.items.length; i++) {
      const item = value.items[i]
      const entryName = value.id + i

      if (entryName === 'PRev0') {
        const householdIncomesList = getHouseholdIncomesList(householdIncome);
        const prevCalcResult = getPRevEntryTotal(householdIncomesList, victim, indirectVictims, victimIndex)
        values._meta.PRev = values._meta.PRev || {}
        values._meta.PRev.items = values._meta.PRev.items || []
        values._meta.PRev.items[0]  = prevCalcResult
        continue
      }

      const calcVal = subEntryTotal(values, victim, entryName, item, indirectVictim)
      values._meta[value.id] = values._meta[value.id] || {}
      values._meta[value.id].items = values._meta[value.id].items || []
      values._meta[value.id].items[i] = calcVal

      values._meta[value.id].items[i].cascadedTotal = getCascadedTotal(values, indirectVictim, entryName)


      if (values[entryName] != null) {
        values._meta[value.id].items[i].lineCalculatations = values._meta[value.id].items[i].lineCalculatations || {}

        for (let entryIndex = 0; entryIndex < values[entryName].length; entryIndex++) {
          values._meta[value.id].items[i].lineCalculatations[entryIndex] = values._meta[value.id].items[i].lineCalculatations[entryIndex] || {}
          values._meta[value.id].items[i].lineCalculatations[entryIndex].lineVictimAmountCalculated = lineVictimAmount(values, entryName, entryIndex, item, victim);
        }
      }

    }
  }

  const victimSummaryData = victimSummary(entries, values, indirectVictim);

  values._meta.victimSummary = victimSummaryData
}
