import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PropTypes from "prop-types";
import React from "react";
import { Divider } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormat from 'react-number-format';
import { palette, localTheme as theme } from '../interests/theme';

const useStyles = makeStyles((theme) => ({
    outlinedContainer: {
        position: 'relative',
        display: 'flex',
        minWidth: 150,
        alignItems: 'center',
        border: (props) => `1px solid ${props.focused ? palette.primary.main : palette.secondary.main300}`,
        borderRadius: theme.shape.borderRadius,
        height: 37.63, // Fixed height
        transition: 'border-color 0.2s',
        '&:hover': {
            borderColor: 'black !important',
        },
        '&:focus-within': {
            borderColor: `${palette.primary.main} !important`,
            borderWidth: 2,
        },
    },
    label: {
        position: 'absolute',
        left: 10,
        top: -10,
        background: 'white',
        padding: '0 4px',
        fontSize: 12,
        color: ({ focused }) => focused ? palette.primary.main : palette.secondary.main300,
        transition: 'color 0.2s',
        zIndex: 1,
        pointerEvents: 'none', // Allow clicks through label
    },
    select: {
        flex: 1,
        height: '100%',
        '& .MuiSelect-select': {
            padding: '8px 14px', // Matches MUI's small input padding
            height: '100% !important',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        '& .MuiSelect-root': {
            height: '100%',
        },
        '& .MuiInputBase-root': {
          backgroundColor: 'transparent', // Remove inner background
          height: '100%',
          padding: 0, // Remove default padding
        },
    },
    input: {
      flex: 2,
      height: '100%',
      '& .MuiInputBase-root': {
          height: '100%',
          paddingRight: 8,
          '& input': {
              padding: '8px 0 8px 8px',
              textAlign: 'right',
              height: '100%',
              '-moz-appearance': 'textfield',
              '&::-webkit-outer-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0
              },
              '&::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0
              },
          },
      },
    },
    unit: {
        marginRight: 12,
        fontWeight: 600,
        fontSize: 12,
    },
    helperText: {
        marginLeft: 14,
        marginTop: 4,
        fontSize: 12,
        color: palette.secondary.main300,
    }
}));

export function DropdownInput(props) {
    const [focused, setFocused] = React.useState(false);
    const classes = useStyles( {focused});
    const [menuOpen, setMenuOpen] = React.useState(false); // New state for menu visibility
    const selectRef = React.useRef();

    const handleFocus = () => setFocused(true);
    const handleBlur = () => setFocused(false);


  const handleSelectChange = (event) => {
    props.setSelected(event.target.value);
  };

    const handleInputChange = (values) => {
        props.setInputValue(values.floatValue);
    };

    const handleClickOutside = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
            setMenuOpen(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const selectedEntry = props.entries.find(entry => props.selected === entry.key);
    const hasInput = selectedEntry?.hasInput || false;
    const helperText = selectedEntry?.helperText || '';

    return (
        // TODO: adjust the label to exactly match MUI default position, size, color, etc. (maybe with fieldset)
        <>
            <div className={classes.outlinedContainer} style={{ width: props.width || 'auto' }}>
                {props.label && (
                    <span className={classes.label}>
                        {props.label}{props.required && <span style={{ color: palette.error.main }}> *</span>}  
                    </span>
                )}
                <Select
                  ref={selectRef}
                    value={props.selected}
                    onChange={handleSelectChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    className={classes.select}
                    disableUnderline
                    displayEmpty
                    open={menuOpen} // Controlled open state
                    onOpen={() => {
                      setMenuOpen(true);
                      handleFocus();
                    }}
                    onClose={() => {
                        setMenuOpen(false);
                        handleBlur();
                    }}
                    MenuProps={{
                      BackdropProps: { invisible: true },
                      disablePortal: true,
                  }}
                    style={{ minWidth: props.selectMinWidth || 120 }}
                >
                    <MenuItem value="">
                        <em>{props.emptyItemTitle || "(aucun)"}</em>
                    </MenuItem>
                    {props.entries.map((entry) => (
                        <MenuItem key={entry.key} value={entry.key} style={{ color: palette.secondary.main500 }}>
                            {entry.name}
                        </MenuItem>
                    ))}
                </Select>

                {hasInput && (
                    <>
                        <Divider orientation="vertical" flexItem style={{ height: focused ? 34 : 36 }} />
                        <NumberFormat
                            value={props.inputValue === 0 ? "0" : props.inputValue}
                            onValueChange={handleInputChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            className={classes.input}
                            thousandSeparator=" "
                            decimalSeparator=","
                            allowLeadingZeros={true}
                            allowNegative={false}
                            isNumericString={true}
                            customInput={TextField}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" className={classes.unit}>
                                        {props.unit}
                                    </InputAdornment>
                                ),
                                disableUnderline: true,
                            }}
                            inputProps={{
                                style: { padding: '10.5px 0 10.5px 8px', textAlign: 'right' }
                            }}
                        />
                    </>
                )}
            </div>
            
            <FormHelperText className={classes.helperText}>
                {helperText || <>&nbsp;</>}
            </FormHelperText>
        </>
    );
}


DropdownInput.propTypes = {
    entries: PropTypes.array.isRequired,
    unit: PropTypes.string,
    emptyItemTitle: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    selected: PropTypes.string,
    setSelected: PropTypes.func.isRequired,
    inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setInputValue: PropTypes.func.isRequired,
    selectMinWidth: PropTypes.number,
    width: PropTypes.number,
};
