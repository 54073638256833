import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Field, change } from "redux-form"
import AmountField from "../../fields/AmountField"
import RenderActionsCol from "./renderActionsCol"
import BtnInputAmount from "../../buttons/BtnInputAmount";
import { getLineAmount, getDsfFutureAnnualDamage, dsfLineCapitalisedAmount } from "../calculations";
import DsfAnnuityCapitalisation from "./dsfAnnuityCapitalisation";
import HelpTip from "../../../layout/HelpTip";
import DisplayDiscountingFormDSFFuture from '../../discounting/DisplayDiscountingFormDSFFuture';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import NumberFormat from "react-number-format";

const renderDSFFuture = props => {

  const { fields, line, item, dispatch, lineIndex, arrValues, values, victim, entry, meta } = props;

  const removeArrLine = index => {
    fields.remove(index);
  }
  const addLine = () => {
    let newLine = { frequencyType: 1 };
    fields.push(newLine);
  }

  if (fields.length === 0) addLine();

  const removeDiscounting = (i, lineValues) => {
    const lineRef = `${entry}[${lineIndex}].arr.future[${i}]`;
    let newLineValues = lineValues;
    delete newLineValues.discounting;
    dispatch(change("caseDataForm", lineRef, newLineValues));
  }

  return (
    <tr>
      <td className="col-num" />
      <td colSpan="4">
        <table className="table table-sm mb-0">
          {fields.length > 0 && <Fragment>
            <caption>Arrérages à échoir <HelpTip helpText="arrFuture" /></caption>
            <thead>
              <tr>
                <th />
                <th className="text-nowrap pl-0">Fréquence d'acquisition</th>
                <th className="container-fluid">Commentaire / composante</th>
                <th>Montant unitaire</th>
                <th colSpan="2" className="text-center">Préjudice annuel</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((arr, i) => (
                <Fragment key={i}>
                  <tr>
                    <th scope="row" className="col-num">{fields.length > 1 && String.fromCharCode(97 + i)+"."}</th>
                    <td>
                      <div className="input-group input-group-sm flex-nowrap">
                        <Field name={`${line}.arr.future[${i}].frequencyNum`}
                          component="input"
                          type="text"
                          className="form-control text-right frequencyNum"
                          placeholder="0"
                          readOnly={arrValues[i].amountDirectInput}
                          disabled={arrValues[i].amountDirectInput}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">fois tou{arrValues[i].frequencyType === "week" ? "te" : ""}s les&nbsp;</span>
                        </div>
                        <Field
                          name={`${line}.arr.future[${i}].frequencyType`}
                          component="select"
                          className="custom-select frequencyType"
                          disabled={arrValues[i].amountDirectInput}
                        >
                          <option value="day">jours</option>
                          <option value="week">semaines</option>
                          <option value="1" default>mois</option>
                          <option value="3">trimestres</option>
                          <option value="12">ans</option>
                          <option value="24">2 ans</option>
                          <option value="36">3 ans</option>
                          <option value="48">4 ans</option>
                          <option value="60">5 ans</option>
                          <option value="72">6 ans</option>
                          <option value="84">7 ans</option>
                          <option value="96">8 ans</option>
                          <option value="108">9 ans</option>
                          <option value="120">10 ans</option>
                          <option value="132">11 ans</option>
                          <option value="144">12 ans</option>
                          <option value="156">13 ans</option>
                          <option value="168">14 ans</option>
                          <option value="180">15 ans</option>
                          <option value="240">20 ans</option>
                        </Field>
                      </div>
                    </td>
                    <td className="container-fluid">
                      <div className="input-group input-group-sm flex-nowrap">
                        <Field name={`${line}.arr.future[${i}].comment`}
                          component="input"
                          type="text"
                          className="form-control"
                          placeholder="Commentaire"
                        />
                      </div>
                    </td>
                    <td className="col-amount">
                      <div className="d-flex">
                        <Field
                          name={`${line}.arr.future[${i}].unitAmount`}
                          component={AmountField}
                          readOnly={arrValues[i].amountDirectInput}
                          disabled={arrValues[i].amountDirectInput}
                        />
                        <DisplayDiscountingFormDSFFuture
                          values={values}
                          entry={entry}
                          index={lineIndex}
                          futureLineIndex={i}
                          disabled={arrValues[i].amountDirectInput}
                        />
                      </div>
                    </td>
                    <td className="col-amount">
                      <div className="d-flex">
                        {!arrValues[i].amountDirectInput ? (
                          <AmountField fieldValue={getLineAmount(values, entry, lineIndex, item, "future", i)} />
                        ) : (
                          <Field
                            name={`${line}.arr.future[${i}].lineAmount`}
                            component={AmountField}
                            readOnly={!arrValues[i].amountDirectInput}
                          />
                        )}
                        <BtnInputAmount
                          switchInputAmount={() => {
                            dispatch(
                              change(
                                "caseDataForm",
                                `${line}.arr.future[${i}].lineAmount`,
                                !arrValues[i].amountDirectInput ?
                                  getLineAmount(values, entry, lineIndex, item, "future", i)
                                    .toFixed(2)
                                    .replace(".", ",")
                                  : null,
                              ),
                            );
                            dispatch(
                              change(
                                "caseDataForm",
                                `${line}.arr.future[${i}].amountDirectInput`,
                                !arrValues[i].amountDirectInput,
                              ),
                            );
                            dispatch(
                              change("caseDataForm", `${line}.arr.future[${i}].frequencyNum`, 1),
                            );
                            dispatch(
                              change("caseDataForm", `${line}.arr.future[${i}].frequencyType`, 12),
                            );
                          }}
                          amountDirectInput={arrValues[i].amountDirectInput}
                        />
                      </div>
                    </td>
                    <RenderActionsCol
                      item={item}
                      line={line}
                      secondaryLine={true}
                      removeLine={() => removeArrLine(i)}
                    />
                  </tr>
                  {arrValues[i].discounting && arrValues[i].discounting.indexValue && !arrValues[i].amountDirectInput &&
                  <tr>
                    <td colSpan={4} className="text-right align-bottom pt-0 pb-0">
                      <em>Montant unitaire actualisé</em> :&nbsp;<NumberFormat
                        displayType="text"
                        thousandSeparator=" "
                        className="discountedTotal pr-1"
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        type="text"
                        value={arrValues[i].unitAmount ? parseFloat(arrValues[i].unitAmount.replace(",", ".").replace(/\s/g, "")) * arrValues[i].discounting.indexValue : 0}
                        suffix=" €"
                      />
                      <button
                        type="button"
                        className="btn btn-outline-danger btn-sm p-0"
                        onClick={()=>removeDiscounting(i, arrValues[i])}
                        title="Supprimer l'actualisation"
                      >
                        <HighlightOffIcon />
                      </button>
                    </td>
                    <td colSpan={2}>
                    </td>
                  </tr>
                  }
                </Fragment>
              ))}
            </tbody>
          </Fragment>}
          <tfoot>
            <tr>
              <td className="col-num" />
              <td colSpan={3} className="text-right">
                {fields.length > 1 && <h6 className="mb-0">Préjudice annuel arrérages à échoir dépense n° {lineIndex + 1}</h6>}
              </td>
              <td className="col-amount">
                {fields.length > 1 && <AmountField
                  readOnly
                  fieldValue={getDsfFutureAnnualDamage(values, entry, lineIndex, item)}
                />}</td>
              <td />
            </tr>
            {(fields.length > 0 || (values[entry][lineIndex].arr.annCap && values[entry][lineIndex].arr.annCap.tppCap && values[entry][lineIndex].arr.annCap.tppCap.length > 0)) &&
            <DsfAnnuityCapitalisation
              colSpan={3}
              item={item}
              line={line}
              lineIndex={lineIndex}
              entry={entry}
              values={values}
              victim={victim}
              meta={meta}
              lineCapitalisedAmount={dsfLineCapitalisedAmount(values, entry, lineIndex, item, victim)}
            />}
          </tfoot>
        </table>
      </td>
      <td />
    </tr>
  )
}

export default connect()(renderDSFFuture)
