import React, {memo, useCallback, useEffect, useState, useRef} from 'react';
import PropTypes from "prop-types";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './RenderDateInput.css'
import { SingleDatePicker } from 'react-dates';
import frLocale from 'moment/locale/fr';
import moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip }  from '@material-ui/core';
import { getAgeInFrench } from "../../../cases/utils";

const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    const years = [];
    for (let i = moment().year() + 30; i >= moment().year() - 120; i--) {
        years.push(<option value={i} key={`year-${i}`}>{i}</option>);
    }
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
                <select value={month.month()} onChange={e => onMonthSelect(month, e.target.value)}
                    style={{ padding: "0.5rem", fontSize: "14px", border: "0px" }}>
                    {moment.months().map((label, value) => (
                        <option value={value} key={value}>{label}</option>
                    ))}
                </select>
            </div>
            <div>
                <select value={month.year()} onChange={e => onYearSelect(month, e.target.value)} style={{
                    marginLeft: "0.5rem",
                    padding: "0.5rem 1rem 0.5rem 0.5rem",
                    fontSize: "14px",
                    border: "0px"
                }}>
                    {years}
                </select>
            </div>
        </div>
    );
};

const isOutsideRange = () => false

function DatePicker({
    input,
    meta: { touched, error, warning },
    placeholder,
    disabled,
    required,
    className,
    label,
    optional,
    mandatory,
    wizard,
    displayWarning,
    displayWarningIndirect,
  title,
}) {
    // Set the locale at the top level of the function component
    useEffect(() => {
        moment.locale('fr', frLocale);
    }, []);

  const [focused, setFocused] = useState(false);

    const onFocusChange = useCallback(() => {
        setFocused(val => !val);
        input.onFocus();
    }, [input]);

    const dateChangeRef = useRef(false);

    useEffect(() => {
        if (input.value && dateChangeRef.current) {
            handleTooltipOpen();
            dateChangeRef.current = false;
        }
    }, [input.value]);

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const [tooltipOpen, setOpenTooltip] = useState(false);
    const handleTooltipOpen = () => {
        if ((displayWarning || displayWarningIndirect)??false){// when value is undefined, make it false
            setOpenTooltip(true);
            setTimeout(() => {
                setOpenTooltip(false);
            }, 3000); 
        }
    };
    const res = useRef(null)
    if (input&& input.value && input.value._i){
        res.current = getAgeInFrench(input.value._i)
    }
    return (<>
        {open ? <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Date de consolidation modifiée</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <strong>Vous venez de modifier la date de consolidation.</strong><br /><br />
                    N’oubliez pas de mettre à jour cette date dans vos calculs déjà réalisés des arrérages échus des postes patrimoniaux futurs&nbsp;:<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;- Dépense de santé futures (DSF)<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;- Frais de logement adapté (FLA)<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;- Frais de véhicule adapté (FVA)<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;- Tierce personne futurs (TPF)<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;- Perte de gains professionnels futurs (PGPF)<br /><br />
                    ...et pour les éventuelles victimes indirectes :<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;- Perte de revenus des proches<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;- Perte en industrie
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" size="small" title="C'est compris !">
                    C'est compris !
                </Button>
            </DialogActions>
        </Dialog> : null}
        <div 
          className={`form-group${touched && (error || warning) ? " has-danger" : ""}${className || ""}`}
          title={disabled ? title : ""}
        >
            {label && (
                <label className="control-label" htmlFor={input.name}>
                    {label}
                    {optional && <em> (facultatif)</em>}
                    {mandatory && <span className="text-danger"> *</span>}
                    {touched && (error || warning) &&
                        <span className="invalid-feedback ml-3" style={{ display: "inline" }}>{error || warning}</span>}
                </label>
            )}
            <div className={wizard ? "" : "input-group input-group-sm"}>
               <Tooltip open={tooltipOpen} onOpen={handleTooltipOpen} title="Cette période a au moins un jour en commun avec une autre période du poste" placement="top">
                    <div>
                        <SingleDatePicker
                            showClearDate={!disabled}
                            displayFormat="DD/MM/YYYY"
                            numberOfMonths={1}
                            isOutsideRange={isOutsideRange}
                            disabled={disabled}
                            placeholder={placeholder ? placeholder : "jj/mm/aaaa"}
                            date={input.value}
                            onDateChange={(date) => {
                                input.onChange(date);
                                if (date) {
                                    dateChangeRef.current = true;
                                }
                            }}
                            focused={focused}
                            onFocusChange={onFocusChange}
                            id={input.name}
                            noBorder={true}
                            renderMonthElement={renderMonthElement}
                            customInputIcon={null}
                    inputIconPosition="after"
                    readOnly={disabled}
                />
                        {input.name === "birth_date" && input.value && res.current && <span style={{color:res.current['color']}}>&nbsp;({res.current['sign']}{res.current['age']})</span>}
                {warning && input.value && <span style={{color: 'red'}}>&nbsp;{warning}</span>} 
            </div>
                </Tooltip>             </div>
        </div>
    </>);
}

export const formatDates = value => (value ? moment(value) : null);

export const normalizeDates = value =>
  value ? value.format('YYYY-MM-DD') : null;

DatePicker.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  optional: PropTypes.bool,
  mandatory: PropTypes.bool,
  wizard: PropTypes.bool,
  title: PropTypes.string
};

export default memo(DatePicker);
