import React from "react";
import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
} from '@novu/notification-center';



const ncWidth = "650px !important";
const unreadBackGroundColor = "#9e3386";
const primaryColor = "white";
const secondaryColor = "white";
const primaryButtonBackGroundColor = unreadBackGroundColor;
const primaryTextColor = "#0C0404";
const secondaryTextColor = "#5b256e";
const secondaryButtonBackGroundColor = "#9e3386";
const dropdownBorderStyle = "2px solid #5b256e";
const tabLabelAfterStyle = "#5b256e !important";

const styles = {
  bellButton: {
    root: {
      marginTop: "3px",
      marginRight: "10px",
      svg: {
        color: "white",
        fill: "white",
        minWidth: "20px",
        minHeight: "20px",
      },
    },
    dot: {
      rect: {
        fill: "red",
        strokeWidth: "0",
        width: "7px",
        height: "7px",
        x: 10,
        y: 2,
      },
    },
  },
  unseenBadge: {
    root: { color: "white", background: "red" },
  },
  popover: {
    // root: { zIndex: -99 },
    arrow: {
      backgroundColor: primaryColor,
      borderLeftColor: secondaryColor,
      borderTopColor: secondaryColor,
    },
    dropdown: {
      border: dropdownBorderStyle,
      borderRadius: "10px",
      maxWidth: ncWidth,
      minWidth: ncWidth,
    },
  },
  header: {
    root: {
      backgroundColor: primaryColor,
      "&:hover": { backgroundColor: primaryColor },
      cursor: "pointer",
      color: primaryTextColor,
    },
    cog: { opacity: 1 },
    markAsRead: {
      color: primaryTextColor,
      fontSize: "14px",
    },
    title: { color: primaryTextColor },
    backButton: {
      color: primaryTextColor,
    },
  },
  layout: {
    root: {
      background: primaryColor,
      maxWidth: ncWidth,
      minWidth: ncWidth,
    },
  },
  loader: {
    root: {
      stroke: primaryTextColor,
    },
  },
  accordion: {
    item: {
      backgroundColor: secondaryColor,
      ":hover": {
        backgroundColor: secondaryColor,
      },
    },
    content: {
      backgroundColor: secondaryColor,
      borderBottomLeftRadius: "7px",
      borderBottomRightRadius: "7px",
    },
    control: {
      ":hover": {
        backgroundColor: secondaryColor,
      },
      color: primaryTextColor,
      title: {
        color: primaryTextColor,
      },
    },
    chevron: {
      color: primaryTextColor,
    },
  },
  switch: {
    root: {},
    input: {
      ":checked~label": {
        background: primaryColor,
        ".mantine-Switch-trackLabel": {
          backgroundColor: primaryColor,
        },
      },
      ":not(checked)~label": {
        background: primaryTextColor,
        ".mantine-Switch-trackLabel": {
          backgroundColor: primaryTextColor,
        },
      },
    },
    track: {},
    thumb: { backgroundColor: secondaryColor },
  },
  notifications: {
    root: {
      ".nc-notifications-list-item": {
        backgroundColor: secondaryColor,
      },
    },
    listItem: {
      layout: {
        borderRadius: "7px",
        color: primaryTextColor,
        ".mantine-Avatar-root": {
          width: "20px",
          height: "20px",
          minWidth: "20px",
        },
        ".mantine-Avatar-image": {
          width: "20px",
          height: "20px",
          minWidth: "20px",
        },
        "div:has(> .mantine-Avatar-root)": {
          border: "none",
          width: "20px",
          height: "20px",
          minWidth: "20px",
        },
      },
      timestamp: { color: secondaryTextColor, fontWeight: "bold" },
      dotsButton: {
        path: {
          fill: primaryTextColor,
        },
      },
      unread: {
        "::before": { background: unreadBackGroundColor },
      },
      buttons: {
        primary: {
          background: primaryButtonBackGroundColor,
          color: primaryTextColor,
          "&:hover": {
            background: primaryButtonBackGroundColor,
            color: secondaryTextColor,
          },
        },
        secondary: {
          background: secondaryButtonBackGroundColor,
          color: secondaryTextColor,
          "&:hover": {
            background: secondaryButtonBackGroundColor,
            color: secondaryTextColor,
          },
        },
      },
    },
  },
  actionsMenu: {
    item: { "&:hover": { backgroundColor: secondaryColor } },
    dropdown: {
      backgroundColor: primaryColor,
    },
    arrow: {
      backgroundColor: primaryColor,
      borderTop: "0",
      borderLeft: "0",
    },
  },
  preferences: {
    item: {
      title: { color: primaryTextColor },
      divider: { borderTopColor: primaryColor },
      channels: { color: secondaryTextColor },
      content: {
        icon: { color: primaryTextColor },
        channelLabel: { color: primaryTextColor },
        success: { color: primaryTextColor },
      },
    },
  },
  tabs: {
    tabLabel: {
      "::after": { background: tabLabelAfterStyle },
      ":hover": { color: tabLabelAfterStyle },
      "[data-active]": { color: tabLabelAfterStyle },
    },
    tabsList: { borderBottomColor: primaryColor },
  },
};

const PopoverHeader = () => {
  return <h2 className="pl-3">Infos Quantum</h2>;
};

const PopoverFooter = () => {
  return <span></span>;
};


const InAppNotifications = props => {
  
  const { auth } = props;

  let subscriberId = '';
  if (auth && auth.user) subscriberId = auth.user.email

  return (
    <NovuProvider subscriberId={subscriberId} applicationIdentifier={'_yZJdkLANZyC'} i18n="fr" styles={styles}>
      <PopoverNotificationCenter
        position="left-start"
        header={() => <PopoverHeader />}
        footer={() => <PopoverFooter />}
        colorScheme="light"
      >
        {({ unseenCount }) => <NotificationBell unseenCount={unseenCount} />}
      </PopoverNotificationCenter>
    </NovuProvider>
  );
}

export default InAppNotifications;
