var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { connect } from 'react-redux';
import { Box, ClickAwayListener, FormControlLabel, FormHelperText, IconButton, MenuItem, MenuList, Paper, Popper, Switch, TextField, Tooltip, Typography, } from '@material-ui/core';
import { Info, ArrowDropDown } from '@material-ui/icons';
import { palette, useStyles } from "./theme";
import { helpEntries } from "../../cases/physical_injury/constants";
import { DropdownInput } from '../fields/DropdownInput';
import NumberFormat from 'react-number-format';
// Wrapper component for number fields with right alignment
var NumberTextField = React.forwardRef(function (props, ref) {
    var _a;
    return (React.createElement(TextField, __assign({}, props, { size: "small", ref: ref, inputProps: __assign(__assign({}, props.inputProps), { style: __assign(__assign({}, (_a = props.inputProps) === null || _a === void 0 ? void 0 : _a.style), { textAlign: 'right' }) }) })));
});
NumberTextField.displayName = 'NumberTextField';
export function RenderTextField(_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var input = _a.input, label = _a.label, type = _a.type, required = _a.required, InputProps = _a.InputProps, placeholder = _a.placeholder, className = _a.className, _j = _a.meta, touched = _j.touched, error = _j.error, helperText = _a.helperText, disabled = _a.disabled, multiline = _a.multiline, custom = __rest(_a, ["input", "label", "type", "required", "InputProps", "placeholder", "className", "meta", "helperText", "disabled", "multiline"]);
    var classes = useStyles();
    var isDateType = type === 'date';
    var baseInputProps = __assign(__assign({}, InputProps), (isDateType && disabled && {
        style: __assign(__assign({}, InputProps === null || InputProps === void 0 ? void 0 : InputProps.style), { textDecoration: 'line-through', WebkitTextDecorationLine: 'line-through', MozTextDecorationLine: 'line-through', textDecorationThickness: '2px', textDecorationColor: 'currentColor' })
    }));
    return type === 'number' ? (React.createElement(NumberFormat, __assign({ thousandSeparator: " ", decimalSeparator: ",", decimalScale: ((_d = (_c = (_b = InputProps === null || InputProps === void 0 ? void 0 : InputProps.endAdornment) === null || _b === void 0 ? void 0 : _b.props) === null || _c === void 0 ? void 0 : _c.children) === null || _d === void 0 ? void 0 : _d.includes('€')) ? 2 : undefined, fixedDecimalScale: (_g = (_f = (_e = InputProps === null || InputProps === void 0 ? void 0 : InputProps.endAdornment) === null || _e === void 0 ? void 0 : _e.props) === null || _f === void 0 ? void 0 : _f.children) === null || _g === void 0 ? void 0 : _g.includes('€'), allowNegative: false, onValueChange: function (values) {
            input.onChange(values.value === '' ? null : values.floatValue);
        }, value: (_h = input.value) !== null && _h !== void 0 ? _h : '', isNumericString: true, allowLeadingZeros: true, customInput: NumberTextField, label: label, InputLabelProps: { shrink: true }, InputProps: baseInputProps, variant: "outlined", required: required, className: "".concat(classes.input).concat(className ? " ".concat(className) : ''), placeholder: placeholder, error: !!error, helperText: error ? error : helperText ? helperText : React.createElement(React.Fragment, null, "\u00A0") }, custom))) : (React.createElement(TextField, __assign({}, input, custom, { label: label, InputLabelProps: { shrink: true }, multiline: multiline, rows: 1, rowsMax: 3, size: "small", InputProps: __assign(__assign({}, baseInputProps), (multiline && {
            style: __assign(__assign({}, baseInputProps === null || baseInputProps === void 0 ? void 0 : baseInputProps.style), { maxHeight: '100px', overflow: 'auto' }),
            inputProps: {
                style: {
                    scrollbarWidth: 'thin',
                    scrollbarColor: "".concat(palette.secondary.main200, " transparent"),
                    paddingRight: '4px'
                }
            }
        })), variant: "outlined", type: type, required: required, className: "".concat(classes.input).concat(className ? " ".concat(className) : ''), placeholder: placeholder, error: !!error, helperText: error ? error : helperText ? helperText : React.createElement(React.Fragment, null, "\u00A0") })));
}
export function RenderTextFieldWithDropdown(_a) {
    var input = _a.input, label = _a.label, type = _a.type, required = _a.required, _b = _a.InputProps, InputProps = _b === void 0 ? {} : _b, placeholder = _a.placeholder, className = _a.className, _c = _a.meta, touched = _c.touched, error = _c.error, helperText = _a.helperText, dropdownItems = _a.dropdownItems, _d = _a.defaultDropdownKey, defaultDropdownKey = _d === void 0 ? 'points' : _d, _e = _a.formName, formName = _e === void 0 ? "interestsForm" : _e, _f = _a.fieldArrayName, fieldArrayName = _f === void 0 ? "interestsList" : _f, arrayIndex = _a.arrayIndex, custom = __rest(_a, ["input", "label", "type", "required", "InputProps", "placeholder", "className", "meta", "helperText", "dropdownItems", "defaultDropdownKey", "formName", "fieldArrayName", "arrayIndex"]);
    var classes = useStyles();
    var _g = React.useState(null), anchorEl = _g[0], setAnchorEl = _g[1];
    var _h = React.useState(false), open = _h[0], setOpen = _h[1];
    var _j = React.useState(false), focused = _j[0], setFocused = _j[1];
    // Initialize with either existing value or default
    var _k = React.useState(function () {
        // Handle different data formats
        if (input.value) {
            if (typeof input.value === 'object' && 'unit' in input.value) {
                // New format: {value: number, unit: 'points'|'percent'}
                return dropdownItems.find(function (item) { return item.key === input.value.unit; }) || dropdownItems[0];
            }
            else if (typeof input.value === 'number') {
                // Legacy format: just a number (implicitly points)
                return dropdownItems.find(function (item) { return item.key === 'points'; }) || dropdownItems[0];
            }
        }
        // Default for new fields
        return dropdownItems.find(function (item) { return item.key === defaultDropdownKey; }) || dropdownItems[0];
    }), selectedItem = _k[0], setSelectedItem = _k[1];
    // Initialize or convert the form value if needed
    React.useEffect(function () {
        if (arrayIndex !== undefined) {
            if (typeof input.value === 'number') {
                // Convert legacy format to new format
                var newValue = {
                    value: input.value,
                    unit: 'points'
                };
                input.onChange(newValue);
            }
            else if (!input.value) {
                // Initialize new field with undefined
                input.onChange(undefined);
            }
        }
    }, []);
    var handleDropdownClick = function (event) {
        event.preventDefault(); // Prevent losing focus
        setAnchorEl(event.currentTarget);
        setOpen(!open);
        setFocused(true);
    };
    var closeDropdown = function () {
        setOpen(false);
    };
    var handleClickAway = function () {
        closeDropdown();
        setFocused(false);
    };
    var handleFocus = function () {
        setFocused(true);
        if (open) {
            closeDropdown();
        }
    };
    var handleBlur = function () {
        // Never handle blur directly - focus is only lost on clickaway
        return;
    };
    var handleItemClick = function (item) {
        setSelectedItem(item);
        if (input.value) {
            var newValue = {
                value: typeof input.value === 'object' ? input.value.value : Number(input.value),
                unit: item.key
            };
            input.onChange(newValue);
        }
        closeDropdown();
    };
    // Merge the dropdown endAdornment with any existing InputProps
    var mergedInputProps = __assign(__assign({}, InputProps), { endAdornment: (React.createElement(React.Fragment, null,
            React.createElement(Typography, { variant: "body2", style: {
                    marginRight: 0,
                    color: palette.secondary.main500,
                    minWidth: 'auto'
                } }, selectedItem.value),
            React.createElement(IconButton, { size: "small", onClick: handleDropdownClick, edge: "end", style: {
                    color: palette.secondary.main300,
                    padding: 0,
                    marginLeft: 0,
                    marginRight: -10
                } },
                React.createElement(ArrowDropDown, { fontSize: "small" })))) });
    return (React.createElement(ClickAwayListener, { onClickAway: handleClickAway },
        React.createElement("div", null,
            React.createElement(NumberFormat, __assign({ thousandSeparator: " ", decimalSeparator: ",", decimalScale: undefined, fixedDecimalScale: false, allowLeadingZeros: true, allowNegative: false, onValueChange: function (values) {
                    if (values.value === '') {
                        input.onChange(null);
                    }
                    else {
                        var newValue = {
                            value: values.floatValue,
                            unit: selectedItem.key
                        };
                        input.onChange(newValue);
                    }
                }, value: (input.value === undefined || input.value === null ? '' :
                    typeof input.value === 'object' ?
                        (input.value.value === 0 ? "0" : input.value.value) :
                        input.value), isNumericString: true, customInput: NumberTextField, onFocus: handleFocus, onBlur: handleBlur, focused: focused, inputProps: {
                    style: { marginRight: 4 }
                } }, custom, { label: label, InputLabelProps: { shrink: true }, InputProps: mergedInputProps, variant: "outlined", required: required, className: "".concat(classes.input).concat(className ? " ".concat(className) : ''), placeholder: placeholder, error: !!error, helperText: error ? error : helperText ? helperText : React.createElement(React.Fragment, null, "\u00A0") })),
            React.createElement(Popper, { open: open, anchorEl: anchorEl, placement: "bottom-end", style: { zIndex: 1300 } },
                React.createElement(Paper, null,
                    React.createElement(MenuList, null, dropdownItems.map(function (item) { return (React.createElement(MenuItem, { key: item.key, onClick: function () { return handleItemClick(item); }, selected: selectedItem.key === item.key, style: {
                            color: palette.secondary.main500,
                        } }, item.value)); })))))));
}
export function RenderSwitchField(_a) {
    var input = _a.input, label = _a.label, helpText = _a.helpText, disabled = _a.disabled;
    var Label = (React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
        React.createElement(Typography, { noWrap: true }, label),
        helpText &&
            React.createElement(Tooltip, { title: helpEntries[helpText], leaveDelay: 200 },
                React.createElement(Info, { fontSize: "small", style: { marginLeft: 4, fill: palette.secondary.main500 } }))));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement(FormControlLabel, { control: React.createElement(Switch, __assign({}, input, { color: "primary", checked: input.value ? true : false })), label: Label, labelPlacement: "start", disabled: disabled }),
            React.createElement(FormHelperText, null, "\u00A0"))));
}
var RenderInterestRateSelection = function (_a) {
    var _b;
    var input = _a.input, label = _a.label, _c = _a.meta, touched = _c.touched, error = _c.error, paos = _a.paos, custom = __rest(_a, ["input", "label", "meta", "paos"]);
    var baseEntries = [
        { key: 'professional', name: 'Professionnel', hasInput: false },
        { key: 'individual', name: 'Particulier', hasInput: false },
        { key: 'custom', name: 'Saisie libre', hasInput: true, helperText: "Fixe sur la période" },
    ];
    if (paos) {
        baseEntries.splice(2, 0, { key: 'paos', name: 'PAOS', hasInput: false });
    }
    return (React.createElement(DropdownInput, __assign({ label: label, selected: input.value.selected || '', setSelected: function (value) { return input.onChange(__assign(__assign({}, input.value), { selected: value })); }, inputValue: (_b = input.value.input) !== null && _b !== void 0 ? _b : "", setInputValue: function (value) { return input.onChange(__assign(__assign({}, input.value), { input: value })); }, emptyItemTitle: "S\u00E9lectionnez le taux", entries: baseEntries, unit: "%", width: 210, selectMinWidth: 120 }, custom)));
};
var mapStateToProps = function (state) {
    var _a, _b;
    return ({
        paos: (_b = (_a = state.auth) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.paos_calculations
    });
};
var ConnectedRenderInterestRateSelection = connect(mapStateToProps)(RenderInterestRateSelection);
export { ConnectedRenderInterestRateSelection as RenderInterestRateSelection };
