import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade'
import { change } from "redux-form";
import { useDispatch } from "react-redux";
import { incomeLossCalcMethods } from './constants';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'left',
    maxWidth:800,
    minWidth:800,
    maxHeight: 450,
    minHeight:450,
  },

}));

let DisplayIncomeLossCalcMethodChoice = (props) => {
  const { open, setMethodChoiceOpen } = props;
    
    
  const [method, setMethod] = useState(null);
  const dispatch = useDispatch();
    
  const classes = useStyles();

  const addIncomeLossCalcMethod = () => {
    if (method) {
      dispatch(
        change(
          "caseDataForm",
          "incomeLossCalcMethod",
          method,
        ),
      );
      setMethodChoiceOpen(false);
    }
  };


  const onOptionChange = e => {
    setMethod(e.target.value)
  }
  
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => setMethodChoiceOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title" style={{ textAlign: 'center' }}>
                Veuillez sélectionner une méthode de calcul pour la perte de revenus des proches.
            </h2>
            <legend style={{ fontSize:"1rem" }} className='pt-2'>
              <span className='font-weight-bold'>La méthode sélectionnée sera utilisée pour l'ensemble des victimes indirectes du dossier.</span>
            </legend>
            <div className="main pt-3">
              <div className="custom-control custom-radio ml-4">
                <input
                  type="radio"
                  name="classic"
                  value="classic"
                  id="classic"
                  checked={method === "classic"}
                  onChange={onOptionChange}
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="classic">
                  <span className='font-weight-bold' style={{ fontSize:"1rem" }}>{incomeLossCalcMethods.classic.label}</span>
                  <p style={{ whiteSpace: "pre-line" }}>{incomeLossCalcMethods.classic.description}</p>
                </label>
              </div>
              <div className="custom-control custom-radio ml-4 pt-4">
                <input
                  type="radio"
                  name="redistribution"
                  value="redistribution"
                  id="redistribution"
                  checked={method === "redistribution"}
                  onChange={onOptionChange}
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="redistribution">
                  <span className='font-weight-bold' style={{ fontSize:"1rem" }}>{incomeLossCalcMethods.redistribution.label}</span>
                  <p style={{ whiteSpace: "pre-line" }}>{incomeLossCalcMethods.redistribution.description}</p>
                </label>
              </div>
            </div>
            <div className="text-right">
              <button
                className="btn btn-sm btn-primary mt-auto"
                onClick={() => addIncomeLossCalcMethod()}
                disabled={!method}
              >
                  Valider la méthode de calcul
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

DisplayIncomeLossCalcMethodChoice.propTypes = {
  open: PropTypes.bool,
  setMethodChoiceOpen: PropTypes.func,
  dispatch: PropTypes.func,
};

export default DisplayIncomeLossCalcMethodChoice;
