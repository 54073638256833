export function intervalUTC(date1, date2, returnType, includeEndStartDays, decimals) {
    if (returnType === void 0) { returnType = "days"; }
    if (includeEndStartDays === void 0) { includeEndStartDays = true; }
    if (decimals === void 0) { decimals = 3; }
    var interval = 0;
    if (date1 && !date2)
        date2 = date1;
    else if (date2 && !date1)
        date1 = date2;
    // Preserve the UTC time by using the timestamp
    var jsDate1 = new Date(date1.getTime());
    var jsDate2 = new Date(date2.getTime());
    if (!date1 || !date2 || jsDate2.getTime() < jsDate1.getTime())
        return interval;
    if (returnType === "days" || returnType === "weeks") {
        var diffDays = 0;
        var oneDay = 24 * 60 * 60 * 1000;
        diffDays = Math.round(Math.abs((jsDate1.getTime() - jsDate2.getTime()) / oneDay));
        includeEndStartDays && ++diffDays; // on ajoute 1 si les jours de début et de fin doivent être inclus
        if (returnType === "days")
            interval = diffDays;
        else
            interval = diffDays / 7;
    }
    else {
        var year1 = jsDate1.getUTCFullYear(), month1 = jsDate1.getUTCMonth(), day1 = jsDate1.getUTCDate();
        var year2 = jsDate2.getUTCFullYear(), month2 = jsDate2.getUTCMonth(), day2 = jsDate2.getUTCDate();
        if (!includeEndStartDays)
            day2 -= 1;
        var nbDaysMonth1 = new Date(Date.UTC(year1, month1 + 1, 0)).getUTCDate();
        var nbDaysMonth2 = new Date(Date.UTC(year2, month2 + 1, 0)).getUTCDate();
        var diffYears = year2 - year1; // number of years
        var diffMonths = diffYears * 12 + month2 - month1 - 1 + day2 / nbDaysMonth2 + (nbDaysMonth1 - day1 + 1) / nbDaysMonth1;
        if (returnType === "months")
            interval = diffMonths;
        else
            interval = diffMonths / 12;
    }
    return parseFloat(interval.toFixed(decimals));
}
export function formatDateFieldUTC(date) {
    // If date is a Date object, use it directly
    // If it's an ISO string, strip the time part if present
    var dateStr = date instanceof Date ? date.toISOString() : date;
    var d = new Date(dateStr.split('T')[0] + 'T12:00:00.000Z');
    var month = String(d.getUTCMonth() + 1).padStart(2, '0');
    var day = String(d.getUTCDate()).padStart(2, '0');
    var year = d.getUTCFullYear();
    return [year, month, day].join("-");
}
export function formatFrenchDate(dateStr) {
    // Convert YYYY-MM-DD to DD/MM/YYYY
    var _a = dateStr.split('-'), year = _a[0], month = _a[1], day = _a[2];
    return "".concat(day, "/").concat(month, "/").concat(year);
}
