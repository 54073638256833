import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { submit, formValueSelector, getFormValues } from "redux-form";
import SaveIcon from "@material-ui/icons/Save";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import NumberFormat from "react-number-format";
import { getEntrySummary, getCascadingEntrySummary } from "../calculations";
import MergeButton from "./MergeButton"

let CaseFooter = props => {
  const { entries, caseValues, dispatch, caseId, token } = props;
  let victimEntries = entries;
  let victimValues = caseValues ||{};

  const victimId = victimValues.currentVictim


  if (victimId && caseValues.indirectVictims && caseValues.indirectVictims.length >= victimId && caseValues.indirectVictims[victimId-1]) {
    victimValues = caseValues.indirectVictims[victimId-1].caseData;
    victimEntries = caseValues.indirectVictims[victimId-1].caseDataForm;
  }

  const _meta = victimValues._meta || {};

  const victimSummaryData = _meta.victimSummary || {}

  const [detailedView, setDetailedView] = useState(false);


  return (
    <div className="fixed-bottom text-white footer d-flex">
      <div className="ml-5 pl-5 p-2">
        <table>
          <thead>
            <tr>
              <th colSpan="3">
                <h2 className="mb-0">
                  Synthèse du dossier{" "}
                  {victimValues && victimValues._meta && Object.keys(victimValues._meta).length > 0 && (
                    <span
                      onClick={() => setDetailedView(!detailedView)}
                      title={`${detailedView ? "Masquer" : "Afficher"} le détail par poste`}
                    >
                      {detailedView ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                    </span>
                  )}
                </h2>
              </th>
            </tr>
            {(victimSummaryData.annuityTotal > 0) && (
              <tr>
                <th></th>
                <th className="text-center align-bottom">en capital</th>
                <th className="text-center align-bottom">
                  sous forme de rente
                  <br />
                  <small>(montant annualisé)</small>
                </th>
              </tr>
            )}
          </thead>
          {detailedView &&
            victimValues &&
            victimValues._meta &&
            Object.keys(victimValues._meta).length > 0 && (
            <tbody>
              {victimEntries.map(
                (entry, i) =>
                  (victimId && !isNaN(entry.id) && entry.parentId === 0 && (
                    <tr key={i}>
                      <td className="l1">
                        {entry.label}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  )) ||
                    (!isNaN(entry.id) && entry.parentId !== 0 && (
                      <tr key={i}>
                        <td className="l1">
                          {victimEntries.filter(e => e.id === entry.parentId).map(e => e.label) + " "}
                          {entry.label.toLowerCase()}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    )) ||
                    (victimValues._meta[entry.id] &&
                      victimValues._meta[entry.id].items &&
                      (getEntrySummary(victimValues._meta[entry.id].items, entry.id).total > 0 ||
                        getEntrySummary(victimValues._meta[entry.id].items, entry.id).annuityTotal > 0 ||
                        getEntrySummary(victimValues._meta[entry.id].items, entry.id).hasTPP) && (
                        <tr key={i}>
                        <td className={entry.parentId !== 0 ? "l3" : "l1"}>
                            {entry.label}
                            {entry.additionalLabel}
                          </td>
                        <td
                            className={`text-right col-amount${entry.parentId !== 0 ? " l3" : "l1"
                          }`}
                          >
                            {entry.id.indexOf("PROV") === 0 && "-"}
                            <NumberFormat
                            displayType="text"
                            thousandSeparator=" "
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            type="text"
                            value={
                              entry.id === "PGPF" || entry.id === "IP"
                                ? Math.max(getCascadingEntrySummary(victimValues._meta[entry.id].items, entry.id).total, 0)
                                : Math.max(
                                  getEntrySummary(victimValues._meta[entry.id].items, entry.id).total,
                                  0,
                                )
                            }
                            suffix=" €"
                          />
                          </td>
                        <td
                            className={`text-right col-amount${entry.parentId !== 0 ? " l3" : ""}`}
                          >
                            {getEntrySummary(victimValues._meta[entry.id].items, entry.id).annuityTotal >
                              0 && (
                              <NumberFormat
                              displayType="text"
                              thousandSeparator=" "
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale={true}
                              type="text"
                              value={
                                getEntrySummary(victimValues._meta[entry.id].items, entry.id).annuityTotal
                              }
                              suffix=" €"
                            />
                          )}
                          </td>
                      </tr>
                    )),
              )}
            </tbody>
          )}
          <tfoot>
            <tr>
              <th className="align-top">Total à percevoir par la victime</th>
              <td className="text-right col-amount align-top">
                <NumberFormat
                  displayType="text"
                  thousandSeparator=" "
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={true}
                  type="text"
                  value={victimSummaryData.total}
                  suffix=" €"
                />
                {victimSummaryData.total < 0 && victimSummaryData.annuityTotal > 0 && (
                  <Fragment>
                    <br />
                    <small>(montant à déduire de la rente versée)</small>
                  </Fragment>
                )}
              </td>
              <td className="text-right col-amount align-top">
                {victimSummaryData.annuityTotal > 0 && (
                  <NumberFormat
                    displayType="text"
                    thousandSeparator=" "
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={true}
                    type="text"
                    value={victimSummaryData.annuityTotal}
                    suffix=" €"
                  />
                )}
              </td>
              {/* {victimSummaryData.cascadeTotal.beforeAnnuityTotal < 0 && (
                <td colSpan="3">
                  Le surplus perçu au titre des PGPF (
                  <NumberFormat
                    displayType="text"
                    thousandSeparator=" "
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={true}
                    type="text"
                    value={-victimSummaryData.cascadeTotal.beforeAnnuityTotal}
                    suffix=" €"
                  />
                  ) sera déduit de la rente versée au titre des PGPF.
                </td>
              )}
              {victimSummaryData.cascadeTotal.total < 0 && (
                <td colSpan="3">
                  Le surplus perçu au titre des PGPF, de l&apos;incidence professionnelle (IP) et du
                  DFP (au total&nbsp;
                  <NumberFormat
                    displayType="text"
                    thousandSeparator=" "
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={true}
                    type="text"
                    value={-victimSummaryData.cascadeTotal.total}
                    suffix=" €"
                  />
                  ) n'est pas reporté.
                </td>
              )} */}
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="p-2 ml-auto">

        <button
          type="submit"
          className="btn btn-primary ml-auto w-100"
          onClick={() => {
            dispatch(submit("caseDataForm"));
          }}
          title="Enregistrer les modifications"
        >
          <SaveIcon fontSize="large" className="saveIcon" />
        </button>

      </div>
      <MergeButton caseId={caseId} token={token} victimId={victimId} />
    </div>
  );
};

const victimSelector = formValueSelector("victim");

const mapStateToProps = state => {
  let entries = [];
  let victim = {};
  let caseValues = {};
  let token = '';

  if (state.auth && state.auth.token)
    token = state.auth.token;

  if (state.cases && state.cases.case && state.cases.case.json_data) {
    entries = state.cases.case.json_data.caseDataForm;
    victim.rateOfCompensationEntitlement = victimSelector(
      state,
      "rateOfCompensationEntitlement",
    );
    victim.disableCascade = victimSelector(state, "disableCascade");
    victim.birthDate = victimSelector(state, "birth_date");
    victim.gender = victimSelector(state, "victimGender");

    caseValues = getFormValues("caseDataForm")(state) || {};
  }
  return { entries, victim, caseValues, token };
};

CaseFooter.propTypes = {
  entries: PropTypes.array,
  victim: PropTypes.object,
  caseValues: PropTypes.object,
  dispatch: PropTypes.func,
  caseId: PropTypes.number,
};

export default connect(mapStateToProps)(CaseFooter);
