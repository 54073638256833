import React, { Fragment } from "react";
import PropTypes from "prop-types";
import AmountField from "../../fields/AmountField"
import { annuityPeriods } from "../constants";

function AnnuitiesSummary(props) {
  const { annuityDetails, annuityTotal, colSpan } = props;

  return (
    <tr>
      <td />
      <th scope="row" colSpan={colSpan}>
        Créance la victime versée sous forme de rente (montant annualisé)
        {Object.keys(annuityDetails).length > 0 && (
          <small>
            <br />
                      Ce montant annualisé se compose d'
            {Object.entries(annuityDetails).map((period, i) => (
              <Fragment key={period[0]}>
                {"une rente " +
                  annuityPeriods
                    .filter(p => p[0] === parseInt(period[0]))
                    .map(p => p[1]) +
                  " de " +
                  Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(period[1])}
                {i === Object.entries(annuityDetails).length - 2
                  ? " et "
                  : i === Object.entries(annuityDetails).length - 1
                    ? ""
                    : ", "}
              </Fragment>
            ))}
          </small>
        )}
      </th>
      <td className="col-amount">
        <AmountField fieldValue={annuityTotal} />
      </td>
      <td />
    </tr>
  );
}

AnnuitiesSummary.propTypes = {
  annuityDetails: PropTypes.object,
  annuityTotal: PropTypes.number,
};

export default AnnuitiesSummary;
