import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import DisplayTableDFP from "./DisplayTableDFP";
import { interval } from "../utils"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({

  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));


const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      width: '100%',
      backgroundColor: '#2196F3',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);



const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    '&$selected': {
      color: '#2196F3',
    },
    '&:focus': {
      color: '#2196F3',
    },
  },
  selected: { color: '#2196F3' },
  // palette: {
  //     primary: {
  //         main: '#2196F3'
  //     }
  // }
}))((props) => <Tab disableRipple {...props} />);

export default function FullWidthTabsDFP(props) {
  const { tables, victim, damageRate, lineIndex, handleClose } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  let consolidationAge = null;

  if (victim && victim.consolidationDate){
    consolidationAge = Math.trunc(interval(victim.birthDate, victim.consolidationDate, "years"))
  }
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const getTableRows = (table, victim) => {
    let tableRows = [];
    if (table.bySex)
      tableRows = table.rows[victim.gender]
    else
      tableRows = table.rows
    return tableRows;
  }

  const getCaption = (table, victim) => {
    let caption = [];
    if (table.bySex)
      caption = table.caption[victim.gender]
    else
      caption = table.caption
    return caption;
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <StyledTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {tables.map((table, i) => (
            <StyledTab key={i} label={table.tabLabel.map((labelLine, j) => (<Fragment key={j}>{labelLine}<br /></Fragment>))} wrapped {...a11yProps(i)} />
          ))}
        </StyledTabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {tables.map((table, i) => (
          <TabPanel key={i} value={value} index={i} dir={theme.direction}>
            <DisplayTableDFP
              header={table.header}
              rows={getTableRows(table, victim)}
              caption={getCaption(table, victim)}
              firstColumn={table.firstColumn}
              damageRate={damageRate && damageRate.toString().replace(",",".")}
              age={consolidationAge}
              lineIndex={lineIndex}
              currentTab={value}
              handleClose={() => handleClose()}
            />
          </TabPanel>
        ))}
      </SwipeableViews>
    </div>
  );
}
