import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'justify',
    width: 500,
  },

}));

let LegalFooter = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <nav className="pl-0 navbar fixed-bottom navbar-expand-sm navbar-dark header">
      <div className="pl-1 container-fluid">
        <ul className="navbar-nav m-auto mt-2 mt-lg-0">
          <li className="nav-item">
            <a href="mailto:quantum@juri-solutions.fr" className="nav-link">Contact</a>
          </li>
          <li className="nav-item">
            <a href="#" onClick={handleOpen} className="nav-link">Mentions légales</a>
          </li>
          <li className="nav-item">
            <a href="https://juri-solutions.fr/quantum-cgau/" target="_blank" rel="noopener noreferrer" className="nav-link">CGAU</a>
          </li>
        </ul>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Mentions légales</h2>
            <p>Le site https://quantum.juri-solutions.fr/ est exploité par la SAS Jurisolutions, au capital social de 5 000 euros, immatriculée au RCS de Paris sous le numéro 881 434 435, et dont le siège social est le 3bis passage Delessert 75010 Paris. Le numéro de TVA de Jurisolutions est FR91881434435.</p>
            <p>Le directeur de la publication est M. Mathieu Bouquet, que vous pouvez contacter en envoyant un courriel à quantum@juri-solutions.fr.</p>
            <p>La solution Quantum, accessible à https://quantum.juri-solutions.fr/, est hébergée par la société Scaleway, dont le siège social est 8 rue de la Ville l'Evêque, 75008 Paris, France.</p>
            <h2 id="transition-modal-title">Utilisation des données personnelles</h2>
            <p>Les données personnelles (adresse email) que l'utilisateur de la solution Quantum communique dans le formulaire d'inscription sur le site https://juri-solutions.fr/ ont pour finalité la gestion des relations entre la société Jurisolutions et les utilisateurs de Quantum. La collecte des adresses emails est indispensable pour gérer les demandes, les avis, les comptes utilisateurs.</p>
            <p>Les données renseignées dans le logiciel Quantum afin de créer un dossier, calculer les préjudices font l'objet d'un traitement automatisé indispensable aux fins d'utilisation du service pour lequel l'utilisateur s'est inscrit. A cette fin ce traitement est licite.</p>
            <p>Les données sont conservées pendant la durée de la relation entre l'utilisateur et la société Jurisolutions et pendant une durée d'une année suivant l'expiration de cette relation, sauf dispositions légales ou règlementaires contraires, ou demande contraire.</p>
            <p>La société Jurisolutions est l'unique destinataire de ces données. Aucune information personnelle n'est collectée à l'insu de l'utilisateur ou cédée à des tiers. En vertu de la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, vous disposez d'un droit d'opposition, d'accès et de rectification des données vous concernant, que vous pouvez exercer auprès de la société Jurisolutions en écrivant à : quantum@juri-solutions.fr.</p>
            <p>Vous pouvez former une réclamation auprès de la CNIL.</p>
          </div>
        </Fade>
      </Modal>
    </nav>
  );
};

export default LegalFooter;

