export function substituteNullDate(arr) {
    return arr.map(obj => {
        const clonedObj = { ...obj };
        if (!clonedObj.endDate && clonedObj.startDate) {
            clonedObj.endDate = clonedObj.startDate;
        }
        if (!clonedObj.startDate && clonedObj.endDate) {
            clonedObj.startDate = clonedObj.endDate;
        }
        return clonedObj;
    });
}

export function findOverlappingPeriods(periods) {
    const clonedPeriods = substituteNullDate(periods);
    if (clonedPeriods && clonedPeriods.length) {
        const overlappingPeriodsIdx = [];
        for (let i = 0; i < clonedPeriods.length; i++) {
            const currentPeriod = clonedPeriods[i];
            if (!currentPeriod.startDate && !currentPeriod.endDate) {
                continue;
            }
            let isOverlapping = false;
            for (let j = 0; j < clonedPeriods.length; j++) {
                if (i !== j) {
                    const nextPeriod = clonedPeriods[j];
                    if (!nextPeriod.startDate && !nextPeriod.endDate) {
                        continue;
                    }
                    if (new Date(currentPeriod.endDate) >= new Date(nextPeriod.startDate) &&
                        new Date(currentPeriod.startDate) <= new Date(nextPeriod.endDate)) {
                        isOverlapping = true;
                        break;
                    }
                }
            }
            if (isOverlapping) {
                overlappingPeriodsIdx.push(i);
            }
        }
        return overlappingPeriodsIdx;
    }
    else {
        return [];
    }
}

export const isHighlight = (index, overLappingIndex) => {
    return overLappingIndex && overLappingIndex.indexOf(index) >= 0
  }

export const isItemIndexOverlapping = (itemArr, index) => {
    const overlappingPeriodsIdxArr = itemArr && findOverlappingPeriods(itemArr);
    return isHighlight(index, overlappingPeriodsIdxArr) ?? false;    
}

export const getOverlapStatus = (entry, values, index, type) => {
    return entry === type && isItemIndexOverlapping(values[`${type}0`], index);
};