// all calculations necessary to summarize the financial dimensions of a given case

import { itemTypes } from "./itemTypes.js";
import { interval } from "../utils.js";
import * as capiTables from "../capitalisation_tables/capitalisationTables.js";
import { refIndexes } from "../discounting/constants.js";
import { getPgpaLineDamage, getPGPFFutureLineAmount } from "./calculations/PGP.js"

// returns the PER (float) from a capitalisation table, given:
//  - the capitalisation table id (requires all capitalisation tables to be imported as capiTables)
//  - the victim's gender ("M" or "F")
//  - the start and the end age
export const getPERFromCapiTable = (selectedCapiTable, victimGender, startAge, selectedEndAge) => {
  let PER = 0;
  if (selectedCapiTable && victimGender && startAge >= 0 && selectedEndAge >= 0) {
    PER =
      capiTables[selectedCapiTable]
        .get(victimGender)
        .filter(ent => ent[0] === parseInt(startAge) && ent[1] === parseInt(selectedEndAge))
        .map(entry => entry[2]) || 0;
  }

  if (PER.length > 0)
    PER = PER[0]

  return PER;
};

export const getItemHoursTotal = (itemValues) => { // used only in TPT items
  let itemHoursTotal = 0;

  itemValues && itemValues.forEach((line) => 
    itemHoursTotal += getHoursTotal(line, "TPT")
  )

  return Math.round(itemHoursTotal * 100) / 100;
}

export const getItemHoursTotalStr = (itemValues) => { // used only in TPT items
  let itemHoursTotal = getItemHoursTotal(itemValues);
  let itemHoursTotalStr = "Total sur le poste : " + Intl.NumberFormat("fr-FR", { maximumFractionDigits: 2 }).format(itemHoursTotal) + " heure" + (itemHoursTotal >= 2 ? "s" : "");

  return itemHoursTotalStr;
}


export const getHoursTotal = (values, itemType) => {
  let hoursTotal = 0;
  if (values.hours) {
    const nbHours = parseFloat(values.hours.replace(",", ".").replace(/\s/g, ""));
    const daysPerYear = values.daysPerYear ? parseFloat(values.daysPerYear.toString().replace(",", ".").replace(/\s/g, "")) : 0;
    if (itemType === "TPF") {
      hoursTotal = nbHours;
      switch (values.periodType) {
        case "months":
          hoursTotal *= parseFloat(values.monthsPerYear || 0) * daysPerYear / 365;
          break;
        case "weeks":
          hoursTotal *= parseFloat(values.weeksPerYear || 0) * daysPerYear / 365;
          break;
        case "days":
        default:
          hoursTotal *= daysPerYear;
      }
    } else if (itemType === "TPT") {
      let duration = interval(values.startDate, values.endDate, values.durationType || "days");
      hoursTotal = nbHours * duration * daysPerYear / 365;
    }
  }

  return Math.round(hoursTotal * 100) / 100;
}

export const getHoursTotalStr = (values, itemType) => {
  let hoursTotal = getHoursTotal(values, itemType);
  let hoursTotalStr = Intl.NumberFormat("fr-FR", { maximumFractionDigits: 2 }).format(hoursTotal) + " heure" + (hoursTotal >= 2 ? "s" : "");
  if (itemType === "TPF") hoursTotalStr += " par an";

  return hoursTotalStr;
};


// returns the raw line amount, before any deduction (ROCE, loss of opportunity or TPP)
export const getLineAmount = (values, entry, lineIndex, item, arrerageType, arrerageIndex) => {
  if (!arrerageType && (!item.computedAmount || values[entry][lineIndex].amountDirectInput)) {
    return parseFloat(
      values[entry][lineIndex].lineAmount
        ? values[entry][lineIndex].lineAmount.replace(",", ".").replace(/\s/g, "")
        : 0,
    );
  }
  let lineAmount = 0;

  // special case of calculation, only for the DSF type items
  if (arrerageType === "past") {
    lineAmount = parseFloat(
      values[entry][lineIndex].arr.past[arrerageIndex].lineAmount
        ? values[entry][lineIndex].arr.past[arrerageIndex].lineAmount.replace(",", ".").replace(/\s/g, "")
        : 0,
    );
    lineAmount = Math.round(lineAmount * 100) / 100
    return lineAmount;
  }

  if (arrerageType === "future") {
    if (values[entry][lineIndex].arr.future[arrerageIndex].amountDirectInput) {
      lineAmount = parseFloat(
        values[entry][lineIndex].arr.future[arrerageIndex].lineAmount
          ? values[entry][lineIndex].arr.future[arrerageIndex].lineAmount.replace(",", ".").replace(/\s/g, "")
          : 0,
      );
      lineAmount = Math.round(lineAmount * 100) / 100
      return lineAmount;
    }

    if (values[entry][lineIndex].arr.future[arrerageIndex].unitAmount && values[entry][lineIndex].arr.future[arrerageIndex].frequencyNum && values[entry][lineIndex].arr.future[arrerageIndex].frequencyType)
      if (values[entry][lineIndex].arr.future[arrerageIndex].frequencyType === "day")
        lineAmount = parseFloat(values[entry][lineIndex].arr.future[arrerageIndex].unitAmount.replace(",", ".").replace(/\s/g, "")) *
          365 * parseInt(values[entry][lineIndex].arr.future[arrerageIndex].frequencyNum);
      else if (values[entry][lineIndex].arr.future[arrerageIndex].frequencyType === "week")
        lineAmount = parseFloat(values[entry][lineIndex].arr.future[arrerageIndex].unitAmount.replace(",", ".").replace(/\s/g, "")) *
          52 * parseInt(values[entry][lineIndex].arr.future[arrerageIndex].frequencyNum);
      else
        lineAmount = parseFloat(values[entry][lineIndex].arr.future[arrerageIndex].unitAmount.replace(",", ".").replace(/\s/g, "")) *
          parseFloat(values[entry][lineIndex].arr.future[arrerageIndex].frequencyNum.toString().replace(",", ".")) * 12 /
          parseInt(values[entry][lineIndex].arr.future[arrerageIndex].frequencyType);
    if (values[entry][lineIndex].arr.future[arrerageIndex].discounting && values[entry][lineIndex].arr.future[arrerageIndex].discounting.indexValue)
      lineAmount *= values[entry][lineIndex].arr.future[arrerageIndex].discounting.indexValue
    lineAmount = Math.round(lineAmount * 100) / 100
    return lineAmount;
  }
  // end of DSF items special case

  let firstFieldTest = false;
  if (item.itemType === "PGPA")
    lineAmount = getPgpaLineDamage(values[entry][lineIndex])
  else if (item.itemType === "PGPFProj")
    lineAmount = getPGPFFutureLineAmount(values[entry][lineIndex]);
  else
    itemTypes.get(item.itemType).map(field => {
      if (field.computedAmount)
        if (!firstFieldTest) {
          firstFieldTest = true;
          if (field.name === "duration") {
            lineAmount = interval(
              values[entry][lineIndex].startDate,
              values[entry][lineIndex].endDate,
              values[entry][lineIndex].durationType || "days",
            );
            if (item.itemType === "TPT") {
              const daysPerYear = values[entry][lineIndex].daysPerYear ? parseFloat(values[entry][lineIndex].daysPerYear.toString().replace(",", ".").replace(/\s/g, "")) : 0;
              lineAmount *= daysPerYear / 365;
            }
          } else {
            lineAmount = values[entry][lineIndex][field.name]
              ? parseFloat(
                values[entry][lineIndex][field.name].replace(",", ".").replace(/\s/g, ""),
              )
              : 0;
          }
        } else {
          if (field.name === "duration") {
            lineAmount *= interval(
              values[entry][lineIndex].startDate,
              values[entry][lineIndex].endDate,
              values[entry][lineIndex].durationType || "days",
            );

            if (item.itemType === "TPT")
              switch (values[entry][lineIndex].durationType) {
                case "months":
                  lineAmount *= values[entry][lineIndex].monthsPerYear / 12;
                  break;
                case "weeks":
                  lineAmount *= values[entry][lineIndex].weeksPerYear / 52;
                  break;
                case "days":
                default:
                  const daysPerYear = values[entry][lineIndex].daysPerYear ? parseFloat(values[entry][lineIndex].daysPerYear.toString().replace(",", ".").replace(/\s/g, "")) : 0;
                  lineAmount *= daysPerYear / 365;
              }
          } else if (item.itemType === "TPF" && field.name === "periodType") {
            let periodType = values[entry][lineIndex].periodType;
            const daysPerYear = values[entry][lineIndex].daysPerYear ? parseFloat(values[entry][lineIndex].daysPerYear.toString().replace(",", ".").replace(/\s/g, "")) : 0;
            switch (periodType) {
              case "days":
                lineAmount *= daysPerYear;
                break;
              case "weeks":
                lineAmount *= values[entry][lineIndex].weeksPerYear * daysPerYear / 365;
                break;
              case "months":
                lineAmount *= values[entry][lineIndex].monthsPerYear * daysPerYear / 365;
                break;
            }
          } else if (item.itemType === "PGPA" && field.name === "periodRate" && values[entry][lineIndex].discounting && values[entry][lineIndex].discounting.discountedTotal) {
            lineAmount = values[entry][lineIndex].discounting.discountedTotal;
          } else if (item.itemType === "PGPA" && field.name === "workIncome") {
            let workIncome = values[entry][lineIndex][field.name]
              ? parseFloat(values[entry][lineIndex][field.name].replace(",", ".").replace(/\s/g, ""))
              : 0;
            if (workIncome && values[entry][lineIndex].incomeType && values[entry][lineIndex].incomeType !== "period") {
              let incomeType = values[entry][lineIndex].incomeType
              let durationNum = interval(
                values[entry][lineIndex].startDate,
                values[entry][lineIndex].endDate,
                incomeType,
              );
              lineAmount -= workIncome * durationNum
            }
            else
              lineAmount -= workIncome
            if (lineAmount < 0) lineAmount = 0
          } else {
            lineAmount *= values[entry][lineIndex][field.name]
              ? parseFloat(
                values[entry][lineIndex][field.name].replace(",", ".").replace(/\s/g, ""),
              )
              : 0;
          }
        }
    });
  if (item.itemType === "DFT") lineAmount = lineAmount / 100;

  if (isNaN(lineAmount)) lineAmount = 0;
  lineAmount = Math.round(lineAmount * 100) / 100
  return lineAmount;
};

export const getWorkIncomeTotal = (line) =>{
  let workIncomeTotal = 0;
  let workIncome = 0;

  if (line.workIncome) {
    workIncome = parseFloat(line.workIncome.replace(",", ".").replace(/\s/g, "")) || 0;
    const incomeType = line.incomeType;
    if (incomeType==="period")
      workIncomeTotal = workIncome;
    else {
      const durationNum = interval(
        line.startDate,
        line.endDate,
        incomeType,
      );
      workIncomeTotal = workIncome * durationNum;
    }
  }

  return workIncomeTotal;
}


export const getRefIncomeTotal = (line) =>{
  let refIncomeTotal = 0;
  let refIncome = 0;

  if (line.periodRate) {
    refIncome = parseFloat(line.periodRate.replace(",", ".").replace(/\s/g, "")) || 0;
    const durationNum = interval(
      line.startDate,
      line.endDate,
      line.durationType,
    );
    refIncomeTotal = refIncome * durationNum;
  }

  return refIncomeTotal;
}


export const splitRefIncomeByMonth = (line) =>{
  let workIncomeByMonth = [];
  let startDateStr, endDateStr;
  if (line.startDate)
    startDateStr = line.startDate;
  else startDateStr = line.endDate;
  if (line.endDate)
    endDateStr = line.endDate;
  else endDateStr = line.startDate;

  if (startDateStr) {
    const endDate = new Date(endDateStr);
    const startDate = new Date(startDateStr)
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
    const nbDaysStartMonth = new Date(startYear, startMonth + 1, 0).getDate();
    let workedDaysStartMonth = nbDaysStartMonth - startDate.getDate() + 1;
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();
    const nbDaysEndMonth = new Date(endYear, endMonth + 1, 0).getDate();
    let workedDaysEndMonth = endDate.getDate();
    if (startYear === endYear && startMonth === endMonth)
      workedDaysStartMonth = workedDaysEndMonth = workedDaysEndMonth - startDate.getDate() + 1;
    const refIncomeTotal = getRefIncomeTotal(line);
    const monthlyRefIncome = Math.round((refIncomeTotal / interval(line.startDate, line.endDate, "months")) * 100) / 100;
    const startMonthIncome = Math.round((monthlyRefIncome * workedDaysStartMonth / nbDaysStartMonth) * 100) / 100;
    const endMonthIncome = Math.round((monthlyRefIncome * workedDaysEndMonth / nbDaysEndMonth) * 100) / 100;
    let date = new Date(startDateStr);

    while (date <= endDate)
    {
      if (date.getTime() === startDate.getTime())
      {
        workIncomeByMonth.push([date.getFullYear(), date.getMonth()+1, startMonthIncome])
        date.setDate(1)
      }
      else if (date.getFullYear() === endDate.getFullYear() && date.getMonth() === endDate.getMonth())
        workIncomeByMonth.push([date.getFullYear(), date.getMonth()+1, endMonthIncome])
      else
        workIncomeByMonth.push([date.getFullYear(), date.getMonth()+1, monthlyRefIncome])

      date.setMonth(date.getMonth() + 1);
    }
  }

  return workIncomeByMonth;
}


export const discountMonthlyAmounts = (line, endRefIndexValue) => {
  //returns discounted monthly amounts of a period :
  // - 0: year (int)
  // - 1: month (int)
  // - 2: reference amount (float)
  // - 3: smic value at month/year (float)
  // - 4: revalued amount (float)
  // - 5: value of the reference index
  // - 6: discounted amount

  const discountedMonthlyAmounts = splitRefIncomeByMonth(line);
  let refIndex;
  if (line.discounting && line.discounting.refIndex)
    refIndex = line.discounting.refIndex;

  if (discountedMonthlyAmounts.length > 0) {
    const startSmicRecord = refIndexes["smic"].data.filter(monthIdx => monthIdx[0] === discountedMonthlyAmounts[0][0] && monthIdx[1] === discountedMonthlyAmounts[0][1])
    const startIpcRecord = refIndexes["inflation"].data.filter(monthIdx => monthIdx[0] === discountedMonthlyAmounts[0][0] && monthIdx[1] === discountedMonthlyAmounts[0][1])
    let startIpc = refIndexes["inflation"].data[refIndexes["inflation"].data.length-1][2];
    let startSmic = refIndexes["smic"]['latest'];
    if (startIpcRecord.length > 0)
      startIpc = startIpcRecord[0][2]
    if (startSmicRecord.length > 0)
      startSmic = startSmicRecord[0][2]

    let year = 0;
    let month = 0;
    let amount = 0;
    let refIndexRecord = [];
    let smicIndexRecord = [];

    discountedMonthlyAmounts.map(monthlyAmount => {
      year = monthlyAmount[0];
      month = monthlyAmount[1];
      amount = monthlyAmount[2];
      let refIndexValue = endRefIndexValue;
      refIndexRecord = refIndexes["inflation"].data.filter(monthIdx => monthIdx[0] === year && monthIdx[1] === month)
      if (refIndexRecord.length > 0)
        refIndexValue = refIndexRecord[0][2];
      let revaluedAmount = amount
      let smicValue = startSmic;
      smicIndexRecord = refIndexes["smic"].data.filter(monthIdx => monthIdx[0] === year && monthIdx[1] === month)
      if (smicIndexRecord.length > 0)
        smicValue = smicIndexRecord[0][2];
      if (refIndex === "double")
        revaluedAmount = Math.round(100 * revaluedAmount * smicValue / startSmic) / 100;
      else if (refIndex === "doubleipc")
        revaluedAmount = Math.round(100 * revaluedAmount * refIndexValue / startIpc) / 100;
      let discountedAmount = revaluedAmount;
      if (refIndex !== "smic")
        discountedAmount = Math.round(100 * discountedAmount * endRefIndexValue / refIndexValue) / 100;
      else
        discountedAmount = Math.round(100 * discountedAmount * smicValue / startSmic) / 100;
      monthlyAmount.push(smicValue)
      monthlyAmount.push(round2Decimal(revaluedAmount))
      monthlyAmount.push(refIndexValue);
      monthlyAmount.push(round2Decimal(discountedAmount));
    },
    )
  }

  return discountedMonthlyAmounts;
}


export const getDiscountedTotals = (discountedMonthlyAmounts) => {
  let discountedTotals = {
    refIncomeTotal:0,
    revaluedIncomeTotal:0,
    discountedTotal:0,
  };

  discountedMonthlyAmounts.map(month =>
  {
    discountedTotals.refIncomeTotal+=month[2]
    discountedTotals.revaluedIncomeTotal+=month[4]
    discountedTotals.discountedTotal+=month[6]
  },
  )

  return discountedTotals;
}


export const getDiscountedLineAmount = (values, entry, lineIndex, item, victim, arrerageType, arrerageIndex) => {
  let discountedLineAmount = Math.max(0, lineVictimAmount(values, entry, lineIndex, item, victim, arrerageType, arrerageIndex));

  if (values[entry][lineIndex].discounting && values[entry][lineIndex].discounting.indexValue && item.itemType!=="PGPA" && item.itemType!=="PGPFProj" && values[entry][lineIndex].tpp && values[entry][lineIndex].tpp.length > 0) // condition on tpp because actualisation with tpp is already done in lineVictimAmount through lineResponsiblePartyAmount
    discountedLineAmount *= values[entry][lineIndex].discounting.indexValue;

  discountedLineAmount = Math.round((discountedLineAmount + Number.EPSILON) * 100) / 100

  return discountedLineAmount;
};

export const lineAmountAfterLossOfOpportunity = (values, entry, lineIndex, item, arrerageType, arrerageIndex) => {
  let lineAmount = getLineAmount(values, entry, lineIndex, item, arrerageType, arrerageIndex);

  if (
    values[entry][lineIndex].lossOfOpportunity &&
    values[entry][lineIndex].lossOfOpportunity.check &&
    values[entry][lineIndex].lossOfOpportunity.num
  )
    lineAmount *= parseFloat(values[entry][lineIndex].lossOfOpportunity.num.replace(",", ".")) / 100;

  return lineAmount;
};


export const getResponsiblePartyAmount = (amount, victim) => {
  let responsiblePartyAmount = 0;

  responsiblePartyAmount = amount * (parseInt(victim.rateOfCompensationEntitlement) || 100) / 100;

  return responsiblePartyAmount;
}


export const lineResponsiblePartyAmount = (values, entry, lineIndex, item, victim, arrerageType, arrerageIndex) => {
  let responsiblePartyAmount = 0;
  let lineAmountAfterLOP = lineAmountAfterLossOfOpportunity(values, entry, lineIndex, item, arrerageType, arrerageIndex);

  if (entry.indexOf("PROV") === 0 || entry.indexOf("DNAPRDI") === 0)
    return lineAmountAfterLOP;

  // if (values[entry][lineIndex].discounting && values[entry][lineIndex].discounting.indexValue && (!values[entry][lineIndex].tpp || values[entry][lineIndex].tpp.length === 0))
  //   lineAmountAfterLOP *= values[entry][lineIndex].discounting.indexValue;
  responsiblePartyAmount = Math.round(getResponsiblePartyAmount(lineAmountAfterLOP, victim)*100)/100;

  return responsiblePartyAmount;
};


export const getDsfFutureAnnualDamage = (values, entry, lineIndex, item) => {
  let lineFutureDsfTotal = 0;

  values[entry][lineIndex].arr.future.forEach(
    (line, i) => {
      lineFutureDsfTotal += getLineAmount(values, entry, lineIndex, item, "future", i);
    },
  )

  return lineFutureDsfTotal;
}


export const getDsfFutureArrSummary = (values, entry, lineIndex, item, victim) => {
  let dsfFutureArrSummary = {
    hasTPP: false,
    damageAmount: 0,
    victimAmount: 0,
    responsiblePartyAmount: 0,
    tppRecourse: 0,
    tppTotal: 0,
    futureTppSurplus: 0,
    hasNonAffected: false,
    nonAffectedTotal: 0,




    victimAmountBeforeCascade: 0,
    victimNonCompensatedPart: 0, // nonAffectedTotal
    maxCascadableTpp: 0,
    tppSurplus: 0,
    maxAbsorbableTpp: 0,
    ncpAbsorbedTpp: 0,
    ncpCascadedTpp:0,
    tppSurplusAfterNcpCascade: 0,
    victimAmountAfterNcpCascade: 0,
    absorbedTppSurplus: 0,
    cascadedTppSurplus: 0,
    totalAbsorbedTpp: 0,
    totalCascadedTpp: 0,
    victimAmountAfterCascade: 0, // after all cascades
    respPartyAmountAfterDiscounting:0,
  }

  let futureTpp = values[entry][lineIndex].arr.annCap.tppCap;
  let futureArrTppTotal = 0;

  let pastMaxCascadableTpp = getMaxCascadableTpp(values, entry, lineIndex, item, victim, "past");
  let pastNcpAbsorbedTpp = getNcpAbsorbedTpp(values, entry, lineIndex, item, victim, "past");
  let pastTppSurplusAfterNcpCascade = getTppSurplusAfterNcpCascade(values, entry, lineIndex, item, victim, "past");
  let pastAbsorbedTppSurplus = getAbsorbedTppSurplus(values, entry, lineIndex, item, victim, "past");

  dsfFutureArrSummary.damageAmount = dsfLineCapitalisedAmount(values, entry, lineIndex, item, victim).capitalisedAmount;
  dsfFutureArrSummary.responsiblePartyAmount = dsfFutureArrSummary.victimAmountBeforeCascade = Math.round(getResponsiblePartyAmount(dsfFutureArrSummary.damageAmount, victim) * 100) / 100;

  if (futureTpp && futureTpp.length > 0) {
    dsfFutureArrSummary.hasTPP = true;
    futureArrTppTotal = tppTotal(futureTpp);
    if (futureArrTppTotal > 0) {
      dsfFutureArrSummary.victimAmount = Math.min(dsfFutureArrSummary.damageAmount - futureArrTppTotal, dsfFutureArrSummary.responsiblePartyAmount);
      dsfFutureArrSummary.tppTotal = futureArrTppTotal;
      dsfFutureArrSummary.tppRecourse = dsfFutureArrSummary.responsiblePartyAmount - Math.max(dsfFutureArrSummary.victimAmount, 0);
    }
  }

  dsfFutureArrSummary.victimNonCompensatedPart = Math.max(0, dsfFutureArrSummary.damageAmount - dsfFutureArrSummary.responsiblePartyAmount - dsfFutureArrSummary.tppTotal);
  dsfFutureArrSummary.maxCascadableTpp = Math.max(0, dsfFutureArrSummary.tppTotal - (dsfFutureArrSummary.damageAmount - dsfFutureArrSummary.responsiblePartyAmount));
  dsfFutureArrSummary.tppSurplus = Math.max(0, dsfFutureArrSummary.tppTotal - dsfFutureArrSummary.damageAmount);
  dsfFutureArrSummary.maxAbsorbableTpp = Math.max(0, dsfFutureArrSummary.damageAmount - futureArrTppTotal);
  if (dsfFutureArrSummary.victimNonCompensatedPart > 0)
  {
    dsfFutureArrSummary.ncpAbsorbedTpp = Math.min(dsfFutureArrSummary.victimNonCompensatedPart, pastMaxCascadableTpp);
    dsfFutureArrSummary.victimNonCompensatedPartAfterCascade = dsfFutureArrSummary.victimNonCompensatedPart - dsfFutureArrSummary.ncpAbsorbedTpp;
  }

  dsfFutureArrSummary.ncpCascadedTpp = pastNcpAbsorbedTpp;
  dsfFutureArrSummary.tppSurplusAfterNcpCascade = Math.max(0, dsfFutureArrSummary.tppSurplus - dsfFutureArrSummary.ncpCascadedTpp);
  dsfFutureArrSummary.victimAmountAfterNcpCascade = Math.min(dsfFutureArrSummary.responsiblePartyAmount, Math.max(0, dsfFutureArrSummary.damageAmount - dsfFutureArrSummary.tppTotal - dsfFutureArrSummary.ncpAbsorbedTpp + dsfFutureArrSummary.ncpCascadedTpp))
  dsfFutureArrSummary.absorbedTppSurplus = Math.min(dsfFutureArrSummary.victimAmountBeforeCascade, pastTppSurplusAfterNcpCascade);
  dsfFutureArrSummary.cascadedTppSurplus = pastAbsorbedTppSurplus;
  dsfFutureArrSummary.totalAbsorbedTpp = dsfFutureArrSummary.ncpAbsorbedTpp + dsfFutureArrSummary.absorbedTppSurplus;
  dsfFutureArrSummary.totalCascadedTpp = dsfFutureArrSummary.ncpCascadedTpp + dsfFutureArrSummary.cascadedTppSurplus;
  dsfFutureArrSummary.tppRecourse += dsfFutureArrSummary.absorbedTppSurplus;
  // dsfFutureArrSummary.tppRecourse = Math.min(dsfFutureArrSummary.tppTotal - dsfFutureArrSummary.totalCascadedTpp + dsfFutureArrSummary.totalAbsorbedTpp, Math.max(0, dsfFutureArrSummary.responsiblePartyAmount - dsfFutureArrSummary.victimAmountAfterNcpCascade));

  dsfFutureArrSummary.victimAmount = Math.round(capLineVictimAmount(values, entry, lineIndex, item, victim) * 100) / 100;

  if (dsfFutureArrSummary.tppTotal < dsfFutureArrSummary.damageAmount - dsfFutureArrSummary.responsiblePartyAmount) {
    dsfFutureArrSummary.hasNonAffected = true;
    dsfFutureArrSummary.nonAffectedTotal = dsfFutureArrSummary.damageAmount - dsfFutureArrSummary.responsiblePartyAmount - dsfFutureArrSummary.tppTotal;
  }


  return dsfFutureArrSummary;
}

export const tppTotal = tppValues => {
  let tppTotal = 0;

  if (tppValues)
    tppValues.forEach(item => {
      if (item.amount) tppTotal += parseFloat(item.amount.replace(",", ".").replace(/\s/g, ""));
    });

  return tppTotal;
};

export const lineVictimAmount = (values, entry, lineIndex, item, victim, arrerageType, arrerageIndex) => {
  let tppValues = {}

  if (arrerageType) tppValues = values[entry][lineIndex].arr[arrerageType][arrerageIndex].tpp
  else tppValues = values[entry][lineIndex].tpp
  let victimAmount = Math.min(
    lineResponsiblePartyAmount(values, entry, lineIndex, item, victim, arrerageType, arrerageIndex),
    lineAmountAfterLossOfOpportunity(values, entry, lineIndex, item, arrerageType, arrerageIndex) - tppTotal(tppValues),
  );

  return Math.round(victimAmount * 100) / 100
};


export const lineVictimDiscountedAmount = (values, entry, lineIndex, item, victim, arrerageType, arrerageIndex) => {
  let victimDiscountedAmount = Math.max(0, lineVictimAmount(values, entry, lineIndex, item, victim, arrerageType, arrerageIndex))

  if (item.itemType !== "PGPA" && values[entry][lineIndex].discounting && values[entry][lineIndex].discounting.indexValue)
    victimDiscountedAmount *= values[entry][lineIndex].discounting.indexValue;
  else if (arrerageType && values[entry][lineIndex].arr[arrerageType][arrerageIndex].lineAmount && values[entry][lineIndex].arr[arrerageType][arrerageIndex].discounting && values[entry][lineIndex].arr[arrerageType][arrerageIndex].discounting.indexValue)
    victimDiscountedAmount = parseFloat(values[entry][lineIndex].arr[arrerageType][arrerageIndex].lineAmount.replace(",", ".").replace(/\s/g, "")) * values[entry][lineIndex].arr[arrerageType][arrerageIndex].discounting.indexValue;

  victimDiscountedAmount = Math.round((victimDiscountedAmount + Number.EPSILON) * 100) / 100;

  return victimDiscountedAmount;
}


export const lineThirdPartyPayersAmount = (values, entry, lineIndex, item, victim, arrerageType, arrerageIndex) => {
  let thirdPartyPayersAmount =
    lineResponsiblePartyAmount(values, entry, lineIndex, item, victim, arrerageType, arrerageIndex) -
    Math.max(lineVictimAmount(values, entry, lineIndex, item, victim, arrerageType, arrerageIndex), 0);

  return thirdPartyPayersAmount;
};

export const subEntryResponsiblePartyTotal = (values, victim, entryName, item, indirectVictim) => {
  let responsiblePartyTotal = 0;
  if (values[entryName])
    values[entryName].forEach((line, i) => {
      let refVictim = victim;
      if (indirectVictim && line.refVictim && line.refVictim === "indirect") {
        refVictim = indirectVictim;
        refVictim.rateOfCompensationEntitlement = victim.rateOfCompensationEntitlement;
      }

      if (!item.annuityCapitalisation)
        responsiblePartyTotal += lineResponsiblePartyAmount(values, entryName, i, item, refVictim);
      else
        responsiblePartyTotal += capLineResponsiblePartyAmount(values, entryName, i, item, refVictim);
    });

  return responsiblePartyTotal;
};

export const dsfEntryResponsiblePartyTotal = (values, victim, entryName, item) => {
  let responsiblePartyTotal = 0;
  if (values[entryName])
    values[entryName].forEach((line, i) => {
      responsiblePartyTotal += getDsfPastArrSummary(values, entryName, i, item, victim).responsiblePartyAmount
        + capLineResponsiblePartyAmount(values, entryName, i, item, victim)
    });

  return responsiblePartyTotal;
};

export const subEntryVictimTotal = (values, victim, entryName, item, indirectVictim) => {
  let victimTotal = 0;
  let damageTotal = 0;
  let afterLinesTPPTotal = 0;
  if (values[entryName])
    values[entryName].forEach((line, i) => {
      let refVictim = victim;
      if (indirectVictim && line.refVictim && line.refVictim === "indirect") {
        refVictim = indirectVictim;
        refVictim.rateOfCompensationEntitlement = victim.rateOfCompensationEntitlement;
      }
      let lineAmount = 0;
      let afterLineTPPAmount = 0;
      if (item.annuityCapitalisation) {
        if (values[entryName][i]) {
          let lineDamage = lineCapitalisedAmount(values, entryName, i, item, refVictim).capitalisedAmountAfterLOP;
          if (item.nonCascadingLines)
            lineAmount = afterLineTPPAmount = Math.max(capLineVictimAmount(values, entryName, i, item, refVictim), 0);
          else {
            damageTotal += lineDamage;
            lineAmount = afterLineTPPAmount = lineDamage - tppTotal(values[entryName][i].tppCap)
          }
          if (values[entryName][i].annuity && values[entryName][i].annuityPercentage)
            lineAmount *= (1 - values[entryName][i].annuityPercentage / 100)
        }
      } else
      if (item.nonCascadingLines)
        lineAmount = Math.min(lineAmountAfterLossOfOpportunity(values, entryName, i, item) - tppTotal(values[entryName][i].tpp), lineResponsiblePartyAmount(values, entryName, i, item, victim));
      else {
        damageTotal += lineAmountAfterLossOfOpportunity(values, entryName, i, item);
        lineAmount = lineAmountAfterLossOfOpportunity(values, entryName, i, item) - tppTotal(values[entryName][i].tpp);
      }
      if (item.nonCascadingLines) victimTotal += Math.max(lineAmount, 0);
      else victimTotal += lineAmount;

      afterLinesTPPTotal += afterLineTPPAmount
    });
  if (entryName === "IP0") {
    let cascadedTotal = getCascadedTotal(values, victim, entryName);
    if (cascadedTotal < 0)
      victimTotal = Math.max(victimTotal + cascadedTotal, 0)
  }

  if (values["TPP"] && values["TPP"][entryName]) { //items with item level TPP don't have line level TPP
    victimTotal -= tppTotal(values["TPP"][entryName]);
  }

  if (!item.nonCascadingLines && entryName !== "PROV0" && entryName !== "DNAPRDI0") {
    let roceRate = (parseInt(victim.rateOfCompensationEntitlement) || 100) / 100;
    victimTotal = Math.min(victimTotal, damageTotal * roceRate)
  }

  if (!item.annuityCapitalisation)
    victimTotal = Math.min(
      victimTotal,
      subEntryResponsiblePartyTotal(values, victim, entryName, item),
    );

  return victimTotal;
};

export const dsfEntryVictimTotal = (values, victim, entryName, item) => {
  let victimTotal = 0;
  if (values[entryName])
    values[entryName].forEach((line, i) => {
      let lineAmount = 0;
      lineAmount = getDsfLineSummary(values, entryName, i, item, victim).victimAmount;
      victimTotal += Math.max(lineAmount, 0);
    });
  if (values["TPP"] && values["TPP"][entryName]) victimTotal -= tppTotal(values["TPP"][entryName]);

  return victimTotal;
};



export const dsfEntryTppRecourse = (values, victim, entryName, item) => {
  let entryTppRecourse = 0;
  if (values[entryName])
    values[entryName].forEach((line, i) => {
      let tppRecourse = 0;
      tppRecourse = getDsfLineSummary(values, entryName, i, item, victim).tppRecourse;
      entryTppRecourse += Math.max(tppRecourse, 0);
    });

  return entryTppRecourse;
};


export const itemPaidTPPTotal = (values, entryName) => {
  let paidTPPTotal = 0;
  if (values[entryName])
    values[entryName].forEach((line, i) => {
      paidTPPTotal += tppTotal(values[entryName][i].tpp) + tppTotal(values[entryName][i].tppCap);
      if (values[entryName][i].arr)
        paidTPPTotal += tppTotal(values[entryName][i].arr.past_tpp) + tppTotal(values[entryName][i].arr.annCap.tppCap);
    })
  if (values["TPP"] && values["TPP"][entryName])
    paidTPPTotal += tppTotal(values["TPP"][entryName]);

  return paidTPPTotal;
}

export const itemAfterTPPAmount = (values, entryName, item, victim, indirectVictim) => {
  let afterTPPAmount = 0;
  let afterTPPNegativeAmount = 0;
  let afterTPPPositiveAmount = 0;
  let afterEntryTPPAmount = 0;
  if (values[entryName])
    values[entryName].forEach((line, i) => {
      let refVictim = victim;
      if (indirectVictim && line.refVictim && line.refVictim === "indirect") {
        refVictim = indirectVictim;
        refVictim.rateOfCompensationEntitlement = victim.rateOfCompensationEntitlement;
      }

      let lineAmount =
        lineAmountAfterLossOfOpportunity(values, entryName, i, item) - tppTotal(values[entryName][i].tpp);
      if (item.annuityCapitalisation) lineAmount = lineCapitalisedAmount(values, entryName, i, item, victim).capitalisedAmountAfterLOP - tppTotal(values[entryName][i].tppCap)
      if (item.nonCascadingLines) {
        afterTPPNegativeAmount += Math.min(lineAmount, 0);
        afterTPPPositiveAmount += Math.max(lineAmount, 0);
      } else afterTPPAmount += lineAmount;
    });
  if (values["TPP"] && values["TPP"][entryName]) {
    afterEntryTPPAmount =
      afterTPPAmount + afterTPPPositiveAmount - tppTotal(values["TPP"][entryName]);
    if (afterTPPNegativeAmount < 0)
      afterTPPAmount = afterTPPNegativeAmount + Math.min(afterEntryTPPAmount, 0);
    else afterTPPAmount = afterEntryTPPAmount;
  }

  if (entryName === "PGPF1") {
    afterTPPAmount -= getPastTppSurplus(values, entryName, victim)
  }
  if (entryName === "IP0") {
    let cascadedTotal = getCascadedTotal(values, victim, entryName)
    if (cascadedTotal < 0)
      afterTPPAmount += cascadedTotal
  }

  return afterTPPAmount;
};

export const lineCapitalisedAmount = (values, entry, lineIndex, item, victim) => {
  let capLineSummary = {
    capitalisedAmount: 0,
    responsiblePartyAmount: 0,
    PER: 0,
    tppCapTotal: 0,
  }
  let PER = 0;
  let startAge = -1;
  let birthDate = victim.birthDate || victim.birth_date;
  let startDate = values[entry][lineIndex].startDate;
  let lineAmount = Math.round(lineAmountAfterLossOfOpportunity(values, entry, lineIndex, item) * 100) / 100;

  if (item.itemType != "PGPFProj" && values[entry][lineIndex].discounting && values[entry][lineIndex].discounting.indexValue)
    lineAmount = Math.round(lineAmount * values[entry][lineIndex].discounting.indexValue * 100) / 100


  if (startDate && startDate.length === 10) {
    if (birthDate <= startDate) {
      startAge = Math.floor(
        interval(birthDate, values[entry][lineIndex].startDate, "years", false),
      );
    }

    if (values[entry][lineIndex].capitalisationTable === "PERdirectInput") {
      if (values[entry][lineIndex].capitalisationPER)
        PER = parseFloat(values[entry][lineIndex].capitalisationPER.replace(",", ".").replace(/\s/g, ""));
    } else if (values[entry][lineIndex].capitalisationEndAge) {
      let gender = victim.victimGender;
      PER = getPERFromCapiTable(
        values[entry][lineIndex].capitalisationTable,
        gender,
        startAge,
        values[entry][lineIndex].capitalisationEndAge,
      );
    }
  }

  // if (values[entry][lineIndex] && values[entry][lineIndex].capitalisationPER)
  //   PER = parseFloat(values[entry][lineIndex].capitalisationPER.replace(",", ".").replace(/\s/g, ""));

  capLineSummary.tppTotal = tppTotal(values[entry][lineIndex].tppCap);
  capLineSummary.capitalisedAmount = capLineSummary.capitalisedAmountAfterLOP = Math.round(lineAmount * PER * 100) / 100;
  capLineSummary.responsiblePartyAmount = getResponsiblePartyAmount(capLineSummary.capitalisedAmount, victim);
  capLineSummary.PER = PER;
  return capLineSummary;
};

export const dsfLineCapitalisedAmount = (values, entry, lineIndex, item, victim) => {
  let capLineSummary = {}
  let lineAmount = getDsfFutureAnnualDamage(values, entry, lineIndex, item);
  lineAmount = Math.max(lineAmount, 0)
  let PER = 0;
  let startAge = -1;

  if (values[entry][lineIndex] && values[entry][lineIndex].arr && values[entry][lineIndex].arr.annCap) {
    let birthDate = victim.birthDate;
    let startDate = values[entry][lineIndex].arr.annCap.startDate;
    if (startDate && startDate.length === 10)
      if (birthDate <= startDate) {
        startAge = Math.floor(
          interval(birthDate, values[entry][lineIndex].arr.annCap.startDate, "years", false),
        );
      }
  }
  if (values[entry][lineIndex] && values[entry][lineIndex].arr && values[entry][lineIndex].arr.annCap) {
    if (values[entry][lineIndex].arr.annCap.capitalisationTable === "PERdirectInput") {
      if (values[entry][lineIndex].arr.annCap.capitalisationPER)
        PER = parseFloat(values[entry][lineIndex].arr.annCap.capitalisationPER.replace(",", ".").replace(/\s/g, ""));
    } else if (values[entry][lineIndex].arr.annCap.capitalisationEndAge) {
      let gender = victim.gender;
      PER = getPERFromCapiTable(
        values[entry][lineIndex].arr.annCap.capitalisationTable,
        gender,
        startAge,
        values[entry][lineIndex].arr.annCap.capitalisationEndAge,
      );
    }
  }

  capLineSummary.capitalisedAmount = Math.round(lineAmount * PER * 100) / 100;
  capLineSummary.PER = PER;

  return capLineSummary;
};


export const getDsfPastTppSurplus = (values, entry, lineIndex, item) => {
  let dsfPastTppSurplus = 0;
  let arrValues = values[entry][lineIndex].arr.past;
  let pastDamageAmount = 0;
  let pastTpp = values[entry][lineIndex].arr.past_tpp;
  let pastTppTotal = tppTotal(pastTpp);

  arrValues.forEach((arrLine, i) => {
    pastDamageAmount += getLineAmount(values, entry, lineIndex, item, "past", i)
  })

  if (pastTppTotal > pastDamageAmount)
    dsfPastTppSurplus = pastTppTotal - pastDamageAmount;

  return dsfPastTppSurplus;
}

export const capLineVictimAmount = (values, entry, lineIndex, item, victim) => {
  let damageAmount = 0;
  let victimAmount = 0;
  let tppCapTotal = 0;
  let lineValues = {};
  let pastTppSurplus = 0;
  let absorbedTppSurplus = 0;
  let hasNonAffected = false;
  let nonAffectedAmount = 0;
  let pastToNonAffected = 0;

  if (item.itemType === "DSF") {
    if (values[entry][lineIndex].arr && values[entry][lineIndex].arr.annCap)
      lineValues = values[entry][lineIndex].arr.annCap
  }
  else
    lineValues = values[entry][lineIndex]

  tppCapTotal = tppTotal(lineValues.tppCap)


  if (entry === "TPF1" || entry === "PGPF1" || entry === "PInd1") {
    damageAmount = lineCapitalisedAmount(values, entry, lineIndex, item, victim).capitalisedAmountAfterLOP;
    pastTppSurplus = getPastTppSurplus(values, entry, victim);
  }
  else if (item.itemType === "DSF") {
    damageAmount = dsfLineCapitalisedAmount(values, entry, lineIndex, item, victim).capitalisedAmount;
    pastTppSurplus = getDsfPastTppSurplus(values, entry, lineIndex, item)
  }
  if (tppCapTotal < damageAmount - getResponsiblePartyAmount(damageAmount, victim)) {
    hasNonAffected = true;
    nonAffectedAmount = damageAmount - getResponsiblePartyAmount(damageAmount, victim) - tppCapTotal;
    pastToNonAffected = Math.max(0, Math.min(nonAffectedAmount, getPastTppCascadable(values, entry, lineIndex, item, victim)))
    pastToNonAffected = Math.round(pastToNonAffected * 100) / 100;
  }

  if (damageAmount > tppCapTotal) {
    absorbedTppSurplus = Math.min(damageAmount - tppCapTotal, pastTppSurplus);
    tppCapTotal += absorbedTppSurplus;
  }

  if (item.itemType === "DSF") {
    victimAmount = Math.min(
      capLineResponsiblePartyAmount(values, entry, lineIndex, item, victim),
      dsfLineCapitalisedAmount(values, entry, lineIndex, item, victim).capitalisedAmount - tppCapTotal);
  }
  else {
    victimAmount = Math.min(
      capLineResponsiblePartyAmount(values, entry, lineIndex, item, victim),
      lineCapitalisedAmount(values, entry, lineIndex, item, victim).capitalisedAmountAfterLOP - tppCapTotal);
  }

  // if (hasNonAffected) {
  //   victimAmount += pastToNonAffected;
  // }

  return victimAmount;
}

export const getPastTppCascadable = (values, entry, lineIndex, item, victim) => {
  let pastTppCascadable = 0;
  let pastTppTotal = 0;
  let pastDamageAmount = 0;
  let pastRespPartyAmount = 0;
  if (item.itemType === "DSF") {
    pastTppTotal = tppTotal(values[entry][lineIndex].arr.past_tpp);
    values[entry][lineIndex].arr.past.forEach((arrLine, i) => {
      pastDamageAmount += getLineAmount(values, entry, lineIndex, item, "past", i)
    })
  }
  else if (entry === "PGPF1" || entry === "TPF1" || entry === "PInd1") {
    let pastEntry = "";
    let pastItem = {};
    if (entry === "PGPF1") {
      pastEntry = "PGPF0";
      pastItem = {
        itemType: "PGPA",
        lossOfOpportunity: true,
        computedAmount: true,
        multiLine: true,
      };
    }
    else if (entry === "TPF1") {
      pastEntry = "TPF0";
      pastItem = {
        itemType: "TPT",
        computedAmount: true,
        multiLine: true,
      };
    }
    else if (entry === "PInd1") {
      pastEntry = "PInd0";
      pastItem = {
        itemType: "TPT",
        computedAmount: true,
        multiLine: true,
      };
    }
    if (pastEntry)
      values[pastEntry].forEach((pastLine, i) => {
        pastDamageAmount += getLineAmount(values, pastEntry, i, pastItem);
        pastTppTotal += tppTotal(pastLine.tpp);
      })
    if (values.TPP && values.TPP[pastEntry])
      pastTppTotal += tppTotal(values.TPP[pastEntry])
  }
  pastRespPartyAmount = getResponsiblePartyAmount(pastDamageAmount, victim);
  pastTppCascadable = pastTppTotal - (pastDamageAmount - pastRespPartyAmount)

  return pastTppCascadable;
}

export const capLineResponsiblePartyAmount = (values, entry, lineIndex, item, victim) => {
  let responsiblePartyAmount = 0;
  let lineAmount = 0;
  if (item.itemType === "DSF")
    lineAmount = dsfLineCapitalisedAmount(values, entry, lineIndex, item, victim).capitalisedAmount;
  else
    lineAmount = lineCapitalisedAmount(values, entry, lineIndex, item, victim).capitalisedAmountAfterLOP;

  responsiblePartyAmount = getResponsiblePartyAmount(lineAmount, victim);

  return responsiblePartyAmount;
}

export const capLineTPPRecourse = (values, entry, lineIndex, item, victim) => {
  let tppRecourse = 0;

  tppRecourse = capLineResponsiblePartyAmount(values, entry, lineIndex, item, victim) -
    Math.max(capLineVictimAmount(values, entry, lineIndex, item, victim), 0);

  return Math.max(0, tppRecourse);
}


export const subEntryTotal = (values, victim, entryName, item, indirectVictim) => {
  let pastFutureSummary;
  let refVictim = victim;
  if (entryName.indexOf("PGPF") === 0 || entryName.indexOf("TPF") === 0 || entryName.indexOf("PInd") === 0) {
    let pastDamage = 0;
    let futureDamage = 0;
    let pastTpp = 0;
    let futureTpp = 0;
    let compensationRate = victim.rateOfCompensationEntitlement || 100;

    if (entryName.indexOf("PGPF") === 0 || entryName.indexOf("TPF") === 0 || entryName.indexOf("PInd") === 0) {
      if (entryName.indexOf("PGPF") === 0) {
        if (values.TPP && values.TPP.PGPF0)
          pastTpp = tppTotal(values.TPP.PGPF0)
        if (values.PGPF1 && values.PGPF1.length > 0 && values.PGPF1[0] && values.PGPF1[0].tppCap)
          futureTpp = tppTotal(values.PGPF1[0].tppCap)
        let pastItem = {
          itemType: "PGPA",
          lossOfOpportunity: true,
          computedAmount: true,
          multiLine: true,
        };
        let futureItem = {
          itemType: "PGPFProj",
          annuityCapitalisation: true,
          computedAmount: true,
        };
        pastDamage = getItemDamageTotal(values, 'PGPF0', pastItem, victim)
        futureDamage = getItemDamageTotal(values, 'PGPF1', futureItem, victim)
      } else if (entryName.indexOf("PInd") === 0) {
        if (values.TPP && values.TPP.PInd0)
          pastTpp = tppTotal(values.TPP.PInd0)
        if (values.PInd1 && values.PInd1.length > 0 && values.PInd1[0])
        {
          if (values.PInd1[0].tppCap) futureTpp = tppTotal(values.PInd1[0].tppCap)
          if (values.PInd1[0].refVictim === "indirect") refVictim = indirectVictim;
        }
        let pastItem = {
          itemType: "TPT",
          multiLine: true,
          computedAmount: true,
        };
        let futureItem = {
          itemType: "TPF",
          disableEntryTPP: true,
          annuityCapitalisation: true,
          nonCascadingLines: true,
          computedAmount: true,
        };
        pastDamage = getItemDamageTotal(values, 'PInd0', pastItem, victim)
        futureDamage = getItemDamageTotal(values, 'PInd1', futureItem, refVictim)
      }
      else {
        if (values.TPP && values.TPP.TPF0)
          pastTpp = tppTotal(values.TPP.TPF0)
        if (values.TPF1 && values.TPF1.length > 0 && values.TPF1[0] && values.TPF1[0].tppCap)
          futureTpp = tppTotal(values.TPF1[0].tppCap)
        let pastItem = {
          itemType: "TPT",
          multiLine: true,
          computedAmount: true,
        };
        let futureItem = {
          itemType: "TPF",
          disableEntryTPP: true,
          annuityCapitalisation: true,
          nonCascadingLines: true,
          computedAmount: true,
        };
        pastDamage = getItemDamageTotal(values, 'TPF0', pastItem, victim)
        futureDamage = getItemDamageTotal(values, 'TPF1', futureItem, victim)
      }
      pastFutureSummary = getPastFutureSummary(pastDamage, futureDamage, pastTpp, futureTpp, compensationRate)

    }
  }
  let victimTotal = 0;
  if (item.itemType === "DSF")
    victimTotal = dsfEntryVictimTotal(values, victim, entryName, item);
  else
    victimTotal = subEntryVictimTotal(values, victim, entryName, item, indirectVictim);
  let damageTotal = 0;
  let annuityCapTotal = 0;
  let annuityTotal = 0;
  let annuityDetails = {};
  let hasTPP = false;
  let hasNonAffected = false;
  let paidTPPTotal = itemPaidTPPTotal(values, entryName);
  let responsiblePartyTotal = 0
  if (item.itemType === "DSF")
    responsiblePartyTotal = dsfEntryResponsiblePartyTotal(values, victim, entryName, item)
  else
    responsiblePartyTotal = subEntryResponsiblePartyTotal(values, victim, entryName, item, indirectVictim);
  let compensationRate = (parseInt(victim.rateOfCompensationEntitlement || 100)) / 100;
  damageTotal = (responsiblePartyTotal / compensationRate) || 0;

  let thirdPartyPayersTotal = 0;
  let futureTppSurplus = 0;
  let absorbedFutureTppSurplus = 0;
  let nbLines = 0;

  let afterTPPTotal = itemAfterTPPAmount(values, entryName, item, victim, indirectVictim);
  if (values["TPP"] && values["TPP"][entryName] && values["TPP"][entryName].length > 0)
    hasTPP = true;
  else if (values[entryName])
    values[entryName].forEach(line => {
      if ((line.tpp && line.tpp.length > 0)
        || (line.tppCap && line.tppCap.length > 0)
        || (line.arr && line.arr.annCap && line.arr.annCap.tppCap && line.arr.annCap.tppCap.length > 0)
        || (line.arr && line.arr.past_tpp && line.arr.past_tpp.length > 0))
        hasTPP = true;
    });

  if (values && victim && entryName && item) {
    if (values[entryName]) {
      nbLines = values[entryName].length;
      values[entryName].forEach((line, i) => {
        refVictim = victim;
        if (indirectVictim && line.refVictim && line.refVictim === "indirect") {
          refVictim = indirectVictim;
          refVictim.rateOfCompensationEntitlement = victim.rateOfCompensationEntitlement;
        }

        if (item.annuityCapitalisation || item.itemType === "DSF") {
          if (values[entryName][i]) {
            if (values[entryName][i].annuity || (values[entryName][i].arr && values[entryName][i].arr.annCap && values[entryName][i].arr.annCap.annuity)) {
              let annCapValues = {}
              if (values[entryName][i].annuity) annCapValues = values[entryName][i];
              else annCapValues = values[entryName][i].arr.annCap;

              let annuityPercentage = parseFloat(annCapValues.annuityPercentage.toString().replace(",", ".")) / 100 || 0;
              let victimAmount = Math.max(capLineVictimAmount(values, entryName, i, item, refVictim), 0);
              if (victimAmount) {
                let PER = 1;
                if (item.itemType === "DSF")
                  PER = dsfLineCapitalisedAmount(values, entryName, i, item, refVictim).PER;
                else PER = lineCapitalisedAmount(values, entryName, i, item, refVictim).PER;

                let annuityCapAmount = victimAmount * annuityPercentage;
                console.log
                let annuityAmount = annuityCapAmount / PER;
                annuityCapTotal += annuityCapAmount;
                annuityTotal += annuityAmount;
                if (annCapValues.annuityPeriodicity) {
                  let periodicity = annCapValues.annuityPeriodicity;
                  if (!annuityDetails[periodicity])
                    annuityDetails[periodicity] = (annuityAmount * parseInt(periodicity)) / 12;
                  else annuityDetails[periodicity] += (annuityAmount * parseInt(periodicity)) / 12;
                }
              }
            }
          }
        }
      });
    }
  }

  if (entryName.indexOf("PGPF") === 0 || entryName.indexOf("TPF") === 0 || entryName.indexOf("PInd") === 0) {
    futureTppSurplus = getFutureTppSurplus(values, entryName, refVictim);
    let nonAffectedTotal = 0;
    if (paidTPPTotal < damageTotal - responsiblePartyTotal) {
      hasNonAffected = true;
      nonAffectedTotal = damageTotal - responsiblePartyTotal - paidTPPTotal;
    }
    if (futureTppSurplus > 0)
      if (futureTppSurplus > nonAffectedTotal) {
        hasNonAffected = false;
        absorbedFutureTppSurplus = Math.min(futureTppSurplus, Math.max(0, damageTotal - paidTPPTotal));
      }
  }
  thirdPartyPayersTotal = responsiblePartyTotal - Math.max(victimTotal + annuityCapTotal, 0);
  if (item.itemType === "DSF")
    thirdPartyPayersTotal = dsfEntryTppRecourse(values, victim, entryName, item);

  if (entryName !== "IP0" && entryName !== "DFP0")
    thirdPartyPayersTotal = Math.min(thirdPartyPayersTotal, paidTPPTotal);

  if (pastFutureSummary) {
    let summary = {}
    if (entryName.indexOf("PGPF0") === 0 || entryName.indexOf("TPF0") === 0 || entryName.indexOf("PInd0") === 0)
      summary = pastFutureSummary.past
    else if (entryName.indexOf("PGPF1") === 0 || entryName.indexOf("TPF1") === 0 || entryName.indexOf("PInd1") === 0)
      summary = pastFutureSummary.future
    if (summary) {
      thirdPartyPayersTotal = summary.tpp_recourse
      responsiblePartyTotal = summary.resp_party
      damageTotal = summary.damage
      victimTotal = summary.victim_amount
      if (summary.pref_right_cascaded > 0.01 || summary.pref_right_absorbed > 0.01)
        hasNonAffected = true
      else
        hasNonAffected = false
      if (summary.pref_right_absorbed || summary.absorbed_tpp_surplus)
        hasTPP = true
    }
  }

  if ((item.itemType === "DSA" || entryName === "AUT10" || entryName === "AUT20" || entryName === "DNAPRDI0" || entryName === "PROV0") && values[entryName] && values[entryName].length > 0)
  {
    victimTotal = 0;
    values[entryName].forEach((line, i) => {
      victimTotal += lineVictimDiscountedAmount(values, entryName, i, item, victim);
    });
  }


  victimTotal = Math.round(victimTotal * 100) / 100;
  annuityTotal = Math.round(annuityTotal * 100) / 100;
  annuityCapTotal = Math.round(annuityCapTotal * 100) / 100;
  thirdPartyPayersTotal = Math.round(thirdPartyPayersTotal * 100) / 100;
  responsiblePartyTotal = Math.round(responsiblePartyTotal * 100) / 100;
  afterTPPTotal = Math.round(afterTPPTotal * 100) / 100;
  futureTppSurplus = Math.round(futureTppSurplus * 100) / 100;
  absorbedFutureTppSurplus = Math.round(absorbedFutureTppSurplus * 100) / 100;

  damageTotal = round2Decimal(damageTotal)
  paidTPPTotal = round2Decimal(paidTPPTotal)

  return {
    hasTPP,
    nbLines,
    damageTotal,
    victimTotal,
    annuityTotal,
    paidTPPTotal,
    afterTPPTotal,
    annuityDetails,
    hasNonAffected,
    annuityCapTotal,
    futureTppSurplus,
    responsiblePartyTotal,
    thirdPartyPayersTotal,
    absorbedFutureTppSurplus,
    pastFutureSummary,
  };
};

export const getCascadedTotal = (values, victim, entryName) => {
  let cascadedTotal = 0;
  let PGPF0EntryTotal = subEntryTotal(values, victim, "PGPF0", {
    itemType: "PGPA",
    lossOfOpportunity: true,
    computedAmount: true,
    multiLine: true,
  }).victimTotal;
  if (!victim.disableCascade) {
    switch (entryName) {
      case "PGPF1":
        cascadedTotal = PGPF0EntryTotal;
        break;
      case "IP0":
        if (values["_meta"] && values["_meta"]["PGPF"] && values["_meta"]["PGPF"]["items"]) {
          if (values["_meta"]["PGPF"]["items"][1] && values["_meta"]["PGPF"]["items"][1]["pastFutureSummary"] && values["_meta"]["PGPF"]["items"][1]["pastFutureSummary"]["entry"])
            cascadedTotal -= values["_meta"]["PGPF"]["items"][1]["pastFutureSummary"]["entry"].tpp_surplus || 0
          else if (values["_meta"]["PGPF"]["items"][0]&& values["_meta"]["PGPF"]["items"][0]["pastFutureSummary"] && values["_meta"]["PGPF"]["items"][0]["pastFutureSummary"]["entry"])
            cascadedTotal -= values["_meta"]["PGPF"]["items"][0]["pastFutureSummary"]["entry"].tpp_surplus || 0
        }
        break;
      // case "DFP0":
      //   if (values["_meta"] && values["_meta"]["IP"] && values["_meta"]["IP"]["items"] && values["_meta"]["IP"]["items"][0]) {
      //     cascadedTotal = values["_meta"]["IP"]["items"][0].afterTPPTotal || 0
      //   } else if (values["_meta"] && values["_meta"]["PGPF"] && values["_meta"]["PGPF"]["items"]) {
      //     if (values["_meta"]["PGPF"]["items"][1])
      //       cascadedTotal += values["_meta"]["PGPF"]["items"][1].afterTPPTotal || 0
      //     if (values["_meta"]["PGPF"]["items"][0])
      //       cascadedTotal += values["_meta"]["PGPF"]["items"][0].afterTPPTotal || 0
      //   }
      //   break;
      default:
        cascadedTotal = 0;
    }
  }
  else if (entryName === "PGPF1")
    cascadedTotal = PGPF0EntryTotal;

  return cascadedTotal;
};


export const getDsfPastArrSummary = (values, entry, lineIndex, item, victim) => {
  let dsfPastArrSummary = {
    hasTPP: false,
    damageAmount: 0,
    victimAmountBeforeDiscounting: 0,
    victimAmount: 0,
    victimDiscountedAmount:0,
    responsiblePartyAmount: 0,
    responsiblePartyAmountAfterDiscounting: 0,
    tppRecourse: 0,
    tppTotal: 0,
    futureTppSurplus: 0,
    absorbedFutureTppSurplus: 0,
    hasNonAffected: false,
    nonAffectedTotal: 0,
  }
  let arrValues = values[entry][lineIndex].arr.past;
  let pastTpp = values[entry][lineIndex].arr.past_tpp;
  let pastArrTppTotal = 0;

  arrValues.forEach((arrLine, i) => {
    dsfPastArrSummary.damageAmount += getLineAmount(values, entry, lineIndex, item, "past", i)
  })
  dsfPastArrSummary.responsiblePartyAmount = dsfPastArrSummary.victimAmount = Math.round(getResponsiblePartyAmount(dsfPastArrSummary.damageAmount, victim) * 100) / 100;

  if (pastTpp && pastTpp.length > 0) {
    dsfPastArrSummary.hasTPP = true;
    pastArrTppTotal = tppTotal(pastTpp);
    if (pastArrTppTotal > 0) {
      dsfPastArrSummary.victimAmount = dsfPastArrSummary.victimAmountBeforeDiscounting = Math.min(Math.max(0, dsfPastArrSummary.damageAmount - pastArrTppTotal), dsfPastArrSummary.responsiblePartyAmount);
      dsfPastArrSummary.tppTotal = pastArrTppTotal;
      dsfPastArrSummary.tppRecourse = dsfPastArrSummary.responsiblePartyAmount - Math.max(dsfPastArrSummary.victimAmount, 0);
    }
  }
  if (dsfPastArrSummary.tppTotal < dsfPastArrSummary.damageAmount - dsfPastArrSummary.responsiblePartyAmount) {
    dsfPastArrSummary.hasNonAffected = true;
    dsfPastArrSummary.nonAffectedTotal = dsfPastArrSummary.damageAmount - dsfPastArrSummary.responsiblePartyAmount - dsfPastArrSummary.tppTotal;
  }

  if (values[entry][lineIndex].arr.past && values[entry][lineIndex].arr.past[0] && values[entry][lineIndex].arr.past[0].discounting && values[entry][lineIndex].arr.past[0].discounting.indexValue){
    dsfPastArrSummary.victimAmount = dsfPastArrSummary.victimDiscountedAmount = Math.round(dsfPastArrSummary.victimAmount * values[entry][lineIndex].arr.past[0].discounting.indexValue * 100) / 100;
    dsfPastArrSummary.responsiblePartyAmountAfterDiscounting = dsfPastArrSummary.victimDiscountedAmount + dsfPastArrSummary.tppRecourse;
  }


  if (dsfPastArrSummary.victimAmount > 0) {
    let futureVictimAmount = capLineVictimAmount(values, entry, lineIndex, item, victim);
    if (futureVictimAmount < 0) {
      dsfPastArrSummary.futureTppSurplus = -futureVictimAmount;
      if (dsfPastArrSummary.futureTppSurplus > dsfPastArrSummary.nonAffectedTotal)
        dsfPastArrSummary.absorbedFutureTppSurplus = Math.min(dsfPastArrSummary.futureTppSurplus, Math.max(0, dsfPastArrSummary.damageAmount - dsfPastArrSummary.tppTotal))
    }
  }

  return dsfPastArrSummary;
}


export const getMaxCascadableTpp = (values, entry, lineIndex, item, victim, type) => {
  let tpp;
  let damage = 0;
  let respPartyAmount = 0;
  if (type === "past") {
    tpp = values[entry][lineIndex].arr.past_tpp;
    values[entry][lineIndex].arr.past.forEach((arrLine, i) => {
      let lineAmount = getLineAmount(values, entry, lineIndex, item, "past", i)
      damage += lineAmount;
    })
  }
  else {
    tpp = values[entry][lineIndex].arr.annCap.tppCap;
    damage = dsfLineCapitalisedAmount(values, entry, lineIndex, item, victim).capitalisedAmount;
  }

  let maxCascadableTpp = tppTotal(tpp);
  if (maxCascadableTpp > 0) {
    respPartyAmount = getResponsiblePartyAmount(damage, victim);
    maxCascadableTpp = Math.max(0, maxCascadableTpp - (damage - respPartyAmount));
  }

  return maxCascadableTpp;
}


export const getNcpAbsorbedTpp = (values, entry, lineIndex, item, victim, type) => {
  let ncpAbsorbedTpp = 0;
  let otherTpp, selfTpp;
  let damage = 0;
  let otherDamage = 0;

  if (type === "past") {
    selfTpp = values[entry][lineIndex].arr.past_tpp;
    otherTpp = values[entry][lineIndex].arr.annCap.tppCap;
    values[entry][lineIndex].arr.past.forEach((arrLine, i) => {
      let lineAmount = getLineAmount(values, entry, lineIndex, item, "past", i)
      damage += lineAmount;
    })
    otherDamage = dsfLineCapitalisedAmount(values, entry, lineIndex, item, victim).capitalisedAmount;
  }
  else {
    selfTpp = values[entry][lineIndex].arr.annCap.tppCap;
    otherTpp = values[entry][lineIndex].arr.past_tpp;
    damage = dsfLineCapitalisedAmount(values, entry, lineIndex, item, victim).capitalisedAmount;
    values[entry][lineIndex].arr.past.forEach((arrLine, i) => {
      let lineAmount = getLineAmount(values, entry, lineIndex, item, "past", i)
      otherDamage += lineAmount;
    })
  }
  const respPartyAmount = getResponsiblePartyAmount(damage, victim);
  const otherRespPartyAmount = getResponsiblePartyAmount(otherDamage, victim);
  const selfTppTotal = tppTotal(selfTpp);
  const otherTppTotal = tppTotal(otherTpp);

  const ncp = Math.max(0, damage - respPartyAmount - selfTppTotal);

  if (ncp > 0)
    ncpAbsorbedTpp = Math.min(ncp, Math.max(0, otherTppTotal - (otherDamage - otherRespPartyAmount)))

  return ncpAbsorbedTpp;
}


export const getTppSurplusAfterNcpCascade = (values, entry, lineIndex, item, victim, type) => {
  let tppSurplusAfterNcpCascade = 0;
  let cascadedTppSurplus = 0;
  let damage = 0;
  let tpp;

  if (type === "past") {
    tpp = values[entry][lineIndex].arr.past_tpp;
    values[entry][lineIndex].arr.past.forEach((arrLine, i) => {
      let lineAmount = getLineAmount(values, entry, lineIndex, item, "past", i)
      damage += lineAmount;
    });
    cascadedTppSurplus = getNcpAbsorbedTpp(values, entry, lineIndex, item, victim, "future");
  }
  else {
    tpp = values[entry][lineIndex].arr.annCap.tppCap;
    damage = dsfLineCapitalisedAmount(values, entry, lineIndex, item, victim).capitalisedAmount;
    cascadedTppSurplus = getNcpAbsorbedTpp(values, entry, lineIndex, item, victim, "past");
  }

  const selfTppTotal = tppTotal(tpp);

  if (selfTppTotal > damage){
    tppSurplusAfterNcpCascade = Math.max(0, selfTppTotal - damage - cascadedTppSurplus);}

  return tppSurplusAfterNcpCascade;
}


export const getAbsorbedTppSurplus = (values, entry, lineIndex, item, victim, type) => {
  let absorbedTppSurplus = 0;
  let damage = 0;
  let tpp;
  let otherTppSurplusAfterNcpCascade = 0;

  if (type === "past") {
    tpp = values[entry][lineIndex].arr.past_tpp;
    values[entry][lineIndex].arr.past.forEach((arrLine, i) => {
      let lineAmount = getLineAmount(values, entry, lineIndex, item, "past", i)
      damage += lineAmount;
    });
    otherTppSurplusAfterNcpCascade = getTppSurplusAfterNcpCascade(values, entry, lineIndex, item, victim, "future");
  }
  else {
    tpp = values[entry][lineIndex].arr.annCap.tppCap;
    damage = dsfLineCapitalisedAmount(values, entry, lineIndex, item, victim).capitalisedAmount;
    otherTppSurplusAfterNcpCascade = getTppSurplusAfterNcpCascade(values, entry, lineIndex, item, victim, "past");
  }

  const selfTppTotal = tppTotal(tpp);
  const respPartyAmount = getResponsiblePartyAmount(damage, victim);
  const victimAmountBeforeCascade = Math.min(respPartyAmount, Math.max(0, damage - selfTppTotal));
  absorbedTppSurplus = Math.min(victimAmountBeforeCascade, otherTppSurplusAfterNcpCascade)

  return absorbedTppSurplus;
}


export const getLineSummary = (values, entry, lineIndex, item, victim) => {
  let lineSummary = {
    baseAmount: 0,
    damageAmount: 0,
    respPartyAmount: 0,
    tppTotal: 0,
    hasTpp: false,
    victimAmount: 0,
    tppRecourse: 0,
    hasNonAffected: false,
    nonAffectedTotal: 0,
    tppSurplus: 0,
    absorbedTppSurplus: 0,
  };

  lineSummary.baseAmount = getLineAmount(values, entry, lineIndex, item);
  lineSummary.damageAmount = lineAmountAfterLossOfOpportunity(values, entry, lineIndex, item);
  lineSummary.respPartyAmount = getResponsiblePartyAmount(lineSummary.damageAmount, victim);
  lineSummary.victimAmount = lineVictimDiscountedAmount(values, entry, lineIndex, item, victim);
  if (item.itemType === "DSF")
    lineSummary.tppTotal = getDsfLineTppTotal(values, entry, lineIndex, item);
  else
    lineSummary.tppTotal = tppTotal(values[entry][lineIndex].tpp);

  return lineSummary;
}

export const getDsfLineTppTotal = (values, entry, lineIndex, item) => {
  let tppTotal = tppTotal(values[entry][lineIndex].arr.annCap.tppCap) + tppTotal(values[entry][lineIndex].arr.past_tpp);

  return tppTotal
}


export const getDsfLineSummary = (values, entry, lineIndex, item, victim) => {
  let dsfLineSummary = {
    damageAmount: 0,//
    victimAmount: 0,//
    tppRecourse: 0,//
    annuityAmount: 0,//
    tppSurplus: 0,//
    hasPreferenceRight: false,
  }

  let pastSummary = {};
  if (values[entry][lineIndex].arr["past"] && values[entry][lineIndex].arr["past"].length > 0) {
    pastSummary = getDsfPastArrSummary(values, entry, lineIndex, item, victim);
    dsfLineSummary.damageAmount = pastSummary.damageAmount;
    dsfLineSummary.victimAmount = pastSummary.victimAmount;
    dsfLineSummary.tppRecourse = pastSummary.tppRecourse;
  }

  let futureSummary = {};
  if (values[entry][lineIndex].arr["future"] && values[entry][lineIndex].arr["future"].length > 0 && values[entry][lineIndex].arr.annCap.capitalisationPER) {
    futureSummary = getDsfFutureArrSummary(values, entry, lineIndex, item, victim);
    dsfLineSummary.damageAmount += futureSummary.damageAmount;
    dsfLineSummary.victimAmount += futureSummary.victimAmount;

    dsfLineSummary.tppRecourse += futureSummary.tppRecourse;
    let annuityPercentage = 0;
    let capitalisedAmount = Math.max(0, capLineVictimAmount(values, entry, lineIndex, item, victim));
    if (values[entry][lineIndex].arr.annCap.annuity && values[entry][lineIndex].arr.annCap.annuityPercentage) {
      annuityPercentage = values[entry][lineIndex].arr.annCap.annuityPercentage;
      dsfLineSummary.annuityAmount = Math.max(0, capitalisedAmount * annuityPercentage / 100 / parseFloat(values[entry][lineIndex].arr.annCap.capitalisationPER.replace(",", ".")));
    }
    dsfLineSummary.victimAmount -= capitalisedAmount * annuityPercentage / 100;
  }

  return dsfLineSummary;
}

// calculates and returns the totals (cumulated capital and annuities for a given victim)
// also returns the cascading total for entries subject to cascading

// TODO: le paramètre victim peut être supprimé, et possiblement entries
export const victimSummary = (entries, values, victim) => {
  let total = 0;
  let annuityTotal = 0;
  const victimId = values.currentVictim;
  let victimValues = values;

  if (victimId && values.indirectVictims && values.indirectVictims.length <= victimId && values.indirectVictims[victimId-1])
    victimValues = values.indirectVictims[victimId-1].caseData;

  let cascadeTotal = { total: 0, beforeAnnuityTotal: 0, annuityTotal: 0 };
  if (victimValues && victimValues._meta) {
    Object.entries(victimValues._meta).map(entry => {
      if (entry[1].items) {
        let entrySummary = getEntrySummary(entry[1].items, entry[0]);

        if (entry[0] === "PGPF" || entry[0] === "IP")
          entrySummary = getCascadingEntrySummary(entry[1].items, entry[0]);

        if (entry[0] !== "PROV") {
          total += Math.max(entrySummary.total, 0);
          annuityTotal += entrySummary.annuityTotal;
        }
        else
          total -= entrySummary.total;
      }
    })
  }

  total = round2Decimal(total)
  annuityTotal = round2Decimal(annuityTotal)
  cascadeTotal = round2Decimal(cascadeTotal)

  return {
    total,
    annuityTotal,
    cascadeTotal,
  };
};

export const getCascadingEntrySummary = (entryItems, entryId) => {

  let entrySummary = { damageTotal: 0, paidTPPTotal: 0, total: 0, annuityTotal: 0, annuityCapTotal: 0, annuityDetails: {}, respPartyTotal: 0, cumulatedTppRecourse: 0, tppRecourse: 0, hasTpp: false, cascade: 0 }
  entryItems.map((item, i) => {
    entrySummary.total += item.victimTotal;
    entrySummary.tppRecourse += item.thirdPartyPayersTotal;
    entrySummary.damageTotal += item.damageTotal;
    entrySummary.annuityTotal += item.annuityTotal;
    entrySummary.cumulatedTppRecourse += Math.max(0, item.thirdPartyPayersTotal);
    entrySummary.respPartyTotal += item.responsiblePartyTotal;
    entrySummary.paidTPPTotal += item.paidTPPTotal
    entrySummary.annuityCapTotal += item.annuityCapTotal || 0

    if (item.annuityDetails) entrySummary.annuityDetails = item.annuityDetails;
    if (!entrySummary.hasTPP) entrySummary.hasTPP = item.hasTPP;

  });

  if (entryId === "PGPF") {
    entrySummary.total = Math.max(Math.min(entrySummary.damageTotal - entrySummary.paidTPPTotal, entrySummary.respPartyTotal), 0)
    entrySummary.total -= entrySummary.annuityCapTotal;
    entrySummary.total = entrySummary.total.toFixed(2)
    entrySummary.tppRecourse = entrySummary.respPartyTotal - entrySummary.total
  }

  if (entrySummary.paidTPPTotal > entrySummary.damageTotal)
    entrySummary.cascade = entrySummary.damageTotal - entrySummary.paidTPPTotal;

  return entrySummary;
};



export const getEntrySummary = (entryItems, entryId) => {
  let entrySummary = { damageTotal: 0, paidTPPTotal: 0, total: 0, annuityTotal: 0, annuityCapTotal: 0, annuityDetails: {}, respPartyTotal: 0, cumulatedTppRecourse: 0, tppRecourse: 0, hasTpp: false, cascade: 0 }
  entryItems.map((item, i) => {
    entrySummary.damageTotal += item.damageTotal;
    entrySummary.annuityTotal += item.annuityTotal;
    entrySummary.cumulatedTppRecourse += Math.max(0, item.thirdPartyPayersTotal);
    entrySummary.respPartyTotal += item.responsiblePartyTotal;
    entrySummary.paidTPPTotal += item.paidTPPTotal
    entrySummary.annuityCapTotal += item.annuityCapTotal || 0
    entrySummary.total += item.victimTotal
    if (entryId !== "DSF" && entryId !== "FLA" && entryId !== "FVA"  && entryId !== "AUT2Cap" )
      entrySummary.total -= item.annuityCapTotal
    entrySummary.tppRecourse += item.thirdPartyPayersTotal

    if (item.annuityDetails) entrySummary.annuityDetails = item.annuityDetails;
    if (!entrySummary.hasTPP) entrySummary.hasTPP = item.hasTPP;

  });

  if (entrySummary.paidTPPTotal > entrySummary.damageTotal)
    entrySummary.cascade = entrySummary.damageTotal - entrySummary.paidTPPTotal;

  return entrySummary;
};



export const getItemTppSurplus = (values, entry, itemIndex = 0) => {
  let itemTppSurplus = 0;

  if (values._meta[entry] && values._meta[entry].items) {
    let item = values._meta[entry].items[itemIndex];
    if (item && item.paidTPPTotal > item.damageTotal)
      itemTppSurplus = item.paidTPPTotal - item.damageTotal;
  }

  return itemTppSurplus
}


export const getFutureTppSurplus = (values, entry, refVictim) => {
  const entryName = entry.slice(0, -1);
  const futureEntryName = entryName+"1";
  let futureTppSurplus = getItemTppSurplus(values, entryName, 1);

  //the following condition is a workaround for a bug on PInd future tpp surplus calculations. It should be removed after the bug is fixed
  if (entryName === "PInd" && values && values[futureEntryName] && values[futureEntryName][0]) {
    futureTppSurplus = 0
    const item = {
      itemType: "TPF",
      disableEntryTPP: true,
      annuityCapitalisation: true,
      nonCascadingLines: true,
      computedAmount: true,
    };
    const capLineSummary = lineCapitalisedAmount(values, futureEntryName, 0, item, refVictim);
    if (capLineSummary.tppTotal > capLineSummary.capitalisedAmount) futureTppSurplus = capLineSummary.tppTotal - capLineSummary.capitalisedAmount;
  }

  return futureTppSurplus
}


export const getPastTppSurplus = (values, entry) => {
  const entryName = entry.slice(0, -1)
  let pastTppSurplus = 0;
  if (entry.indexOf("PGPF") === 0 || entry.indexOf("TPF") === 0 || entry.indexOf("PInd") === 0)
    pastTppSurplus = getItemTppSurplus(values, entryName, 0);

  return pastTppSurplus
}


export const getItemDamageTotal = (values, entryName, item, victim) => {
  let itemDamageTotal = 0;

  if (values[entryName])
    values[entryName].forEach((line, i) => {
      if (item.itemType === "DSF")
        itemDamageTotal += getDsfLineSummary(values, entryName, i, item, victim).damageAmount;
      else
      if (item.annuityCapitalisation)
        itemDamageTotal += lineCapitalisedAmount(values, entryName, i, item, victim).capitalisedAmountAfterLOP;
      else
        itemDamageTotal += lineAmountAfterLossOfOpportunity(values, entryName, i, item);
    })

  return itemDamageTotal;
}


export const getFuturePGPFSummary = (values, victim) => {
  let futurePGPFLines = [];
  const itemPGPF1 = { itemType: "PGPFProj", computedAmount: true, annuityCapitalisation: true }
  let pastPGPFTpp = getPastTppSurplus(values, "PGPF1", victim)
  let summary = {
    damageTotal: 0, tppTotal: pastPGPFTpp,
    nonCompensatedDamageTotal: 0,
    nonAffectedTotal: 0,
    tppSurplusTotal: pastPGPFTpp,
  }
  values["PGPF1"].forEach((line, i) => {
    line.nonCompensatedDamage = line.nonAffectedPart = line.tppSurplus = line.absorbedTppSurplus = line.absorbedNonAffectedPart = line.spreadNonAffectedPart = 0
    let lineSummary = lineCapitalisedAmount(values, "PGPF1", i, itemPGPF1, victim)
    line.damageAmount = lineSummary.capitalisedAmountAfterLOP
    summary.damageTotal += line.damageAmount
    line.tppTotal = lineSummary.tppTotal
    summary.tppTotal += line.tppTotal
    if (parseInt(victim.rateOfCompensationEntitlement) < 100) {
      let roceRate = parseInt(victim.rateOfCompensationEntitlement) / 100
      let outOfRespParty = lineSummary.capitalisedAmountAfterLOP * roceRate;
      if (outOfRespParty > lineSummary.tppTotal) {
        line.nonCompensatedDamage = outOfRespParty - lineSummary.tppTotal;
        summary.nonCompensatedDamageTotal += line.nonCompensatedDamage
      }
      else {
        line.nonAffectedPart = Math.min(lineSummary.tppTotal, lineSummary.capitalisedAmountAfterLOP) - outOfRespParty;
        summary.nonAffectedTotal += line.nonAffectedPart
      }
    }
    if (lineSummary.capitalisedAmountAfterLOP < lineSummary.tppTotal) {
      line.tppSurplus = lineSummary.tppTotal - lineSummary.capitalisedAmountAfterLOP;
      summary.tppSurplusTotal += line.tppSurplus;
    }
    futurePGPFLines.push(line)
  })

  if (summary.tppSurplusTotal > 0) {
    let absorbedTppSurplusTotal = 0;
    if (summary.nonCompensatedDamageTotal > 0)
      futurePGPFLines.forEach((line, i) => {
        if (line.nonCompensatedDamage > 0 && summary.tppSurplusTotal > absorbedTppSurplusTotal) {
          line.absorbedTppSurplus = Math.min(line.nonCompensatedDamage, summary.tppSurplusTotal - absorbedTppSurplusTotal);
          summary.tppSurplusTotal -= line.absorbedTppSurplus;
          line.nonCompensatedDamage -= line.absorbedTppSurplus;
          absorbedTppSurplusTotal += line.absorbedTppSurplus;
          line.tppTotal += line.absorbedTppSurplus;
        }
      })
    if (absorbedTppSurplusTotal < summary.tppSurplusTotal) {
      futurePGPFLines.forEach((line, i) => {
        if (line.tppTotal < line.damageAmount && summary.tppSurplusTotal > absorbedTppSurplusTotal) {
          let additionalAbsorbedSurplus = Math.min(line.damageAmount - line.tppTotal, summary.tppSurplusTotal - absorbedTppSurplusTotal);
          line.absorbedTppSurplus += additionalAbsorbedSurplus;
          summary.tppSurplusTotal -= additionalAbsorbedSurplus;
          absorbedTppSurplusTotal += additionalAbsorbedSurplus;
          line.tppTotal += additionalAbsorbedSurplus;
        }
      })
    }
    summary.nonCompensatedDamageTotal = Math.max(summary.nonCompensatedDamageTotal - absorbedTppSurplusTotal, 0)
  }

  if (summary.nonCompensatedDamageTotal > 0 && summary.nonAffectedTotal > 0) {
    let absorbedNonAffectedPart = 0;
    futurePGPFLines.forEach(line => {
      if (line.nonCompensatedDamage > 0 && absorbedNonAffectedPart < summary.nonAffectedTotal && summary.nonCompensatedDamageTotal > 0) {
        line.absorbedNonAffectedPart = Math.min(line.nonCompensatedDamage, summary.nonAffectedTotal);
        absorbedNonAffectedPart += line.absorbedNonAffectedPart;
        line.nonCompensatedDamage -= line.absorbedNonAffectedPart
        line.tppTotal += line.absorbedNonAffectedPart
        summary.nonCompensatedDamageTotal -= line.absorbedNonAffectedPart
      }
    })
    futurePGPFLines.forEach(line => {
      if (line.nonCompensatedDamage > 0 && absorbedNonAffectedPart < summary.nonAffectedTotal && summary.nonCompensatedDamageTotal > 0) {
        line.absorbedNonAffectedPart = Math.min(line.nonCompensatedDamage, summary.nonAffectedTotal);
        absorbedNonAffectedPart += line.absorbedNonAffectedPart;
        line.nonCompensatedDamage -= line.absorbedNonAffectedPart;
        line.tppTotal += line.absorbedNonAffectedPart;
        summary.nonCompensatedDamageTotal -= line.absorbedNonAffectedPart;
      }
      if (line.nonAffectedPart > 0 && absorbedNonAffectedPart > 0) {
        line.spreadNonAffectedPart = Math.min(absorbedNonAffectedPart, line.nonAffectedPart);
        absorbedNonAffectedPart -= line.spreadNonAffectedPart;
        line.tppTotal -= line.spreadNonAffectedPart;
      }
    })
  }
  futurePGPFLines.forEach(line => {
    line.absorbedTpp = 0
    if (line.absorbedNonAffectedPart > 0)
      line.absorbedTpp += line.absorbedNonAffectedPart
    if (line.absorbedTppSurplus > 0)
      line.absorbedTpp += line.absorbedTppSurplus
  })


  return { lines: futurePGPFLines, summary: summary };
}

export const getPastFutureSummary = (pastDamage, futureDamage, pastTpp, futureTpp, compensationRate) => {
  let summary = {
    past:
    {
      damage: pastDamage,
      resp_party: 0,
      tpp: pastTpp,
      tpp_surplus: 0,
      absorbed_tpp_surplus: 0,
      after_cascade_tpp: 0,
      after_tpp_non_compensated: 0,
      after_resp_party_non_compensated: 0,
      virtual_tpp_recourse: 0,
      pref_right_cascaded: 0,
      pref_right_absorbed: 0,
      tpp_recourse: 0,
      victim_amount: 0,
    },
    future:
    {
      damage: futureDamage,
      resp_party: 0,
      tpp: futureTpp,
      tpp_surplus: 0,
      absorbed_tpp_surplus: 0,
      after_cascade_tpp: 0,
      after_tpp_non_compensated: 0,
      after_resp_party_non_compensated: 0,
      virtual_tpp_recourse: 0,
      pref_right_cascaded: 0,
      pref_right_absorbed: 0,
      tpp_recourse: 0,
      victim_amount: 0,
    },
    entry: { tpp_surplus: 0 },
  }


  summary.past.resp_party = round2Decimal(pastDamage * parseFloat(compensationRate) / 100);
  summary.future.resp_party = round2Decimal(futureDamage * parseFloat(compensationRate) / 100);

  summary.past.tpp_surplus = round2Decimal(Math.max(0, summary.past.tpp - summary.past.damage))
  summary.future.tpp_surplus = round2Decimal(Math.max(0, summary.future.tpp - summary.future.damage))

  summary.past.absorbed_tpp_surplus = round2Decimal(Math.min(summary.future.tpp_surplus, Math.max(0, summary.past.damage - summary.past.tpp)))
  summary.future.absorbed_tpp_surplus = round2Decimal(Math.min(summary.past.tpp_surplus, Math.max(0, summary.future.damage - summary.future.tpp)))

  summary.past.after_cascade_tpp = round2Decimal(summary.past.tpp + summary.past.absorbed_tpp_surplus - summary.past.tpp_surplus)
  summary.future.after_cascade_tpp = round2Decimal(summary.future.tpp + summary.future.absorbed_tpp_surplus - summary.future.tpp_surplus)

  summary.past.after_tpp_non_compensated = round2Decimal(Math.max(0, summary.past.damage - summary.past.after_cascade_tpp))
  summary.future.after_tpp_non_compensated = round2Decimal(Math.max(0, summary.future.damage - summary.future.after_cascade_tpp))

  summary.past.after_resp_party_non_compensated = round2Decimal(Math.max(0, summary.past.after_tpp_non_compensated - summary.past.resp_party))
  summary.future.after_resp_party_non_compensated = round2Decimal(Math.max(0, summary.future.after_tpp_non_compensated - summary.future.resp_party))

  summary.past.virtual_tpp_recourse = round2Decimal(Math.max(0, summary.past.resp_party - summary.past.after_tpp_non_compensated))
  summary.future.virtual_tpp_recourse = round2Decimal(Math.max(0, summary.future.resp_party - summary.future.after_tpp_non_compensated))

  summary.past.pref_right_cascaded = round2Decimal(Math.min(summary.future.after_resp_party_non_compensated, summary.past.virtual_tpp_recourse))
  summary.future.pref_right_cascaded = round2Decimal(Math.min(summary.past.after_resp_party_non_compensated, summary.future.virtual_tpp_recourse))

  summary.past.pref_right_absorbed = summary.future.pref_right_cascaded
  summary.future.pref_right_absorbed = summary.past.pref_right_cascaded

  summary.past.tpp_recourse = round2Decimal(Math.max(0, summary.past.virtual_tpp_recourse - summary.future.after_resp_party_non_compensated))
  summary.future.tpp_recourse = round2Decimal(Math.max(0, summary.future.virtual_tpp_recourse - summary.past.after_resp_party_non_compensated))

  summary.past.victim_amount = round2Decimal(Math.min(summary.past.resp_party, summary.past.after_tpp_non_compensated) + summary.past.pref_right_absorbed)
  summary.future.victim_amount = round2Decimal(Math.min(summary.future.resp_party, summary.future.after_tpp_non_compensated) + summary.future.pref_right_absorbed)
  if (summary.past.tpp + summary.future.tpp > summary.past.damage + summary.future.damage)
    summary.entry.tpp_surplus = round2Decimal(summary.past.tpp + summary.future.tpp - (summary.past.damage + summary.future.damage))

  return summary
}


function round2Decimal(value){
  return Math.round(value * 100) / 100
}
