import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { interval } from "../../utils";
import { annuityPeriods } from "../constants";

import { Field, arrayPush, FieldArray, change } from "redux-form";
import { connect } from "react-redux"

import AmountField from "../../fields/AmountField";
import NumberField from "../../fields/NumberField";
import RenderActionsCol from "./renderActionsCol"
import RenderTPP from "./renderTPP"
import RenderSwitch from "../../fields/RenderSwitch"

import * as capiTables from "../../capitalisation_tables/capitalisationTables";
import {
  getPERFromCapiTable,
  getLineAmount,
  capLineResponsiblePartyAmount,
  capLineTPPRecourse,
  getDsfPastArrSummary,
  getDsfFutureArrSummary,
} from "../calculations";
import DatePicker, {
  formatDates,
  normalizeDates,
} from "../creation_wizard/RenderDateInput";
import Append from "../../fields/Append"

let DsfAnnuityCapitalisation = props => {
  const {
    colSpan,
    item,
    values,
    entry,
    lineIndex,
    victim,
    lineCapitalisedAmount,
    dispatch,
    line,
    meta,
  } = props;
  let startAge = -1;
  if (values[entry][lineIndex] && values[entry][lineIndex].arr && values[entry][lineIndex].arr.annCap) {
    let birthDate = victim.birthDate;
    if (birthDate <= values[entry][lineIndex].arr.annCap.startDate) {
      startAge = Math.floor(interval(birthDate, values[entry][lineIndex].arr.annCap.startDate, "years", false));
    }
  }

  const pastSummary = getDsfPastArrSummary(values, entry, lineIndex, item, victim);
  const pastVictimAmount = pastSummary.victimAmount;

  const futureSummary = getDsfFutureArrSummary(values, entry, lineIndex, item, victim);

  const addTPPLine = () => {
    let newLine = {};
    dispatch(arrayPush("caseDataForm", `${entry}[${lineIndex}].arr.annCap.tppCap`, newLine));
  };

  const selectedCapiTable = values[entry][lineIndex].arr.annCap.capitalisationTable;
  const selectedEndAge = parseInt(values[entry][lineIndex].arr.annCap.capitalisationEndAge);
  const gender = victim.gender

  const responsiblePartyAmount = capLineResponsiblePartyAmount(values, entry, lineIndex, item, victim)
  const victimAmount = futureSummary.victimAmount;
  const thirdPartyPayersAmount = capLineTPPRecourse(values, entry, lineIndex, item, victim);
  const annuity = values[entry][lineIndex].arr.annCap.annuity

  return (
    <Fragment>
      <tr>
        <td className="col-num" />
        <td colSpan={colSpan} className="small align-bottom">
          <div className="d-sm-flex">
            <div className="pl-0 d-sm-flex form-group main-form anncap input-group-sm">
              <Field
                name={`${line}.arr.annCap.startDate`}
                label="A compter du"
                component={DatePicker}
                parse={normalizeDates}
                format={formatDates}

                className=" d-flex input-group-sm"
                onChange={(event, newValue) => {
                  let PER = 0;
                  if (selectedCapiTable && selectedCapiTable !=="PERdirectInput" && gender && selectedEndAge && newValue && newValue.length === 10)
                  {
                    PER = getPERFromCapiTable(
                      selectedCapiTable,
                      gender,
                      Math.floor(interval(victim.birthDate, newValue, "years", false)),
                      selectedEndAge,
                    )
                    dispatch(change("caseDataForm", `${entry}[${lineIndex}].arr.annCap.capitalisationPER`, PER.toString().replace(".", ",")))
                  }
                }
                }
                append={startAge >= 0 ? `(${startAge} an${startAge >= 2 ? "s" : ""})` : ""}
              />
              {startAge >= 0 && <Append append={`(${startAge} an${startAge >= 2 ? "s" : ""})`} className=" pl-1 pr-2" />}
              <label
                className="control-label"
                htmlFor={`${entry}[${lineIndex}].capitalisationTable`}
              >Table de capitalisation :</label>
              <Field
                name={`${line}.arr.annCap.capitalisationTable`}
                component="select"
                className="custom-select captable-select"
                onChange={(event, newValue) => {
                  let PER = "";
                  if (newValue && newValue !== "PERdirectInput"
                    && gender && startAge >= 0 && selectedEndAge >= 0)
                    PER = getPERFromCapiTable(
                      newValue,
                      gender,
                      startAge,
                      selectedEndAge,
                    );
                  dispatch(change("caseDataForm", `${entry}[${lineIndex}].arr.annCap.capitalisationPER`, PER.toString().replace(".", ",")))
                  !newValue && dispatch(change("caseDataForm", `${entry}[${lineIndex}].capitalisationPER`, ""))
                }
                }
                id={`${entry}[${lineIndex}].arr.annCap.capitalisationTable`}
              >
                <option value=""></option>
                <option value="PERdirectInput">Libre - Saisir un PER</option>
                {capiTables.tableList.map(table => (
                  <option key={table.id} value={table.id}>
                    {table.author}
                    {table.year && " " + table.year}
                    {table.rate && " " + table.rate}
                  </option>
                ))}
              </Field>
              {startAge >= 0 &&
                selectedCapiTable &&
                selectedCapiTable.length > 0 &&
                (selectedCapiTable !== "PERdirectInput" ?
                  (<Fragment>
                    <label
                      className="control-label pl-2"
                      htmlFor={`${entry}[${lineIndex}].arr.annCap.capitalisationEndAge`}
                    >Âge de fin</label>
                    <Field
                      name={`${line}.arr.annCap.capitalisationEndAge`}
                      component="select"
                      className="custom-select endage-select"
                      id={`${entry}[${lineIndex}].arr.annCap.capitalisationEndAge`}
                      onChange={(event, newValue) => {
                        let PER = 0
                        if (selectedCapiTable && gender && startAge >= 0)
                          PER = getPERFromCapiTable(
                            selectedCapiTable,
                            gender,
                            startAge,
                            newValue,
                          )
                        dispatch(change("caseDataForm", `${entry}[${lineIndex}].arr.annCap.capitalisationPER`, PER.toString().replace(".", ",")))
                      }
                      }
                    >
                      <option value=""></option>
                      {victim.gender &&
                        capiTables[selectedCapiTable]
                          .get(victim.gender)
                          .filter(ent => ent[0] === startAge)
                          .map(entry => (
                            <option key={entry[1]} value={entry[1]}>
                              {entry[1] === 999 ? "viager" : entry[1] + " ans"}
                            </option>
                          ))}
                    </Field>
                    {selectedEndAge > 0 && (
                      <React.Fragment>
                        <label
                          className="control-label pl-2 ml-auto"
                          htmlFor={`${entry}[${lineIndex}].arr.annCap.capitalisationPER`}
                          title="Prix de l'euro de rente"
                        >
                          PER :{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control per-input"
                          readOnly
                          id={`${entry}[${lineIndex}].arr.annCap.capitalisationPER`}
                          value={getPERFromCapiTable(
                            selectedCapiTable,
                            gender,
                            startAge,
                            selectedEndAge,
                          ).toString().replace(".", ",")}
                        />
                      </React.Fragment>
                    )}
                  </Fragment>
                  )
                  :
                  (
                    <React.Fragment>
                      <label
                        className="control-label pl-2"
                        htmlFor={`${entry}[${lineIndex}].arr.annCap.capitalisationPER`}
                        title="Prix de l'euro de rente"
                      >
                        PER :{" "}
                      </label>
                      <Field
                        name={`${line}.arr.annCap.capitalisationPER`}
                        id={`${entry}[${lineIndex}].arr.annCap.capitalisationPER`}
                        component="input"
                        type="text"
                        className="form-control per-input"
                      />
                    </React.Fragment>
                  ))
              }
            </div>
          </div>
        </td>
        <td className="col-amount text-left align-bottom">
          <em>Montant capitalisé :</em>
          <AmountField fieldValue={lineCapitalisedAmount.capitalisedAmount} />
        </td>
        <RenderActionsCol
          item={item}
          lineAmount={getLineAmount(values, entry, lineIndex, item).toFixed(2)}
          line={line}
          addTPPLine={() => addTPPLine(lineIndex)}
          capitalTPP={true}
          secondaryLine={true}
        />
      </tr>
      {values[entry][lineIndex].arr &&
        values[entry][lineIndex].arr.annCap &&
        values[entry][lineIndex].arr.annCap.tppCap &&
        values[entry][lineIndex].arr.annCap.tppCap.length > 0 && (
        <FieldArray
          name={`${line}.arr.annCap.tppCap`}
          colSpan={colSpan}
          line={line}
          component={RenderTPP}
          entry={entry}
          lineIndex={lineIndex}
          tppValues={values[entry][lineIndex].arr.annCap.tppCap}
          entryMeta={meta[entry]}
          annCap={true}
          lineAmount={lineCapitalisedAmount.capitalisedAmount}
          tppCap={true}
          caption={`le montant capitalisé des arrérages à échoir de la dépense n° ${lineIndex + 1}`}
          arrerageType={"annCap"}
        />
      )}
      {parseInt(victim.rateOfCompensationEntitlement) < 100 && (
        <tr>
          <td className="col-num pt-0 pb-0" />
          <td colSpan={colSpan} className="text-right align-bottom pt-0 pb-0">
            <em>Dette du/des responsable(s)</em>
          </td>
          <td className="col-amount text-left align-bottom pt-0 pb-0">
            <AmountField
              readOnly
              fieldValue={responsiblePartyAmount}
            />
          </td>
          <td className="col-act pt-0 pb-0" />
        </tr>
      )}
      <tr>
        <td className="col-num pt-0 pb-0" />
        <td colSpan={colSpan} className="text-right align-bottom pt-0 pb-0">
          <div className="d-sm-flex float-left">
            <Field
              name={`${line}.arr.annCap.annuity`}
              component={RenderSwitch}
              label="Verser sous forme de rente"
              className=" pl-0 anncap text-nowrap"
            />
          </div>
          <em>Créance de la victime{futureSummary.ncpAbsorbedTpp > 0 && `, dont ${Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
          }).format(futureSummary.ncpAbsorbedTpp)} de dette du responsable non affectée à l'indemnisation de la victime sur les arrérages échus`}</em>
        </td>
        <td className="col-amount text-left pt-0 pb-0">
          <AmountField readOnly fieldValue={Math.max(victimAmount, 0)} />
        </td>
        <td className="col-act pt-0 pb-0" />
      </tr>
      {annuity && <Fragment>
        <tr>
          <td className="col-num pt-0 pb-0" />
          <td colSpan={colSpan} className="text-right pt-0 pb-0">
            <div className="input-group input-group-sm flex-nowrap float-right anncapLabels">
              <div className="input-group-prepend">
                <span className="input-group-text pr-0">Versée à</span>
              </div>
              <Field
                name={`${entry}[${lineIndex}].arr.annCap.annuityPercentage`}
                component={NumberField}
                placeholder={"0"}
                max={100}
                append={"%"}
                className="refNumber font-weight-bold"
              />
              <div className="input-group-append">
                <span className="input-group-text">&nbsp;sous forme de rente&nbsp;</span>
              </div>
              <Field
                name={`${line}.arr.annCap.annuityPeriodicity`}
                component="select"
                className="custom-select periodicity-select font-weight-bold"
                id={`${entry}[${lineIndex}].arr.annCap.annuityPeriodicity`}
              >
                <option value=""></option>
                {annuityPeriods.map((annPer, i) => (
                  <option key={i} value={annPer[0]}>
                    {annPer[1]}
                  </option>
                ))}
              </Field>
            </div>
          </td>
          <td className="col-amount text-left pt-0 pb-0">
            <AmountField
              readOnly
              fieldValue={Math.max((victimAmount || 0) / (lineCapitalisedAmount.PER || 1)
                * parseInt(values[entry][lineIndex].arr.annCap.annuityPeriodicity || 0) / 12
                * parseFloat(values[entry][lineIndex].arr.annCap.annuityPercentage ?values[entry][lineIndex].arr.annCap.annuityPercentage.toString().replace(",",".") : 0) / 100, 0)}
            />
          </td>
          <td className="p-0">
            {values[entry][lineIndex].arr.annCap.annuityPeriodicity &&
              <div className="input-group input-group-sm flex-nowrap">
                <div className="input-group-append">
                  <span className="input-group-text">/ {annuityPeriods.filter(period => period[0] === parseInt(values[entry][lineIndex].arr.annCap.annuityPeriodicity))
                    .map(entry => entry[2])}</span>
                </div>
              </div>}
          </td>
        </tr>
        <tr>
          <td className="col-num pt-0 pb-0" />
          <td colSpan={colSpan} className="text-right pt-0 pb-0">
            <em>Reliquat versé en capital</em>
          </td>
          <td className="col-amount text-left pt-0 pb-0">
            <AmountField readOnly fieldValue={Math.max(victimAmount, 0) * (100 - parseFloat(values[entry][lineIndex].arr.annCap.annuityPercentage? values[entry][lineIndex].arr.annCap.annuityPercentage.toString().replace(",",".") : 0)) / 100} />
          </td>
          <td className="col-act pt-0 pb-0" />
        </tr>
      </Fragment>}
      {((values[entry][lineIndex].arr.annCap.tppCap &&
        values[entry][lineIndex].arr.annCap.tppCap.length > 0) || futureSummary.absorbedTppSurplus > 0) && (
        <Fragment>
          <tr>
            <td className="col-num pt-0 pb-0" />
            <td colSpan={colSpan} className="text-right pt-0 pb-0">
              <em>Recours du/des tiers payeur(s){futureSummary.absorbedTppSurplus < 0 && `, y compris le report de ${Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(futureSummary.absorbedTppSurplus)} des arrérages échus`}</em>
            </td>
            <td className="col-amount text-left align-bottom pt-0 pb-0">
              <AmountField
                readOnly
                fieldValue={thirdPartyPayersAmount}
              />
            </td>
            <td className="col-act pt-0 pb-0" />
          </tr>
          {victimAmount < 0 && values[entry][lineIndex].arr.past.length > 0 && (
            <tr>
              <td className="col-num pt-0 pb-0" />
              <td colSpan={colSpan + 1} className="text-right align-bottom pt-0 pb-0">
                <small className="text-danger">
                    Le reliquat de paiement de tiers payeur(s) non imputé de{" "}{Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(-victimAmount)} est reporté automatiquement sur les éventuels arrérages échus de cette dépense.
                </small>
              </td>
              <td className="col-act pt-0 pb-0" />
            </tr>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

DsfAnnuityCapitalisation.propTypes = {
  colSpan: PropTypes.number,
  values: PropTypes.object,
  entry: PropTypes.string,
  lineIndex: PropTypes.number,
  victim: PropTypes.object,
  lineCapitalisedAmount: PropTypes.object,
  lineAmount: PropTypes.number,
  line: PropTypes.string,
};

export default connect()(DsfAnnuityCapitalisation);
