import React from 'react'

export default function ExtendSubscription(props) {
  const { active } = props
  return (
    <div className='mr-2'>
      <a className={`btn btn-primary${active ? "" : " disabled"}`} href="https://billing.stripe.com/p/login/bIY15B9KOc9ac4UeUU">
                Prolonger mon abonnement
      </a>
    </div>
  )
}
