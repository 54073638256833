import React from "react";
import { connect } from "react-redux";
import { FieldArray, getFormValues } from "redux-form";
import PropTypes from "prop-types";
import { itemTypes } from "../itemTypes";
import AmountField from "../../fields/AmountField";
import BtnAddItemLine from "../../buttons/BtnAddItemLine";
import BtnAddEntryTPPLine from "../../buttons/BtnAddEntryTPPLine";
import IndirectRenderEntryTPP from "./IndirectRenderEntryTPP";
import { getItemHoursTotalStr } from "../calculations";

function IndirectItemTableFooter(props) {
  const { item, entry, values, itemIndex, victim, addLine, addTPPLine, meta, disableEntryTPP, victimId } = props;
  const colSpan = itemTypes.get(item.itemType).length;
  const entryName = entry.id + itemIndex;
  let itemSummary = {}
  if (values._meta && values._meta[entry.id] && values._meta[entry.id].items && values._meta[entry.id].items[itemIndex])
    itemSummary = values._meta[entry.id].items[itemIndex];

  let annuityTotal = 0,
    responsiblePartyTotal = 0,
    victimTotal = 0,
    thirdPartyPayersTotal = 0,
    hasTPP = false,
    afterTPPTotal = 0,
    cascadedTotal = 0;
  let annuityDetails = {};

  if (
    values._meta &&
        values._meta[entry.id] &&
        values._meta[entry.id].items &&
        values._meta[entry.id].items[itemIndex]
  ) {
    if (values._meta[entry.id].items[itemIndex].annuityTotal)
      annuityTotal = values._meta[entry.id].items[itemIndex].annuityTotal;
    if (values._meta[entry.id].items[itemIndex].annuityDetails)
      annuityDetails = values._meta[entry.id].items[itemIndex].annuityDetails;
    if (values._meta[entry.id].items[itemIndex].responsiblePartyTotal)
      responsiblePartyTotal = values._meta[entry.id].items[itemIndex].responsiblePartyTotal;
    if (values._meta[entry.id].items[itemIndex].victimTotal)
      victimTotal = values._meta[entry.id].items[itemIndex].victimTotal;
    if (values._meta[entry.id].items[itemIndex].hasTPP)
      hasTPP = values._meta[entry.id].items[itemIndex].hasTPP;
    if (values._meta[entry.id].items[itemIndex].thirdPartyPayersTotal)
      thirdPartyPayersTotal = values._meta[entry.id].items[itemIndex].thirdPartyPayersTotal;
    if (values._meta[entry.id].items[itemIndex].afterTPPTotal)
      afterTPPTotal = values._meta[entry.id].items[itemIndex].afterTPPTotal;
    if (values._meta[entry.id].items[itemIndex].cascadedTotal)
      cascadedTotal = values._meta[entry.id].items[itemIndex].cascadedTotal;
  }


  let totalLabelDetails = "";
  item.label
    ? (totalLabelDetails += item.label.toLowerCase() + (entry.abbr ? " " + entry.abbr : ""))
    : (totalLabelDetails +=
            entry.label.toLowerCase() + (entry.additionalLabel ? " " + entry.additionalLabel : ""));
  let totalLabel = "Total " + totalLabelDetails;

  return (
    <tfoot>
      {(item.itemType === "TPT") && itemSummary.nbLines > 1 && 
        <tr>
          <td colSpan={4} className="main-line" />
          <td className="main-line">
            <div className="input-group-append" style={{ marginLeft: 230 }}><span className="input-group-text pt-0 pb-0" style={{ fontSize: "0.71rem" }}>{getItemHoursTotalStr(values[entryName])}</span></div>
          </td>
          <td colSpan={3} className="main-line" />
        </tr>
      }
      <React.Fragment>
        {values.TPP && values.TPP[entryName] && values.TPP[entryName].length > 0 && (
          <FieldArray
            name={`TPP[${entryName}]`}
            entryName={entryName}
            colSpan={colSpan}
            component={IndirectRenderEntryTPP}
            entry={entry.abbr}
            tppValues={values.TPP[entryName]}
            entryMeta={meta.TPP && meta.TPP[entryName]}
            victim={victim}
          />
        )}
        {itemIndex === 0 &&
        <tr>
          <td className="main-line"/>
          <td colSpan={colSpan + 1} className="main-line text-right">
            {item.multiLine && <BtnAddItemLine addLine={addLine} label={item.lineLabel}/>}
            {(!disableEntryTPP && !item.disableEntryTPP) && <BtnAddEntryTPPLine addTPPLine={addTPPLine}/>}
            {(entry.items.length === 1 || (values[entryName] && values[entryName].length > 1) || (hasTPP && !item.annuityCapitalisation)) &&
            <h5 className="mb-0">{totalLabel}{item.itemType === "DSF" &&
            <span style={{ fontSize: "0.7rem" }}> (arrérages échus et à échoir)</span>}</h5>}
          </td>
          <td className="main-line col-act"/>
        </tr>}
        {(entry.items.length === 1 || (values[entryName] && values[entryName].length > 1) || (hasTPP && !item.annuityCapitalisation)) &&
        <React.Fragment>
          {/* {parseInt(victim.rateOfCompensationEntitlement) < 100 && entry.id !== "DNAPRDI" && entry.id !== "PROV" && (
              <tr>
                <td />
                <th scope="row" colSpan={colSpan}>
                  Dette du/des responsable(s)
                </th>
                <td className="col-amount">
                  <AmountField fieldValue={responsiblePartyTotal} />
                </td>
                <td />
              </tr>
            )} */}
          <tr>
            <td/>
            <th scope="row" colSpan={colSpan}>
              {entry.id === "PROV" ? "Total" : `Créance de la victime${item.annuityCapitalisation ? " versée en capital" : ""}`}
            </th>
            <td className="col-amount">
              <AmountField fieldValue={Math.max(victimTotal, 0)}/>
            </td>
            <td/>
          </tr>
        </React.Fragment>
        }
        {(entry.items.length === 1 || (values[entryName] && values[entryName].length > 1) || (hasTPP && !item.annuityCapitalisation)) && (hasTPP || thirdPartyPayersTotal > 0 || cascadedTotal < 0 || itemSummary.absorbedFutureTppSurplus > 0) && (
          <tr>
            <td/>
            <th scope="row" colSpan={colSpan}>
                        Recours du/des tiers
                        payeur(s){itemSummary.absorbedFutureTppSurplus > 0 && (entry.id === "PInd") && `, y compris le report de ${Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(itemSummary.absorbedFutureTppSurplus)} des arrérages échus`}
            </th>
            <td className="col-amount">
              <AmountField fieldValue={thirdPartyPayersTotal}/>
            </td>
            <td/>
          </tr>
        )}
      </React.Fragment>
      {afterTPPTotal < 0 && !item.annuityCapitalisation && !item.nonCascadingLines && entry.id !== "IP" && (
        <tr>
          <td/>
          <td colSpan={colSpan + 1} className="text-right">
            <small className="text-danger">
                        Le reliquat de paiement de tiers payeur(s) non imputé de&nbsp;{Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(-afterTPPTotal)}&nbsp;
              {(entry.id === "PInd") ? `est reporté automatiquement sur les éventuels arrérages ${itemIndex === 0 ? "à échoir" : "échus"}` : "n'est pas reporté automatiquement sur les autres postes de préjudice"}.
            </small>
          </td>
          <td/>
        </tr>
      )}
      {itemSummary.hasNonAffected && entry.id === "PInd" &&
        <tr>
          <td/>
          <td colSpan={colSpan + 1} className="text-right">
            <small className="text-danger">
                        En application du droit de préférence, la créance de la victime et le recours du/des tiers
                        payeur(s) s'apprécient sur l'ensemble des composantes de perte en industrie.
                        La part de la dette du responsable non affectée à l'indemnisation de la victime sur certaines
                        composantes est ainsi reportée sur les autres composantes.
            </small>
          </td>
          <td/>
        </tr>
      }
      {itemSummary.absorbedFutureTppSurplus > 0 && entry.id === "PInd" &&
        <tr>
          <td/>
          <td colSpan={colSpan + 1} className="text-right">
            <small className="text-danger">
                        Le reliquat de paiement de tiers payeur(s) non imputé sur les arrérages à échoir
                        de {Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(itemSummary.futureTppSurplus)}&nbsp;est reporté automatiquement sur les arrérages échus de
                        la perte en
                        indutrie&nbsp;{itemSummary.futureTppSurplus !== itemSummary.absorbedFutureTppSurplus ? `à hauteur de ${Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(itemSummary.absorbedFutureTppSurplus)}` : ""}.</small>
          </td>
          <td/>
        </tr>
      }
    </tfoot>
  );
}

IndirectItemTableFooter.propTypes = {
  item: PropTypes.object.isRequired,
  entry: PropTypes.object.isRequired,
  itemIndex: PropTypes.number,
  values: PropTypes.object,
  victim: PropTypes.object,
  addLine: PropTypes.func,
  addTPPLine: PropTypes.func,
  meta: PropTypes.object,
};


const mapStateToProps = (state, ownProps) => {
  let caseValues = getFormValues("caseDataForm")(state);
  let values = {};
  if (caseValues) values = caseValues.indirectVictims[ownProps.victimId].caseData;

  return { caseValues, values };
};

export default connect(mapStateToProps)(IndirectItemTableFooter);
