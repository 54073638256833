import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { localTheme } from "../../interests/theme";
import RenderInput from "./RenderInput";
import DatePicker, {
  formatDates,
  normalizeDates,
} from "./RenderDateInput";
import RenderTitleInput from "./RenderTitleInput";
import RenderGenderChoice from "./RenderGenderChoice";
import { formatDate } from "../../utils";

const required = value => (value ? undefined : "Champ obligatoire");

let CreateVictim = props => {
  const {
    handleSubmit,
    caseId,
    formType,
    dispatch,
    caseTitle,
    lastName,
    firstName,
    birthDate,
    victimGender,
    valid
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [pendingSubmit, setPendingSubmit] = useState(null);

  const handleDialogClose = () => {
    setDialogOpen(false);
    setPendingSubmit(null);
  };

  const handleConfirmSubmit = () => {
    handleDialogClose();
    if (pendingSubmit) {
      pendingSubmit();
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (caseId) {
      // Submit the form
      handleSubmit(event);
      
      // Restore mutable fields after submission using current props values
      if (caseTitle) dispatch(change("victim", "title", caseTitle));
      if (lastName) dispatch(change("victim", "last_name", lastName));
      if (firstName) dispatch(change("victim", "first_name", firstName));
    } else if (valid) {
      setPendingSubmit(() => () => handleSubmit(event));
      setDialogOpen(true);
    } else {
      handleSubmit(event); // Let redux-form show validation errors
    }
  };

  const setTitleLastName = (newValue, previousValue) => {
    if (!caseTitle || caseTitle === previousValue)
      dispatch(change("victim", "title", newValue));
  };

  const setTitleFirstName = (newValue, previousValue) => {
    if (
      !caseTitle ||
      caseTitle === lastName ||
      caseTitle === lastName + " " + previousValue
    )
      dispatch(change("victim", "title", lastName + " " + newValue));
  };

  return (
    <div className="card border-primary wizard-form mx-auto">
      <h3 className="card-header">Victime</h3>
      <form onSubmit={onSubmit} id="victim-form" className="h-100 d-flex flex-column">
        <div
          className="card-body"
        >
          <Field
            label="Nom"
            name="last_name"
            component={RenderInput}
            optional={true}
            type="text"
            placeholder="Nom de famille"
            onChange={(event, newValue, previousValue) =>
              setTitleLastName(newValue, previousValue)
            }
            wizard={true}
            autoFocus
          />
          <Field
            label="Prénom"
            name="first_name"
            component={RenderInput}
            optional={true}
            type="text"
            placeholder="Prénom"
            onChange={(event, newValue, previousValue) =>
              setTitleFirstName(newValue, previousValue)
            }
            wizard={true}
          />
          <Field
            label="Référence du dossier"
            name="title"
            component={RenderTitleInput}
            type="text"
            placeholder="Référence du dossier"
            validate={required}
            mandatory={true}
          />
          <Field
            label="Date de naissance"
            name="birth_date"
            component={DatePicker}
            parse={normalizeDates}
            format={formatDates}
            validate={required}
            mandatory={true}
            className=" wizard"
            wizard={true}
            disabled={!!caseId}
            title={caseId ? "La date de naissance n'est pas modifiable après la création du dossier" : ""}
          />
          <Field
            name="victimGender"
            component={RenderGenderChoice}
            validate={required}
            mandatory={true}
            formType={formType}
            disabled={!!caseId}
            title={caseId ? "Le sexe n'est pas modifiable après la création du dossier" : ""}
          />
          <button
            type="submit"
            className="btn btn-sm btn-primary mt-auto w-100"
            title="Enregistrer les modifications"
          >
            {!caseId ? "Enregistrer et passer à l'étape suivante >>" : "Enregistrer les modifications"}
          </button>
        </div>
      </form>
      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleConfirmSubmit}
        birthDate={birthDate}
        victimGender={victimGender}
      />
    </div>
  );
};

const ConfirmationDialog = ({ open, onClose, onConfirm, birthDate, victimGender }) => (
  <ThemeProvider theme={localTheme}>
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirmation des informations de la victime</DialogTitle>
      <DialogContent>
        <p>
          La date de naissance ({formatDate(birthDate, 0, false, true)}) et le sexe ({victimGender === 'M' ? "homme" : "femme"}) de la victime ne sont pas modifiables après la création du dossier.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Modifier ces informations
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained">
          Confirmer la création du dossier
        </Button>
      </DialogActions>
    </Dialog>
  </ThemeProvider>
);

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  birthDate: PropTypes.string,
  victimGender: PropTypes.string,
};

CreateVictim = reduxForm({
  form: "victim",
  enableReinitialize: true,
})(CreateVictim);

CreateVictim.propTypes = {
  handleSubmit: PropTypes.func,
  caseId: PropTypes.number,
  formType: PropTypes.string,
  caseTitle: PropTypes.string,
  lastName: PropTypes.string,
  firstName: PropTypes.string,
  dispatch: PropTypes.func,
  initialValues: PropTypes.object,
  birthDate: PropTypes.string,
  victimGender: PropTypes.string,
  valid: PropTypes.bool,
};

const victimSelector = formValueSelector("victim");

const mapStateToProps = state => {
  let initialValues = {};
  let caseTitle = "";
  let lastName = "";
  let firstName = "";
  let birthDate = "";
  let victimGender = "";

  if (state.cases && state.cases.case && state.cases.case.json_data) {
    initialValues = state.cases.case.json_data.victim;
    initialValues.title = state.cases.case.title;
  }
  caseTitle = victimSelector(state, "title");
  lastName = victimSelector(state, "last_name");
  firstName = victimSelector(state, "first_name");
  birthDate = victimSelector(state, "birth_date");
  victimGender = victimSelector(state, "victimGender");

  return { initialValues, caseTitle, lastName, firstName, birthDate, victimGender };
};

export default connect(mapStateToProps)(CreateVictim);
