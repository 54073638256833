import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade'
import { FieldArray, change } from "redux-form";
import renderHouseholdIncome from './renderHouseholdIncome';
import { getHouseholdIncomeLoss } from "./calculations"

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'left',
    maxWidth:1200,
    minWidth:1000,
    maxHeight: '90vh',
    minHeight:400,
  },

}));

let DisplayHouseholdIncomeForm = (props) => {
  const { open, setIncomeFormOpen, householdIncome, dispatch, indirectVictims } = props;
  
  const classes = useStyles();
  
  const addHouseholdIncome = () => {
    if (householdIncome && householdIncome.length > 0) {
      householdIncome.forEach((line,i) => {
        if (!line.amountDirectInput) dispatch(change("caseDataForm", `householdIncome[${i}].householdLossAmount`, getHouseholdIncomeLoss(line)))
      });
      if (householdIncome.length === 1) {
        indirectVictims.forEach((victim, j) => {
          if (victim.caseData && victim.caseData.PRev0 && victim.caseData.PRev0.length > 0) {
            victim.caseData.PRev0.forEach((incomeLoss, k) => {
              dispatch(change("caseDataForm", `indirectVictims[${j}].caseData.PRev0[${k}].householdLoss`, householdIncome[0].incomeId))
            })}
        })
      }
    }
    setIncomeFormOpen(false);
  };
  
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => setIncomeFormOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title" style={{ textAlign: 'center' }}>
                Calcul du préjudice économique annuel du foyer
            </h2>
            <div className="form-group input-group pt-2">
              <FieldArray component={renderHouseholdIncome} name="householdIncome" />
            </div>
            <div className="text-right">
              <button
                className="btn btn-sm btn-primary mt-auto"
                onClick={() => addHouseholdIncome()}
              >
                  Enregistrer le préjudice économique du foyer
              </button>
            </div>
            <div className="pt-2">
              <em>
                  Le préjudice économique du foyer est commun à toutes les victimes indirectes : toute
                  modification de ce préjudice sera pris en compte pour l'ensemble des victimes
                  indirectes.
              </em>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

DisplayHouseholdIncomeForm.propTypes = {
  open: PropTypes.bool,
  setIncomeFormOpen: PropTypes.func,
  dispatch: PropTypes.func,
  householdIncome: PropTypes.array,
  indirectVictims: PropTypes.array,
};

export default connect()(DisplayHouseholdIncomeForm);
