import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { connect } from "react-redux";
import RenderInput from "../creation_wizard/RenderInput"
import { formatDate, interval } from "../../utils"
import RenderCEDropdown from "./RenderCEDropdown"
import RenderSwitch from "../../fields/RenderSwitch";
import DatePicker, {
  formatDates,
  normalizeDates,
} from "../creation_wizard/RenderDateInput";
import Append from "../../fields/Append"
import { startLaterThanEndDate, getErrMsg, ErrMessageType } from  "../../../cases/utils";
let CaseVictimAccident = (props) => {

  const {
    accidentType,
    victimGender,
    victimBirthDate,
    consolidationDate,
    accidentDate,
    deathDate,
    meta,
    dispatch,
  } = props;
    const accidentDateInvalid = startLaterThanEndDate(victimBirthDate, accidentDate);
    const consolidationDateInvalid = startLaterThanEndDate(victimBirthDate, consolidationDate);
    const consolidationAccidentDateInvalid = startLaterThanEndDate(accidentDate, consolidationDate);
    const deathDateInvalid = startLaterThanEndDate(victimBirthDate, deathDate);
    const deathAccidentDateInvalid = startLaterThanEndDate(accidentDate, deathDate);
    const deathConsolidationDateInvalid  = startLaterThanEndDate(consolidationDate,deathDate);
  const today = new Date();

  const updateMinimized = () => {
    if (meta)
      Object.keys(meta).forEach(key => {
        if (meta[key].minimized)
          openMinimized(key)
      })
  }

    const openMinimized = entryId => {
        let fieldName = "_meta[" + entryId + "].minimized";
        dispatch(
            change(
                "caseDataForm",
                fieldName,
                false
                )
                );
            }

    let consolidationAge = -1;
    let accidentAge = -1;
    let deathAge = -1;

    if (consolidationDate)
        consolidationAge = Math.floor(interval(victimBirthDate, consolidationDate, "years", false));
    if (accidentDate)
        accidentAge = Math.floor(interval(victimBirthDate, accidentDate, "years", false));
    if (deathDate)
        deathAge = Math.floor(interval(victimBirthDate, deathDate, "years", false));


    return (
        <div className="card border-dark main-form mx-auto">
            <div className="card-body">
                <div className="container-fluid main-form-header p-0">
                    <form>
                        <div className="d-flex">
                            <Field
                                label="Nom"
                                name="last_name"
                                component={RenderInput}
                                type="text"
                                placeholder="Nom de famille"
                            />
                            <Field
                                label="Prénom"
                                name="first_name"
                                component={RenderInput}
                                type="text"
                                placeholder="Prénom"
                            />
                            <div className="form-group flex-fill">
                                <label className="control-label" htmlFor="accidentType">
                                    Nature
                                </label>
                                <Field
                                    name="accidentType"
                                    id="accidentType"
                                    component="select"
                                    className="custom-select form-control accidentType"
                                >
                                    <option value="accident" default>accident</option>
                                    <option value="aggravation">aggravation</option>
                                </Field>
                            </div>
                            <div className="form-group flex-fill">
                                <label className="control-label" htmlFor="accidentType">
                                    Date de l'{accidentType ? accidentType : "accident"}
                                </label>
                                <div className="d-flex input-group input-group-sm">
                                    <Field
                                        name="date"
                                        component={DatePicker}
                                        parse={normalizeDates}
                                        format={formatDates}
                                        className=" mr-0"
                                    />
                                    {accidentAge >= 0 && <Append append={`(${accidentAge} an${accidentAge >= 2 ? "s" : ""})`} className=" pl-1" />}
                                </div>
                               {accidentDate ? getErrMsg(accidentDateInvalid, "accidentDateInvalid", ErrMessageType.jsx, accidentType) || getErrMsg(deathAccidentDateInvalid, "deathAccidentDateInvalid", ErrMessageType.jsx, accidentType)  || getErrMsg(consolidationAccidentDateInvalid, "consolidationAccidentDateInvalid", ErrMessageType.jsx, accidentType) : null}
                            </div>

                            <div className="form-group flex-fill">
                                <label className="control-label" htmlFor="consolidationDate">
                                    Date de consolidation
                                </label>
                                <div className="d-flex input-group input-group-sm">
                                    <Field
                                        name="consolidationDate"
                                        component={DatePicker}
                                        parse={normalizeDates}
                                        format={formatDates}
                                        className=" mr-0"
                                    />
                                    {consolidationAge >= 0 && <Append append={`(${consolidationAge} an${consolidationAge >= 2 ? "s" : ""})`} className=" pl-1" />}
                                </div>
                                {consolidationDate ? getErrMsg(consolidationDateInvalid, "consolidationDateInvalid", ErrMessageType.jsx, accidentType) || getErrMsg(consolidationAccidentDateInvalid, "consolidationAccidentDateInvalid", ErrMessageType.jsx, accidentType) || getErrMsg(deathConsolidationDateInvalid,"deathConsolidationDateInvalid", ErrMessageType.jsx, accidentType) : null}
                            </div>
                            <div className="form-group flex-fill">
                                <label className="control-label" htmlFor="deathDate">
                                    Date du décès
                                </label>
                                <div className="d-flex input-group input-group-sm">
                                    <Field
                                        name="deathDate"
                                        component={DatePicker}
                                        parse={normalizeDates}
                                        format={formatDates}
                                        className=" mr-0"
                                    />
                                    {deathAge >= 0 && <Append append={`(${deathAge} an${deathAge >= 2 ? "s" : ""})`} className=" pl-1" />}
                                </div>
                                {deathDate ? getErrMsg(deathDateInvalid,"deathDateInvalid", ErrMessageType.jsx, accidentType) || getErrMsg(deathAccidentDateInvalid,"deathAccidentDateInvalid", ErrMessageType.jsx, accidentType) || getErrMsg(deathConsolidationDateInvalid,"deathConsolidationDateInvalid", ErrMessageType.jsx, accidentType) : null}
                            </div>
                        </div>
                        <div className="mt-2 d-flex">
                            <div>
                                {victimGender === "M" && 'Homme, né'}
                                {victimGender === "F" && 'Femme, née'}&nbsp;le&nbsp;{formatDate(victimBirthDate, 0, false, true)} ({Math.floor(interval(victimBirthDate, today, "years", false))} ans)
                            </div>
                            <Field
                                label="Droit à indemnisation"
                                name="rateOfCompensationEntitlement"
                                component={RenderCEDropdown}
                                helpText="preferenceRight"
                                changeHandler={updateMinimized}
                            />
                            <Field
                                name="disableCascade"
                                component={RenderSwitch}
                                label="Désactiver les déductions en cascade"
                                className=" ml-4 ml-auto"
                                helpText="disableCascade"
                                onChange={() => updateMinimized()}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

CaseVictimAccident = reduxForm({
  form: "victim",
  enableReinitialize: true,
})(CaseVictimAccident);

CaseVictimAccident.propTypes = {
  victimGender: PropTypes.string,
  victimBirthDate: PropTypes.string,
  dispatch: PropTypes.func,
};

const victimSelector = formValueSelector("victim");

const mapStateToProps = state => {
  let initialValues = {};
  let victimGender = "";
  let victimBirthDate;
  let accidentDate;
  let consolidationDate;
  let deathDate;
  let accidentType = "accident";
  let meta;

  if (state.cases && state.cases.case && state.cases.case.json_data) {
    initialValues = state.cases.case.json_data.victim;
  }
  if (state.form && state.form.caseDataForm && state.form.caseDataForm.values && state.form.caseDataForm.values._meta) {
    meta = state.form.caseDataForm.values._meta
  }
  victimGender = victimSelector(state, "victimGender");
  victimBirthDate = victimSelector(state, "birth_date");
  accidentType = victimSelector(state, "accidentType");
  consolidationDate = victimSelector(state, "consolidationDate");
  accidentDate = victimSelector(state, "date");
  deathDate = victimSelector(state, "deathDate");

  return { initialValues, victimGender, victimBirthDate, accidentType, accidentDate, consolidationDate, deathDate, meta };
};

export default connect(mapStateToProps)(CaseVictimAccident);

