import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FieldArray, getFormValues, arrayPush, change } from "redux-form";
import EntryTitle from "./EntryTitle";
import { ItemTableHeader } from "./ItemTableHeader";
import IndirectItemTableFooter from "./IndirectItemTableFooter";
import IndirectItemTableBody from "./IndirectItemTableBody";
import { formatDateField } from "../../utils";
import HelpTip from "../../../layout/HelpTip";
import RenderPRev from "./RenderPRev";
import EntrySummary from "./EntrySummary"
import { calcSingle } from "../calculations_single";

let IndirectVictimEntries = props => {
  const { editAllPIndperiodRate, setEditAllPIndperiodRate, editAllPIndDaysPerYear, setEditAllPIndDaysPerYear, entries, directVictim, indirectVictim, caseValues, dispatch, victimId, indirectCaseValues, indirectMeta, setIncomeFormOpen, setMethodChoiceOpen, indirectVictims } = props;
  const startMinimized = true;

  useEffect(() => {
    const newValues = calcSingle(caseValues, directVictim, entries)
    const equal = JSON.stringify(caseValues.indirectVictims[victimId].caseData._meta) === JSON.stringify(newValues.indirectVictims[victimId].caseData._meta)

    if (equal) {
      return
    }


    dispatch(
      change(
        "caseDataForm",
        `indirectVictims[${victimId}].caseData._meta`,
        newValues.indirectVictims[victimId].caseData._meta,
      ),
    );

  }, [caseValues, directVictim, entries]);

  const minimizeEntry = entryId => {
    let fieldName = `indirectVictims[${victimId}].caseData._meta[${entryId}].minimized`;
    dispatch(
      change(
        "caseDataForm",
        fieldName,
        indirectCaseValues && indirectCaseValues._meta && indirectCaseValues._meta[entryId] && indirectCaseValues._meta[entryId].minimized !== undefined
          ? !indirectCaseValues._meta[entryId].minimized
          : !startMinimized,
      ),
    );
  };


  const addLine = (entry, itemIndex, itemType) => {
    let newLine = {};
    const entryData = indirectCaseValues[entry.id + itemIndex];
    const lastLine = entryData[entryData.length - 1];

    if (entryData.length > 0) {
      if (lastLine.durationType) newLine.durationType = lastLine.durationType;
      else if (!newLine.durationType) newLine.durationType = "days";
      if (lastLine.periodRate) newLine.periodRate = lastLine.periodRate;
      if (lastLine.lossOfOpportunity) newLine.lossOfOpportunity = lastLine.lossOfOpportunity;
      if (lastLine.capitalisationTable) {
        newLine.capitalisationTable = lastLine.capitalisationTable;
        if (lastLine.startDate) {
          newLine.startDate = lastLine.startDate;
          if (lastLine.capitalisationEndAge) {
            newLine.capitalisationEndAge = lastLine.capitalisationEndAge;
            newLine.capitalisationPER = lastLine.capitalisationPER;
          }
        }
        if (lastLine.capitalisationTable === "PERdirectInput" && lastLine.capitalisationPER)
          newLine.capitalisationPER = lastLine.capitalisationPER;
      }
      if (lastLine.annuityPercentage) newLine.annuityPercentage = lastLine.annuityPercentage
      else newLine.annuityPercentage = 0
      if (lastLine.annuityPeriodicity) newLine.annuityPeriodicity = lastLine.annuityPeriodicity;
      if (itemType !== "DSA" && entry.id !== "PROV" && entry.id !== "AUTCap" && entry.id !== "AUT" && (lastLine.endDate || lastLine.startDate)) {
        let prevDate = lastLine.endDate;
        if (!prevDate)
          prevDate = lastLine.startDate;
        let lastEndDate = new Date(prevDate.split("-")[0], parseInt(prevDate.split("-")[1]) - 1, prevDate.split("-")[2]);
        newLine.startDate = formatDateField(lastEndDate.getTime() + 86400000);
      }
      if (lastLine.daysPerYear) newLine.daysPerYear = lastLine.daysPerYear;
      if (lastLine.daysPerYear) newLine.weeksPerYear = lastLine.weeksPerYear;
      if (lastLine.daysPerYear) newLine.monthsPerYear = lastLine.monthsPerYear;
      if (lastLine.periodType) newLine.periodType = lastLine.periodType;
    }

    dispatch(arrayPush("caseDataForm", `indirectVictims[${victimId}].caseData[${entry.id + itemIndex}]`, newLine));
  };

  const addTPPLine = (entry, itemIndex) => {
    let newLine = {};
    const entryName = entry.id + itemIndex;

    dispatch(arrayPush("caseDataForm", `indirectVictims[${victimId}].caseData.TPP.${entryName}`, newLine));
  };

  return (
    <React.Fragment>
      {entries &&
        entries.map(entry => {
          const minimized = Boolean(indirectCaseValues && indirectCaseValues._meta && indirectCaseValues._meta[entry.id] && indirectCaseValues._meta[entry.id].minimized !== undefined
            ? indirectCaseValues._meta[entry.id].minimized
            : startMinimized)

          return <Fragment key={entry.id}>
            <div className={isNaN(entry.id) ? "card border-primary" : ""}>
              <EntryTitle
                entry={entry}
                addLine={() => addLine(entry, 0)}
                victim={directVictim}
                entries={entries}
                minimizeEntry={() => minimizeEntry(entry.id)}
                minimized={
                  minimized
                }
                entryValues={caseValues && caseValues[entry.id + "0"]}
                indirect={true}
                setMethodChoiceOpen={setMethodChoiceOpen}
                incomeLossCalcMethod={caseValues && caseValues.incomeLossCalcMethod}
                indirectVictims={indirectVictims}
              />
              {//!caseValues[entry.id] ||
                indirectCaseValues &&
                indirectCaseValues._meta &&
                indirectCaseValues._meta[entry.id] &&
                (minimized === false) &&
                entry.items && (
                  <div className="card-body">
                    {entry.items[0].itemType === "PRev" ?
                      <FieldArray
                        component={RenderPRev}
                        name={entry.id + "0"}
                        itemId={0}
                        entry={entry}
                        item={entry.items[0]}
                        victim={directVictim}
                        meta={indirectMeta}
                        victimId={victimId}
                        setIncomeFormOpen={setIncomeFormOpen}
                      />
                      :
                      entry.items.map((item, i) => {
                        return (
                          <Fragment key={i}>
                            {entry.items.length > 1 && (
                              <h6 className="d-flex card-title">
                                {item.label}<HelpTip helpText={item.itemType === "TPT" || item.itemType === "PGPA" ? "arrPast" : "arrFuture"} />
                              </h6>
                            )}

                            <table className={`table table-hover table-sm${entry.items.length > 1 && i === 0 && indirectCaseValues[entry.id + i] && indirectCaseValues[entry.id + i].length < 2 ? " mb-4" : ""}`}>
                              <ItemTableHeader item={item} indrectEntry={entry} victimId={victimId} indirectVictim={indirectVictim} editAllPIndperiodRate={editAllPIndperiodRate} setEditAllPIndperiodRate={setEditAllPIndperiodRate} editAllPIndDaysPerYear={editAllPIndDaysPerYear} setEditAllPIndDaysPerYear={setEditAllPIndDaysPerYear} />
                              <FieldArray
                                component={IndirectItemTableBody}
                                name={entry.id + i}
                                itemId={i}
                                entry={entry.id}
                                item={item}
                                victim={directVictim}
                                // values={indirectCaseValues}
                                meta={indirectMeta}
                                victimId={victimId}
                                editAllPIndperiodRate={editAllPIndperiodRate}
                                editAllPIndDaysPerYear={editAllPIndDaysPerYear}
                                indirectVictim={indirectVictim}
                              />
                              <IndirectItemTableFooter
                                item={item}
                                entry={entry}
                                itemIndex={i}
                                victim={directVictim}
                                meta={indirectMeta}
                                victimId={victimId}
                                addLine={() => addLine(entry, i, item.itemType)}
                                addTPPLine={() => addTPPLine(entry, i)}
                                disableEntryTPP={entry.disableEntryTPP}
                              />
                            </table>
                          </Fragment>
                        );
                      })
                    }
                    {entry.items.length > 1 && (
                      <EntrySummary entry={entry} victim={directVictim} caseValues={indirectCaseValues} />
                    )}
                  </div>
                )}
            </div>
          </Fragment>
        },
        )}
      <div className="footer-spacer"></div>
    </React.Fragment>
  );
};


const mapStateToProps = (state, ownProps) => {
  const victimId = ownProps.victimId;

  let entries = [];
  let indirectCaseValues = {};
  let indirectMeta = {};
  let indirectVictims = []
  let caseValues = getFormValues("caseDataForm")(state);
  let directVictim = getFormValues("victim")(state);

  if (caseValues && caseValues.indirectVictims && caseValues.indirectVictims[victimId]) {
    entries = caseValues.indirectVictims[victimId].caseDataForm;
    indirectCaseValues = caseValues.indirectVictims[victimId].caseData;
    indirectVictims = caseValues.indirectVictims;
  }

  return { entries, caseValues, indirectCaseValues, directVictim, indirectMeta, indirectVictims };
};

IndirectVictimEntries.propTypes = {
  dispatch: PropTypes.func,
  entries: PropTypes.array,
  caseValues: PropTypes.object,
  indirectCaseValues: PropTypes.object,
  indirectMeta: PropTypes.object,
  victimId: PropTypes.number,
  directVictim: PropTypes.object,
  setIncomeFormOpen: PropTypes.func,
  setMethodChoiceOpen: PropTypes.func,
  indirectVictims: PropTypes.array,
};

export default connect(mapStateToProps)(IndirectVictimEntries);
