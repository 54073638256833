import React from "react";
import PropTypes from "prop-types";
import Append from "../../fields/Append"

const RenderInput = ({
  input,
  label,
  type,
  placeholder,
  optional,
  mandatory,
  append,
  className,
  wizard,
  autoFocus,
  meta: { touched, error, warning },
}) => {
  return (
    <div
      className={`form-group${touched && (error || warning) ? " has-danger" : ""
      }${className || ""}`}
    >
      <label className="control-label" htmlFor={input.name}>
        {label}
        {optional && <em> (facultatif)</em>}
        {mandatory && <span className="text-danger"> *</span>}
        {touched && (error || warning) && (
          <span className="invalid-feedback ml-3" style={{ display: "inline" }}>{error || warning}</span>
        )}
      </label>
      <div className={append ? "d-flex input-group input-group-sm" : wizard ? "" : "input-group input-group-sm"}>
        <input
          {...input}
          placeholder={placeholder}
          type={type}
          id={input.name}
          lang="fr"
          className={`form-control${touched && (error || warning) ? " is-invalid" : ""}`}
          autoFocus={autoFocus}
        />
        {append && <Append append={append} className=" pl-1" />}
      </div>
    </div>
  );
};

RenderInput.propTypes = {
  input: PropTypes.object,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  mandatory: PropTypes.bool,
  append: PropTypes.string,
  className: PropTypes.string,
  meta: PropTypes.object,
};

export default RenderInput;
