import React from "react";
import PropTypes from "prop-types";

const RenderGenderChoice = ({
  formType,
  input,
  disabled,
  meta: { touched, error, warning },
  title
}) => {
  return (
    <div
      className={`mb-${formType === "main" ? "2" : "4"} form-group${touched && (error || warning) ? " has-danger" : ""}`}
      title={disabled ? title : ""}
    >
      <legend>
        Sexe <span className="text-danger">*</span>
        {touched && (error || warning) && (
          <span className="invalid-feedback ml-3" style={{ display: "inline", fontSize: "60%" }}>{error || warning}</span>
        )}
      </legend>
      <div className={formType === "main" ? "d-flex" : ""}>
        <div className={`custom-control custom-radio ${disabled ? "disabled" : ""}`}>
          <input
            {...input}
            type="radio"
            id="male"
            value="M"
            className={`custom-control-input${touched && (error || warning) ? " is-invalid" : ""}`}
            disabled={disabled}
            title={disabled ? title : ""}
          />
          <label 
            className={`custom-control-label ${disabled ? "text-muted" : ""}`}
            htmlFor="male"
            title={disabled ? title : ""}
          >
            homme
          </label>
        </div>
        <div className={`custom-control custom-radio ${disabled ? "disabled" : ""}`}>
          <input
            {...input}
            name={input.name}
            type="radio"
            id="female"
            value="F"
            className={`custom-control-input${touched && (error || warning) ? " is-invalid" : ""}`}
            disabled={disabled}
            title={disabled ? title : ""}
          />
          <label 
            className={`custom-control-label ${disabled ? "text-muted" : ""}`}
            htmlFor="female"
            title={disabled ? title : ""}
          >
            femme
          </label>
        </div>
      </div>
    </div >
  );
};
RenderGenderChoice.propTypes = {
  input: PropTypes.object,
  required: PropTypes.bool,
  meta: PropTypes.object,
  formType: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

export default RenderGenderChoice;
