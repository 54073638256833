import React from "react";

const useMoveUpAndDown = (fields) => {
  const [hoveredIndex, setHoveredIndex] = React.useState(null);
  const [highlightedIndex, setHighlightedIndex] = React.useState(null);

  const moveFieldDown = (index) => {

    if (index < fields.length - 1) {
      fields.move(index, index + 1); // Move field down
      setHighlightedIndex(index + 1);
      setTimeout(() => setHighlightedIndex(null), 1001)
    }
  };

  const moveFieldUp = (index) => {
    if (index > 0) {
      fields.move(index, index - 1); // Move field up
      setHighlightedIndex(index - 1);
      setTimeout(() => setHighlightedIndex(null), 1001) // force update when highlightedIndex remains the same
    }
  };
  const handleEnterLine = (index) => {
    setHoveredIndex(index)
  }
  const handleLeaveLine = () => {
    setHoveredIndex(null)
  }
  return { moveFieldDown, moveFieldUp, handleEnterLine, handleLeaveLine, hoveredIndex, highlightedIndex };
};

export default useMoveUpAndDown;
