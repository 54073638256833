import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { formatDate } from "../cases/utils";
import ExtendSubscription from "./ExtendSubscription";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'justify',
    width: 500,
  },

}));

let ExpiredAccountLink = (props) => {
  const { title, expiryDate, isAccountManager, isBetaTester } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <a href="#" onClick={handleOpen}>
        {title}
      </a>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Votre abonnement à Quantum a expiré</h2>
            <p>Votre abonnement à Quantum a pris fin{expiryDate ? ` le ${formatDate(expiryDate, 0, true)}` : ""}.</p>
            {(isAccountManager || isBetaTester) ?
              <>
                <p>Vous pouvez prolonger votre abonnement en cliquant directement sur le bouton ci-dessous.</p>
                <div align="center"><ExtendSubscription active={true} /></div>
              </> :
              <p>Pour prolonger votre abonnement, veuillez vous adresser à l'administrateur de votre compte Quantum.</p>
            }
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default ExpiredAccountLink;

