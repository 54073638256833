import React, { Fragment, memo, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { arrayPush, change, Field, FieldArray, FormSection, getFormMeta, getFormValues } from "redux-form";
import AmountField from "../../fields/AmountField";
import { itemTypes } from "../itemTypes";
import RenderField from "../../fields/RenderField";
import RenderLossOfOpportunity from "./renderLossOfOpportunity";
import RenderTPP from "./renderTPP";
import AnnuityCapitalisation from "./AnnuityCapitalisation";
import RenderActionsCol from "./renderActionsCol";
import {
  getLineAmount,
  getWorkIncomeTotal,
  lineAmountAfterLossOfOpportunity,
  lineCapitalisedAmount,
  lineVictimDiscountedAmount,
} from "../calculations";
import { getPgpaRemainder } from "../calculations/PGP"
import BtnInputAmount from "../../buttons/BtnInputAmount";
import NumberFormat from "react-number-format";
import LineSummary from "./renderLineSummary";
import DisplayDiscountingForm from "../../discounting/DisplayDiscountingForm";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { getLineVictimAmountCalculated } from "../calculations_single";
import useMoveUpAndDown from './useMoveUpAndDown';
import { MoveDown } from "./movableIcon";
import { MoveUp } from "./movableIcon";
import { getOverlapStatus } from '../../fields/checkOverlappingPeriods';

const ItemTableBody = props => {
  const { fields, item, victim, values, dispatch, meta, entry, itemId, editAllDFT0, editAllTPT0periodRate, editAllTPT0daysPerYear, editAllTPF0periodRate, editAllTPF0daysPerYear } = props;
  const colSpan = itemTypes.get(item.itemType).length;
  let changeCheckArray = [values[fields.name], victim, null];
  const { moveFieldDown, moveFieldUp, handleEnterLine, handleLeaveLine, hoveredIndex, highlightedIndex } = useMoveUpAndDown(fields);

  if (values["TPP"] && values["TPP"][fields.name]) changeCheckArray[2] = values["TPP"][fields.name];
  let tppCaption = "";
  if (fields.name.indexOf("DNAPRDI") === 0 || fields.name.indexOf("AUT") === 0)
    tppCaption = "le préjudice"
  else
    tppCaption = "la dépense"
  tppCaption += " n° "

  useEffect(() => {
    if (fields.length === 0) {
      let firstLine = {};
      if (fields.name.indexOf("PGP") === 0) {
        firstLine.durationType = firstLine.incomeType = "months";
        if (fields.name.indexOf("PGPF1") === 0)
          firstLine.durationType = firstLine.incomeType = "years";
      }
      // if ((fields.name.indexOf("PGPF0") === 0 || fields.name.indexOf("TPF0") === 0) && victim && victim.consolidationDate) {
      //     let consolidationDate = new Date(victim.consolidationDate.split("-")[0],parseInt(victim.consolidationDate.split("-")[1])-1,victim.consolidationDate.split("-")[2]);
      //     firstLine.startDate = formatDateField(consolidationDate.getTime() + 86400000);
      // }
      if (fields.name.indexOf("TPF") === 0 || fields.name === "TPT0") {
        firstLine.periodType = firstLine.durationType = "days";
        firstLine.daysPerYear = 365;
        firstLine.weeksPerYear = 52;
        firstLine.monthsPerYear = 12;
      }

      if (item.annuityCapitalisation === true) {
        firstLine.annuityPercentage = 100;
        firstLine.annuityPeriodicity = 12;
      }
      fields.push(firstLine);
    }
  }, changeCheckArray);

  const addTPPLine = index => {
    let newLine = {};
    dispatch(arrayPush("caseDataForm", `${fields.name}[${index}].tpp`, newLine));
  };

  const removeLine = index => {
    fields.remove(index);
  };

  const removeDiscounting = (line, index) => {
    let newLineValues = line;
    delete newLineValues.discounting;
    dispatch(change("caseDataForm", `${fields.name}[${index}]`, newLineValues));
  }
    const displayWarning = (entry, values, index) => {
        const types = ["DFT", "TPT", "PGPF", "TPF", "PGPA"];
        return types.some(type => getOverlapStatus(entry, values, index, type));
    };
   
  return (
    <tbody onMouseLeave={handleLeaveLine} >
      <Fragment>
        {fields.map((line, index) => (
          <Fragment key={index}>
            <tr key={index}
              style={{ position: "relative" }}
              className={index === highlightedIndex ? "blink" : ""}
              onMouseEnter={() => handleEnterLine(index)}
            >
              <th scope="row" className="col-num align-middle d-flex justify-content-between">
                {item.multiLine && 
                <>
                  {hoveredIndex === index && <div style={{ position: "absolute", left: "-20px", top: "-10px" }}>
                    <MoveUp
                      moveFieldUp={() => moveFieldUp(index)}
                      disabled={index === 0}
                    />
                    <MoveDown
                      moveFieldDown={() => moveFieldDown(index)}
                      disabled={index === fields.length - 1}
                    />
                  </div>}
                  {<span className="d-flex justify-content-center align-items-center" style={{ position: 'relative' }}>{index + 1}.</span>}
                </>
                }
              </th>
              {itemTypes.get(item.itemType).length === 0 ? (
                <td />
              ) : (
                itemTypes.get(item.itemType).map((field, k) => (
                  <td key={k} className={`main-line${field.className ? field.className : ""}`}>
                    <div className="input-group input-group-sm flex-nowrap">
                      <RenderField
                        entry={fields.name}
                        item={item}
                        line={line}
                        index={index}
                        field={field}
                        values={values}
                        victim={victim}
                        editAllDFT0={editAllDFT0}
                        editAllTPT0periodRate={editAllTPT0periodRate}
                        editAllTPT0daysPerYear={editAllTPT0daysPerYear}
                        editAllTPF0periodRate={editAllTPF0periodRate}
                        editAllTPF0daysPerYear={editAllTPF0daysPerYear}
                        readOnly={
                          item.computedAmount ? values[fields.name][index].amountDirectInput : false
                        }
                                                displayWarning = {displayWarning(entry, values, index)}
                      />
                    </div>
                  </td>
                ))
              )}
              <td className="col-amount main-line">
                <div className="d-flex">
                  {item.computedAmount && !values[fields.name][index].amountDirectInput ? (
                    <AmountField fieldValue={getLineAmount(values, fields.name, index, item)} />
                  ) : (
                    <Field
                      name={`${line}.lineAmount`}
                      component={AmountField}
                      readOnly={
                        item.computedAmount ? !values[fields.name][index].amountDirectInput : false
                      }
                    />
                  )}
                  {item.computedAmount && (
                    <BtnInputAmount
                      switchInputAmount={() => {
                        dispatch(
                          change(
                            "caseDataForm",
                            `${fields.name}[${index}].lineAmount`,
                            !values[fields.name][index].amountDirectInput
                              ? getLineAmount(values, fields.name, index, item)
                                .toFixed(2)
                                .replace(".", ",")
                              : null,
                          ),
                        );
                        dispatch(
                          change(
                            "caseDataForm",
                            `${fields.name}[${index}].amountDirectInput`,
                            !values[fields.name][index].amountDirectInput,
                          ),
                        );
                      }}
                      amountDirectInput={values[fields.name][index].amountDirectInput}
                    />
                  )}
                  {(item.itemType === "DSA" || fields.name.indexOf("DNAPRDI") === 0 || fields.name.indexOf("PROV") === 0 ||
                                        (item.itemType.indexOf("AUT") === 0 && parseInt(entry.charAt(3)) < 3))
                                        &&
                                        <DisplayDiscountingForm
                                          lineValues={values[fields.name][index]}
                                          amount={Math.max(0,
                                            getLineVictimAmountCalculated(values, fields, index))
                                          }
                                          entry={fields.name}
                                          index={index}
                                        />
                  }
                </div>
              </td>
              <RenderActionsCol
                item={item}
                lineAmount={getLineAmount(values, fields.name, index, item).toFixed(2)}
                line={line}
                removeLine={() => removeLine(index)}
                addTPPLine={() => addTPPLine(index)}
              />
            </tr>
            {values[fields.name][index] && values[fields.name][index].discounting && values[fields.name][index].discounting.indexValue && !values[fields.name][index].amountDirectInput && (!values[fields.name][index].tpp || values[fields.name][index].tpp.length === 0) && (item.itemType === "PGPA") &&
            <tr className="pb">
              <td className="col-num pt-0" />
              <td colSpan={4} className="text-right pt-0">
                <em>
                                        Montant actualisé (total sur la période)</em> :&nbsp;<NumberFormat
                                          displayType="text"
                                          thousandSeparator=" "
                                          className="discountedTotal pr-1"
                                          decimalSeparator=","
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          type="text"
                                          value={values[fields.name][index].discounting.discountedTotal || 0}
                                          suffix=" €"
                />
                <button
                  type="button"
                  className="btn btn-outline-danger btn-sm p-0 ml-1"
                  onClick={() => removeDiscounting(values[fields.name][index], index)}
                  title="Supprimer l'actualisation"
                >
                  <HighlightOffIcon />
                </button>
              </td>
              <td colSpan={3} className="pl-2 pt-0">
                {values[fields.name][index].workIncome && parseFloat(values[fields.name][index].workIncome.replace(",", ".").replace(/\s/g, "")) > 0 &&
                <>
                  <em>
                                                Total à déduire sur la période :</em>&nbsp;<NumberFormat
                                                  displayType="text"
                                                  thousandSeparator=" "
                                                  className="discountedTotal"
                                                  decimalSeparator=","
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  type="text"
                                                  value={getWorkIncomeTotal(values[fields.name][index])}
                                                  suffix=" €"
                  />
                </>
                }
              </td>
              <td className="col-act pt-0" />
            </tr>
            }

            {(item.itemType === "PGPA") && getPgpaRemainder(values[fields.name][index]) < 0 &&
            <tr className="pb">
              <td className="col-num pt-0" />
              <td colSpan={4} className="text-right pt-0">
              </td>
              <td colSpan={3} className="pl-2 pt-0">
                <small className="text-danger">&nbsp;Le montant à déduire est supérieur au revenus de
                                        référence, le reliquat de {Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                }).format(-getPgpaRemainder(values[fields.name][index]))} n'est pas reporté
                                        automatiquement sur les autres PGP.</small>
              </td>
              <td className="col-act pt-0" />
            </tr>
            }

            {item.lossOfOpportunity && item.itemType.indexOf("PGP") !== 0 && (
              <FormSection name={`${line}.lossOfOpportunity`}>
                <RenderLossOfOpportunity
                  colSpan={colSpan}
                  lineAmount={lineAmountAfterLossOfOpportunity(values, fields.name, index, item)}
                  idPrefix={`${line}.lossOfOpportunity`}
                  active={
                    values[fields.name][index].lossOfOpportunity &&
                                        values[fields.name][index].lossOfOpportunity.check
                  }
                />
              </FormSection>
            )}

            {values[fields.name][index].discounting && values[fields.name][index].discounting.indexValue && (fields.name === "AUT2Cap0") &&
            <tr>
              <td className="col-num pt-0 pb-0" />
              <td colSpan={colSpan} className="text-right align-bottom pt-0 pb-0">
                <em>Préjudice annuel actualisé de la victime</em>
              </td>
              <td className="col-amount text-left align-bottom pt-0 pb-0">
                <AmountField readOnly
                  fieldValue={values[fields.name][index].lineAmount ? parseFloat(values[fields.name][index].lineAmount.replace(",", ".").replace(/\s/g, "")) * values[fields.name][index].discounting.indexValue : 0}
                />
              </td>
              <td className="col-act pt-0 pb-0">
                <button
                  type="button"
                  className="btn btn-outline-danger btn-sm p-0"
                  onClick={() => removeDiscounting(values[fields.name][index], index)}
                  title="Supprimer l'actualisation"
                >
                  <HighlightOffIcon />
                </button>
              </td>
            </tr>
            }
            {values[fields.name][index].discounting && values[fields.name][index].discounting.indexValue && !values[fields.name][index].amountDirectInput && (item.itemType === "PGPFProj") && values[fields.name][index].periodRate &&
            <tr>
              <td className="col-num pt-0 pb-0" />
              <td className="text-right pt-0">
                <em>Montant actualisé</em> :&nbsp;<NumberFormat
                  displayType="text"
                  thousandSeparator=" "
                  className="discountedTotal pr-1"
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={true}
                  type="text"
                  value={parseFloat(values[fields.name][index].periodRate.replace(",", ".").replace(/\s/g, "")) * values[fields.name][index].discounting.indexValue}
                  suffix=" €"
                />
                <button
                  type="button"
                  className="btn btn-outline-danger btn-sm p-0 ml-1"
                  onClick={() => removeDiscounting(values[fields.name][index], index)}
                  title="Supprimer l'actualisation"
                >
                  <HighlightOffIcon />
                </button>
              </td>
              <td colSpan={colSpan}></td>
              <td className="col-act"></td>
            </tr>
            }

            {values[fields.name][index].tpp &&
                            values[fields.name][index].tpp.length > 0 &&
                            fields.name.indexOf("PROV") !== 0 && (
                            <FieldArray
                name={`${line}.tpp`}
                colSpan={colSpan}
                line={line}
                component={RenderTPP}
                entry={fields.name}
                lineIndex={index}
                tppValues={values[fields.name][index].tpp}
                entryMeta={meta[fields.name]}
                annCap={item.annuityCapitalisation}
                caption={(fields.name.indexOf("AUT") === 0 && values[fields.name][index].label && values[fields.name][index].label.trim().length > 0) ? values[fields.name][index].label : tppCaption + (index + 1)}
                lineAmount={lineAmountAfterLossOfOpportunity(values, fields.name, index, item)}
              />
            )}
            {(!item.annuityCapitalisation && item.multiLine) && (
              <LineSummary
                colSpan={colSpan}
                values={values}
                entry={fields.name}
                lineIndex={index}
                item={item}
                victim={victim}
              />
            )}
            {item.annuityCapitalisation && (
              <AnnuityCapitalisation
                colSpan={colSpan}
                item={item}
                line={line}
                lineIndex={index}
                entry={fields.name}
                values={values}
                victim={victim}
                meta={meta}
                lineAmount={Math.max(
                  lineVictimDiscountedAmount(values, fields.name, index, item, victim),
                  0,
                )}
                lineCapitalisedAmount={lineCapitalisedAmount(
                  values,
                  fields.name,
                  index,
                  item,
                  victim,
                )}
              />
            )}
          </Fragment>
        ))}
      </Fragment>
    </tbody>
  );
};

ItemTableBody.propTypes = {
  fields: PropTypes.object,
  item: PropTypes.object,
  victim: PropTypes.object,
  values: PropTypes.object,
  meta: PropTypes.object,
  dispatch: PropTypes.func,
  entry: PropTypes.string,
  itemId: PropTypes.number,
};

const mapStateToProps = state => ({
  values: getFormValues("caseDataForm")(state),
  meta: getFormMeta("caseDataForm")(state),
})

function memoCompare(prev, next) {
  const prevStr = JSON.stringify(prev, null, " ")
  const nextStr = JSON.stringify(next, null, " ")

  return prevStr == nextStr
}

export default connect(mapStateToProps)(memo(ItemTableBody, memoCompare));




