export const legalInterestRates = [
    { startDate: '1989-09-06', endDate: '1989-12-31', individual: 7.82, professional: 7.82, sourceLbl: "Décret n°89-622 du 6 septembre 1989 FIXANT LE TAUX DE L'INTERET LEGAL JUSQU'AU 31-12-1989", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000700387" },
    { startDate: '1990-01-01', endDate: '1990-12-31', individual: 9.36, professional: 9.36, sourceLbl: "Décret du 4 janvier 1990 fixant le taux de l'intérêt légal du 1er janvier 1990 au 31 décembre 1990", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000342448" },
    { startDate: '1991-01-01', endDate: '1991-12-31', individual: 10.26, professional: 10.26, sourceLbl: "Décret n°91-131 du 2 février 1991 FIXANT LE TAUX DE L'INTERET LEGAL DU 01-01-1991 AU 31-12-1991", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000522806" },
    { startDate: '1992-01-01', endDate: '1992-12-31', individual: 9.69, professional: 9.69, sourceLbl: "Décret du 5 mars 1992 fixant le taux de l'intérêt légal pour l'année 1992", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000522806" },
    { startDate: '1993-01-01', endDate: '1993-12-31', individual: 10.40, professional: 10.40, sourceLbl: "Décret no 92-1361 du 24 décembre 1992 fixant le taux de l'intérêt légal pour l'année 1993", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000360092" },
    { startDate: '1994-01-01', endDate: '1994-12-31', individual: 8.40, professional: 8.40, sourceLbl: "Décret no 94-179 du 23 février 1994 fixant le taux de l'intérêt légal pour l'année 1994", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000181839" },
    { startDate: '1995-01-01', endDate: '1995-12-31', individual: 5.82, professional: 5.82, sourceLbl: "Décret no 95-76 du 23 janvier 1995 fixant le taux de l'intérêt légal pour l'année 1995", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000368768" },
    { startDate: '1996-01-01', endDate: '1996-12-31', individual: 6.65, professional: 6.65, sourceLbl: "Décret no 96-105 du 8 février 1996 fixant le taux de l'intérêt légal pour l'année 1996", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000374728" },
    { startDate: '1997-01-01', endDate: '1997-12-31', individual: 3.87, professional: 3.87, sourceLbl: "Décret no 97-115 du 10 février 1997 fixant le taux de l'intérêt légal pour l'année 1997", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000380859" },
    { startDate: '1998-01-01', endDate: '1998-12-31', individual: 3.36, professional: 3.36, sourceLbl: "Décret no 98-62 du 2 février 1998 fixant le taux de l'intérêt légal pour l'année 1998", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000388148" },
    { startDate: '1999-01-01', endDate: '1999-12-31', individual: 3.47, professional: 3.47, sourceLbl: "Décret no 99-71 du 3 février 1999 fixant le taux de l'intérêt légal pour l'année 1999", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000743160" },
    { startDate: '2000-01-01', endDate: '2000-12-31', individual: 2.74, professional: 2.74, sourceLbl: "Décret n° 2000-133 du 16 février 2000 fixant le taux de l'intérêt légal pour l'année 2000", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000762876" },
    { startDate: '2001-01-01', endDate: '2001-12-31', individual: 4.26, professional: 4.26, sourceLbl: "Décret no 2001-138 du 12 février 2001 fixant le taux de l'intérêt légal pour l'année 2001", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000588846" },
    { startDate: '2002-01-01', endDate: '2002-12-31', individual: 4.26, professional: 4.26, sourceLbl: "Décret n° 2002-159 du 8 février 2002 fixant le taux de l'intérêt légal pour l'année 2002", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000410469" },
    { startDate: '2003-01-01', endDate: '2003-12-31', individual: 3.29, professional: 3.29, sourceLbl: "Décret n° 2003-201 du 10 mars 2003 fixant le taux de l'intérêt légal pour l'année 2003", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000600771" },
    { startDate: '2004-01-01', endDate: '2004-12-31', individual: 2.27, professional: 2.27, sourceLbl: "Décret du 13 février 2004 fixant le taux de l'intérêt légal pour l'année 2004", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000432560" },
    { startDate: '2005-01-01', endDate: '2005-12-31', individual: 2.05, professional: 2.05, sourceLbl: "Décret n° 2005-130 du 10 février 2005 fixant le taux de l'intérêt légal pour l'année 2005", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000606684" },
    { startDate: '2006-01-01', endDate: '2006-12-31', individual: 2.11, professional: 2.11, sourceLbl: "Décret n° 2006-117 du 31 janvier 2006 fixant le taux de l'intérêt légal pour l'année 2006", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000634679" },
    { startDate: '2007-01-01', endDate: '2007-12-31', individual: 2.95, professional: 2.95, sourceLbl: "Décret n° 2007-217 du 19 février 2007 fixant le taux de l'intérêt légal pour l'année 2007", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000000273659" },
    { startDate: '2008-01-01', endDate: '2008-12-31', individual: 3.99, professional: 3.99, sourceLbl: "Décret n° 2008-166 du 21 février 2008 fixant le taux de l'intérêt légal pour l'année 2008", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000018158311" },
    { startDate: '2009-01-01', endDate: '2009-12-31', individual: 3.79, professional: 3.79, sourceLbl: "Décret n° 2009-138 du 9 février 2009 fixant le taux de l'intérêt légal pour l'année 2009", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000020237409" },
    { startDate: '2010-01-01', endDate: '2010-12-31', individual: 0.65, professional: 0.65, sourceLbl: "Décret n° 2010-127 du 10 février 2010 fixant le taux de l'intérêt légal pour l'année 2010", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000021809326" },
    { startDate: '2011-01-01', endDate: '2011-12-31', individual: 0.38, professional: 0.38, sourceLbl: "Décret n° 2011-137 du 1er février 2011 fixant le taux de l'intérêt légal pour l'année 2011", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000023502977" },
    { startDate: '2012-01-01', endDate: '2012-12-31', individual: 0.71, professional: 0.71, sourceLbl: "Décret n° 2012-182 du 7 février 2012 fixant le taux de l'intérêt légal pour l'année 2012", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000025338835" },
    { startDate: '2013-01-01', endDate: '2013-12-31', individual: 0.04, professional: 0.04, sourceLbl: "Décret n° 2013-178 du 27 février 2013 fixant le taux de l'intérêt légal pour l'année 2013", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000027119603" },
    { startDate: '2014-01-01', endDate: '2014-12-31', individual: 0.04, professional: 0.04, sourceLbl: "Décret n° 2014-98 du 4 février 2014 fixant le taux de l'intérêt légal pour l'année 2014", sourceUrl: "https://www.legifrance.gouv.fr/loda/id/JORFTEXT000028565720" },
    { startDate: '2015-01-01', endDate: '2015-06-30', individual: 4.06, professional: 0.93, sourceLbl: "Arrêté du 23 décembre 2014 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/loda/id/JORFTEXT000029964913" },
    { startDate: '2015-07-01', endDate: '2015-12-31', individual: 4.29, professional: 0.99, sourceLbl: "Arrêté du 24 juin 2015 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/loda/id/JORFTEXT000030785042" },
    { startDate: '2016-01-01', endDate: '2016-06-30', individual: 4.54, professional: 1.01, sourceLbl: "Arrêté du 23 décembre 2015 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/loda/id/JORFTEXT000031691930" },
    { startDate: '2016-07-01', endDate: '2016-12-31', individual: 4.35, professional: 0.93, sourceLbl: "Arrêté du 24 juin 2016 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/loda/id/JORFTEXT000032770232" },
    { startDate: '2017-01-01', endDate: '2017-06-30', individual: 4.16, professional: 0.9, sourceLbl: "Arrêté du 29 décembre 2016 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000033735433" },
    { startDate: '2017-07-01', endDate: '2017-12-31', individual: 3.94, professional: 0.9, sourceLbl: "Arrêté du 26 juin 2017 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000035058592" },
    { startDate: '2018-01-01', endDate: '2018-06-30', individual: 3.73, professional: 0.89, sourceLbl: "Arrêté du 28 décembre 2017 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000036336005" },
    { startDate: '2018-07-01', endDate: '2018-12-31', individual: 3.6, professional: 0.88, sourceLbl: "Arrêté du 27 juin 2018 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000037111972" },
    { startDate: '2019-01-01', endDate: '2019-06-30', individual: 3.4, professional: 0.86, sourceLbl: "Arrêté du 21 décembre 2018 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000037883620" },
    { startDate: '2019-07-01', endDate: '2019-12-31', individual: 3.26, professional: 0.87, sourceLbl: "Arrêté du 26 juin 2019 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/loda/id/JORFTEXT000038689341" },
    { startDate: '2020-01-01', endDate: '2020-06-30', individual: 3.15, professional: 0.87, sourceLbl: "Arrêté du 23 décembre 2019 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/loda/id/JORFTEXT000039667408" },
    { startDate: '2020-07-01', endDate: '2020-12-31', individual: 3.11, professional: 0.84, sourceLbl: "Arrêté du 15 juin 2020 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000042007291" },
    { startDate: '2021-01-01', endDate: '2021-06-30', individual: 3.14, professional: 0.79, sourceLbl: "Arrêté du 21 décembre 2020 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000042738880" },
    { startDate: '2021-07-01', endDate: '2021-12-31', individual: 3.12, professional: 0.76, sourceLbl: "Arrêté du 16 juin 2021 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000043699419" },
    { startDate: '2022-01-01', endDate: '2022-06-30', individual: 3.13, professional: 0.76, sourceLbl: "Arrêté du 26 décembre 2021 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000044571483" },
    { startDate: '2022-07-01', endDate: '2022-12-31', individual: 3.15, professional: 0.77, sourceLbl: "Arrêté du 27 juin 2022 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000046003556" },
    { startDate: '2023-01-01', endDate: '2023-06-30', individual: 4.47, professional: 2.06, sourceLbl: "Arrêté du 26 décembre 2022 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000046829005" },
    { startDate: '2023-07-01', endDate: '2023-12-31', individual: 6.82, professional: 4.22, sourceLbl: "Arrêté du 27 juin 2023 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000047752803" },
    { startDate: '2024-01-01', endDate: '2024-06-30', individual: 8.01, professional: 5.07, sourceLbl: "Arrêté du 21 décembre 2023 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000048659680" },
    { startDate: '2024-07-01', endDate: '2024-12-31', individual: 8.16, professional: 4.92, sourceLbl: "Arrêté du 26 juin 2024 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000049797659" },
    { startDate: '2025-01-01', endDate: '2025-06-30', individual: 7.21, professional: 3.71, sourceLbl: "Arrêté du 17 décembre 2024 relatif à la fixation du taux de l'intérêt légal", sourceUrl: "https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000050793726" },
];



export const paosFigures = [
    { startDate: '2019-01-01', endDate: '2019-12-31', individual: 0, professional: 0.0714 /* monthly rate, in percent */, sourceLbl: '', sourceUrl:'' },
    { startDate: '2020-01-01', endDate: '2020-12-31', individual: 0, professional: 0.0722 /* monthly rate, in percent */, sourceLbl: '', sourceUrl:'' },
    { startDate: '2021-01-01', endDate: '2021-12-31', individual: 0, professional: 0.0656 /* monthly rate, in percent */, sourceLbl: '', sourceUrl:'' },
    { startDate: '2022-01-01', endDate: '2022-12-31', individual: 0, professional: 0.0631 /* monthly rate, in percent */, sourceLbl: '', sourceUrl:'' },
    { startDate: '2023-01-01', endDate: '2023-12-31', individual: 0, professional: 0.1701 /* monthly rate, in percent */, sourceLbl: '', sourceUrl:'' },
    { startDate: '2024-01-01', endDate: '2024-12-31', individual: 0, professional: 0.4130 /* monthly rate, in percent */, sourceLbl: '', sourceUrl:'' },
    { startDate: '2025-01-01', endDate: '2025-12-31', individual: 0, professional: 0.3040 /* monthly rate, in percent */, sourceLbl: '', sourceUrl:'' },
]