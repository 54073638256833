import {
  CASES_LOADING,
  CASES_RECEIVED,
  DELETE_CASE,
  ADD_CASE,
  GET_CASE,
  DUPLICATE_CASE,
  UPDATE_CASE,
  IS_CASE_LIST,
  IS_NOT_CASE_LIST,
  UPDATE_CASE_VERSION, UPDATE_CASE_TITLE,
} from "../actions/types";

const initialState = {
  cases: [],
  isLoading: false,
  // case: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CASES_LOADING:
      return {
        ...state,
        isLoading: true,
        case: {},
      };
    case CASES_RECEIVED:
      return {
        ...state,
        cases: action.payload,
        isLoading: false,
      };
    case DELETE_CASE:
      return {
        ...state,
        cases: state.cases.filter(c => c.id !== action.payload),
      };
    case GET_CASE:
      return {
        ...state,
        case: action.payload,
        isLoading: false,
      };
    case IS_CASE_LIST:
      return {
        ...state,
        isCaseList: true,
      };
    case IS_NOT_CASE_LIST:
      return {
        ...state,
        isCaseList: false,
      };
    case UPDATE_CASE:
      return {
        ...state,
        case: action.payload,
      };
    case UPDATE_CASE_TITLE:
      return {
        ...state,
        case: { ...state.case, title: action.payload },
      };
    case UPDATE_CASE_VERSION:
      return {
        ...state,
        case: { ...state.case, version: action.payload },
      };
    case ADD_CASE:
      return {
        ...state,
        cases: [...state.cases, action.payload],
        case: action.payload,
      };
    case DUPLICATE_CASE:
      return {
        ...state,
        cases: [...state.cases, action.payload],
        case: action.payload,
      };
    default:
      return state;
  }
}



