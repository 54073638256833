import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { EventAvailable } from '@material-ui/icons';
import { useDispatch, connect } from "react-redux";
import { change } from "redux-form";
import { Field } from "redux-form";
import { refIndexes, lastYear, firstYear } from './constants';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'left',
    maxWidth:700,
    minWidth:700,
  },

}));

const years = Array.from({ length: lastYear[0] - firstYear + 1 }, (_, i) => firstYear + i).reverse();

const frenchMonths = [
  [1,"janvier"],
  [2,"février"],
  [3,"mars"],
  [4,"avril"],
  [5,"mai"],
  [6,"juin"],
  [7,"juillet"],
  [8,"août"],
  [9,"septembre"],
  [10,"octobre"],
  [11,"novembre"],
  [12,"décembre"],
]

const refIndexKeys = Object.keys(refIndexes);

function DisplayDiscountingFormDSFPast(props) {

  const { values, entry, index, pastLineIndex, amount, hasTpp } = props;

  const today = new Date();
  const currentMonth = today.getMonth()+1;
  const line = values[entry][index].arr.past[pastLineIndex]

  let defaultIndex = "medical";
  if (entry.indexOf("FLA") === 0)
    defaultIndex = "inflation"
  else if (entry.indexOf("FVA") === 0)
    defaultIndex = "vehicles"

  let discountingStartDate;

  if (line.startDate)
    discountingStartDate = new Date(line.startDate)
  else if (line.endDate)
    discountingStartDate = new Date(line.endDate)
  else
    discountingStartDate = new Date();
    
  let discountingValues = {
    refIndex:defaultIndex,
    startYear:discountingStartDate.getFullYear(),
    startMonth:discountingStartDate.getMonth()+1,
    endYear:lastYear[0],
    endMonth:currentMonth,
  }

  if (line.discounting && line.discounting.indexValue)
    discountingValues = line.discounting

  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [startMonth, setStartMonth] = React.useState(discountingValues.startMonth)
  const [startYear, setStartYear] = React.useState(discountingValues.startYear)
  const [endMonth, setEndMonth] = React.useState(discountingValues.endMonth)
  const [endYear, setEndYear] = React.useState(discountingValues.endYear)
  const [refIndex, setRefIndex] = React.useState(discountingValues.refIndex)

  const handleOpen = () => {
    if (!line.discounting || !line.discounting.indexValue){
      setStartMonth(discountingValues.startMonth)
      setStartYear(discountingValues.startYear)
      dispatch(change("caseDataForm", `${entry}[${index}].arr.past[${pastLineIndex}].discounting`, discountingValues));
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStartMonthChange = (event, newValue) => {
    setStartMonth(newValue)
  }
  const handleStartYearChange = (event, newValue) => {
    setStartYear(newValue)
  }
  const handleEndMonthChange = (event, newValue) => {
    setEndMonth(newValue)
  }
  const handleEndYearChange = (event, newValue) => {
    setEndYear(newValue)
  }
  const handleRefIndexChange = (event, newValue) => {
    setRefIndex(newValue)
  }

  let startIndexValue = refIndexes[refIndex]
    .data
    .filter(ent => ent[0] === parseInt(startYear) && ent[1] === parseInt(startMonth))
    .map(entry => entry[2])[0] || 1;
    
  let endIndexValue = refIndexes[refIndex]
    .data
    .filter(ent => ent[0] === parseInt(endYear) && ent[1] === parseInt(endMonth))
    .map(entry => entry[2])[0] || 1;

  let indexValue = endIndexValue / startIndexValue;

  let discountedAmount = amount * indexValue;

  const addDiscounting = () => {
    let discounting = {
      refIndex: refIndex,
      startYear: startYear,
      startMonth: startMonth,
      endYear: endYear,
      endMonth: endMonth,
      startIndexValue: startIndexValue,
      endIndexValue: endIndexValue,
      indexValue: indexValue,
    }
    handleClose();
    dispatch(change("caseDataForm", `${entry}[${index}].arr.past[${pastLineIndex}].discounting`, discounting));
  }

  return (
    <div onClick={((e) => e.stopPropagation())}>
      <button
        type="button"
        className="btn btn-outline-success btn-sm p-0 ml-1"
        onClick={()=>handleOpen()}
        title={line.discounting ? "Modifier l'actualisation":"Actualiser le montant"}
      >
        <EventAvailable />
      </button>      
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <fieldset className={classes.paper}>
            <h2 id="transition-modal-title" style={{ textAlign:'center' }}>Actualisation d'un montant&nbsp;<small style={{ fontSize:"0.8rem" }}><i>(version bêta)</i></small></h2>
            <div>
              <strong>Montant à actualiser :&nbsp;<NumberFormat
                displayType="text"
                thousandSeparator=" "
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                type="text"
                value={amount}
                suffix=" €"
              />
              </strong>{hasTpp && <i> (montant du préjudice après paiement(s) des tiers payeurs, qui ne sont pas actualisés)</i>}
            </div>
            <div className='form-group input-group pt-2'>
              <div className='row align-items-center' style={{ marginLeft: "0px" }}>
                <label htmlFor="refIndex" className="control-label mb-0">Choisir l'indice d'actualisation :</label>
                <div className='d-flex input-group-sm ml-1'>
                  <Field
                    name={`${entry}[${index}].arr.past[${pastLineIndex}].discounting.refIndex`}
                    id="refIndex"
                    component="select"
                    className="custom-select form-control"
                    style={{ fontSize: "0.8125rem" }}
                    onChange={handleRefIndexChange}
                  >
                    {refIndexKeys.map(refIndexKey =>
                      <option value={refIndexKey} key={refIndexKey}>{refIndexes[refIndexKey].label}</option>,
                    )}
                  </Field>
                </div>
              </div>
            </div>
            <div className='d-flex'>
              <div className='form-group row align-items-center col-4 pl-0' style={{ marginLeft: "0px" }}>
                <label htmlFor="startYear" className="control-label mb-0">Année de départ :</label>
                <div className='d-flex input-group-sm ml-1'>
                  <Field
                    name={`${entry}[${index}].arr.past[${pastLineIndex}].discounting.startYear`}
                    id="startYear"
                    component="select"
                    style={{ fontSize: "0.8125rem" }}
                    className="custom-select form-control"
                    onChange={handleStartYearChange}
                  >                       
                    {years.map(value =>
                      <option value={value} key={value} default>{value}</option>,
                    )}
                  </Field>
                </div>
              </div>
              <div className='form-group row align-items-center col-4'>
                <label htmlFor="startMonth" className="control-label mb-0">Mois de départ :</label>
                <div className='d-flex input-group-sm ml-1'>
                  <Field
                    name={`${entry}[${index}].arr.past[${pastLineIndex}].discounting.startMonth`}
                    id="startMonth"
                    component="select"
                    style={{ fontSize: "0.8125rem" }}
                    className="custom-select form-control"
                    onChange={handleStartMonthChange}
                  >                            
                    {frenchMonths.map((value) =>
                      <option value={value[0]} key={value[0]}>{value[1]}</option>,
                    )}
                  </Field>
                </div>
              </div>
              <div className='form-group col-4 mt-1'>
                <div className="control-label text-right">Valeur de l'indice :&nbsp;<strong>
                  <NumberFormat
                    displayType="text"
                    thousandSeparator=" "
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={true}
                    type="text"
                    value={startIndexValue}
                    suffix={refIndex==='smic' ?" €":""}
                  /></strong>
                </div>
              </div>
            </div>
            <div className='d-flex'>
              <div className='form-group row align-items-center col-4 pl-0' style={{ marginLeft: "0px" }}>
                <label htmlFor="endYear" className="control-label mb-0">Année de fin :</label>
                <div className='d-flex input-group-sm ml-1'>
                  <Field
                    name={`${entry}[${index}].arr.past[${pastLineIndex}].discounting.endYear`}
                    id="endYear"
                    component="select"
                    style={{ fontSize: "0.8125rem" }}
                    className="custom-select form-control"
                    onChange={handleEndYearChange}
                  >
                    {years.map(value =>
                      <option value={value} key={value} default>{value}</option>,
                    )}
                  </Field>
                </div>
              </div>
              <div className='form-group row align-items-center col-4'>
                <label htmlFor="endMonth" className="control-label mb-0">Mois de fin :</label>
                <div className='d-flex input-group-sm ml-1'>
                  <Field
                    name={`${entry}[${index}].arr.past[${pastLineIndex}].discounting.endMonth`}
                    id="endMonth"
                    component="select"
                    style={{ fontSize: "0.8125rem" }}
                    className="custom-select form-control"
                    onChange={handleEndMonthChange}
                  >                            
                    {frenchMonths.map((value) =>
                      <option value={value[0]} key={value[0]}>{value[1]}</option>,
                    )}
                  </Field>
                </div>
              </div>
              <div className='form-group col-4 mt-1'>
                <div className="control-label text-right">Valeur de l'indice :&nbsp;<strong>
                  <NumberFormat
                    displayType="text"
                    thousandSeparator=" "
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={true}
                    type="text"
                    value={endIndexValue}
                    suffix={refIndex==='smic' ?" €":""}
                  />                                     </strong>
                </div>
              </div>

            </div>
            <div className='text-right pb-2'>
              <strong>
                                    Montant actualisé :&nbsp;<NumberFormat
                                      displayType="text"
                                      thousandSeparator=" "
                                      decimalSeparator=","
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      type="text"
                                      value={discountedAmount}
                                      suffix=" €"
                />
              </strong>
            </div>
            <div className='text-right'>
              <button className="btn btn-sm btn-primary mt-auto" onClick={()=>addDiscounting(index)}>Enregistrer l'actualisation</button>
            </div>
            <div className='pt-2' style={{ minHeight:"50px" }}>
              <em>Source : <a href={refIndexes[refIndex].sourceUrl} target="_blank" rel="noopener noreferrer">{refIndexes[refIndex].sourceLabel}</a></em>
            </div>
            <div className='pt-2' style={{ minHeight:"50px" }}>
              <em className="small">Les indices sont actualisés dans Quantum dès leur parution. Pour les indices de prix à la consommation, cette parution est postérieure de quelques jours / semaines par rapport au mois concerné.</em>
            </div>
          </fieldset>
        </Fade>
      </Modal>
    </div>
  );
}


export default connect()(DisplayDiscountingFormDSFPast);
