import React from "react";
import { familyLinkTypes } from "./physical_injury/constants.js";


export function getFrenchDuration(durationType) {
  switch (durationType) {
    case "years":
      return "an";
    case "months":
      return "mois";
    case "weeks":
      return "semaine";
    case "days":
    default:
      return "jour";
  }
}

export function getFrenchDurationAdj(durationType) {
  switch (durationType) {
    case "years":
      return "annuel";
    case "months":
      return "mensuel";
    case "weeks":
      return "hebdomadaire";
    case "days":
    default:
      return "quotidien";
  }
}

export function interval(date1, date2, returnType = "days", includeEndStartDays = true, decimals = 3) {
  // calcule le nombre exact de jours/semaines/mois/années entre 2 dates
  // A faire : prendre en compte le cas où la date de fin est avant la date de début
  let interval = 0

  if (date1 && !date2)
    date2 = date1;
  else if (date2 && !date1)
    date1 = date2;

  const jsDate1 = new Date(date1);
  const jsDate2 = new Date(date2);

  if (!date1 || !date2 || jsDate2 < jsDate1) return interval;
  if (returnType === "days" || returnType === "weeks") {
    let diffDays = 0;
    let oneDay = 24 * 60 * 60 * 1000;
    diffDays = Math.round(Math.abs((jsDate1 - jsDate2) / oneDay));
    includeEndStartDays && ++diffDays; // on ajoute 1 si les jours de début et de fin doivent être inclus

    if (returnType === "days") interval = diffDays;
    else interval = diffDays / 7;
  } else {
    const year1 = jsDate1.getFullYear(),
      month1 = jsDate1.getMonth(),
      day1 = jsDate1.getDate();
    let year2 = jsDate2.getFullYear(),
      month2 = jsDate2.getMonth(),
      day2 = jsDate2.getDate();
    if (!includeEndStartDays) day2 -= 1;
    const nbDaysMonth1 = new Date(year1, month1 + 1, 0).getDate();
    const nbDaysMonth2 = new Date(year2, month2 + 1, 0).getDate();

    let diffYears = year2 - year1; // number of years

    // let diffMonths = diffYears * 12 + month2 - month1 - 1 + (day2 + nbDaysMonth2 - day1 + 1) / nbDaysMonth2;
    let diffMonths = diffYears * 12 + month2 - month1 - 1 + day2 / nbDaysMonth2 + (nbDaysMonth1 - day1 + 1) / nbDaysMonth1;

    if (returnType === "months") interval = diffMonths;
    else interval = diffMonths / 12;
  }

  return parseFloat(interval.toFixed(decimals))
}

export function frenchPeriod(startDate, endDate) {
  let frenchPeriod = "du ";

  if (startDate)
  {
    frenchPeriod += formatDate(startDate);
    if (endDate)
      frenchPeriod += " au " + formatDate(endDate);
  }
  else frenchPeriod += formatDate(endDate);

  return frenchPeriod;
}

export function formatDate(startDate, addYears = 0, time = false, utc = false) {
  let monthNames = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];
  let date, day, monthIndex, year;
  let formattedDate = "";
  try {
    date = new Date(startDate);
  } catch (_error) {
    console.log("date invalide : " + _error)
  }
  if (date) {
    day = date.getDate();
    monthIndex = date.getMonth();
    year = date.getFullYear();
    if (utc) {
      day = date.getUTCDate();
      monthIndex = date.getUTCMonth();
      year = date.getUTCFullYear();
    }
    if (day === 1) day += "er"
    year += addYears;
    formattedDate = day + " " + monthNames[monthIndex] + " " + year;
    if (time)
      formattedDate +=
        " à " +
        date
          .toTimeString()
          .slice(0, 5)
          .replace(":", "h");
  }

  return formattedDate;
}

export const normalizeAmount = value => {
  if (!value) {
    return value;
  }

  let onlyNums = value
    .replace(/[^\d.]/g, "")
    .replace(/\./, "x")
    .replace(/\./g, "")
    .replace(/x/, ".");

  return onlyNums;
};

export const formatDateField = date => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};


export const getIndirectVictimTitle = (victim, i) => {
  let indirectVictimTitle = "";
  if (victim) {
    if (victim.first_name) indirectVictimTitle = victim.first_name;
    if (victim.last_name) {
      if (indirectVictimTitle) indirectVictimTitle += " ";
      indirectVictimTitle += victim.last_name;
    }
  }
  if (!indirectVictimTitle) indirectVictimTitle = `Victime indirecte n° ${i+1}`;
  indirectVictimTitle += ` (${getIndirectVictimLink(victim)})`;

  return indirectVictimTitle;
}

export const getIndirectVictimLink = (victim, initialCap = false) => {
  let indirectVictimLink = "";
  let gender = 0; // default to male
  if (victim.victimGender === "F") gender = 1;
  if (victim.linkType === "other" && victim.otherLink && victim.otherLink.trim())
    indirectVictimLink = victim.otherLink.trim();
  else if (victim.linkType && familyLinkTypes[victim.linkType])
    indirectVictimLink = familyLinkTypes[victim.linkType][gender];
  else indirectVictimLink += "autre";
  if (initialCap) indirectVictimLink = indirectVictimLink.charAt(0).toUpperCase() + indirectVictimLink.slice(1);

  return indirectVictimLink;
}

export const getIndirectVictimFullName = (victim, i, crop = 0, initialLowerCase = false) => {
  let indirectVictimFullName = "";

  if (victim) {
    if (victim.first_name) indirectVictimFullName = victim.first_name;
    if (victim.last_name) {
      if (indirectVictimFullName) indirectVictimFullName += " ";
      indirectVictimFullName += victim.last_name;
    }
  }
  if (!indirectVictimFullName) {
    indirectVictimFullName = "Victime indirecte n° " + (parseInt(i)+1);
    if (initialLowerCase) indirectVictimFullName = indirectVictimFullName.charAt(0).toLowerCase() + indirectVictimFullName.slice(1);
  }

  if (crop > 0 && indirectVictimFullName.length > crop) indirectVictimFullName = indirectVictimFullName.slice(0, crop) + "..."

  return indirectVictimFullName;
}

// Function to calculate the victim's age and return it in French, even if the birthdate is in the future.
export function calculateAge(birthDate, res) {
    const dob = new Date(birthDate);
    const current = new Date();
    let start;
    let end;
    
    let years = Math.floor(interval(dob, current, "years", false));
    let months = Math.floor(interval(dob, current, "months", false));
    let days = Math.floor(interval(dob, current, "days", false));
    
    if (startLaterThanEndDate(dob,current)){
      res.sign = "-";
      res.color = 'red';
      start = current;
      end = dob;
      years = Math.floor(interval(current, dob, "years", false));
      months = Math.floor(interval(current, dob, "months", false));
      days = Math.floor(interval(current, dob, "days", false));
    }
    else {
      res.sign = "";
      res.color = 'grey';
      start = dob;
      end = current;
    }

    return { years, months, days };
}

export function getAgeInFrench(dateOfBirth) {
  if (!dateOfBirth)
  {
     throw new Error("getAgeInFrench: dateOfBirth is required");
  }
  const res = {}
  let ageString = '';
  try {
    const { years: ageInYears, months: ageInMonths, days: ageInDays } = calculateAge(dateOfBirth, res);
    if (ageInYears >= 1) {
        ageString = `${ageInYears} an${ageInYears >= 2 ? 's' : ''}`;
    } else if (ageInMonths >= 1) {
        ageString = `${ageInMonths} mois`;
    } else {
        ageString = `${ageInDays} jour${ageInDays >= 2 ? 's' : ''}`;
    }

    if (ageInYears === 0 && ageInMonths === 0 && ageInDays === 0) {
      ageString = "0 jour";
    }

  } catch (error) {
    ageString = "Date invalide";
  }
  res.age = ageString;
  return res;
}

export const startLaterThanEndDate = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return false;
  }

  const start = new Date(startDate);
  const end = new Date(endDate);

  if (isNaN(start) || isNaN(end)) {
    console.error("Invalid date(s) detected");
    return false; 
  }
  return start > end; 
};

const labelMapping = {
  'accidentDateInvalid': ["l'accident", "naissance"],
  'deathAccidentDateInvalid': ["décès", "l'accident"],
  'consolidationAccidentDateInvalid': ["consolidation", "l'accident"],
  "consolidationDateInvalid": ["consolidation", "naissance"],
  "deathConsolidationDateInvalid": ["décès", "consolidation"],
  "deathDateInvalid": ["décès", "naissance"]
}

export const ErrMessageType = Object.freeze({
    string: 'string',
    jsx: 'jsx',
  });


export function getErrMsg(isInvalid, invalidType, type, accidentType = 'accident') {
  if (!isInvalid) {
    return null;
  }
  let label1 = labelMapping[invalidType][0];
  let label2 = labelMapping[invalidType][1];
  if (accidentType !== 'accident') {
    label1 = label1.replace("accident", accidentType);
    label2 = label2.replace("accident", accidentType);
  }
  if (type === ErrMessageType.string){
    return `date de ${label1} < date de ${label2}`;
  }
  else if (type === ErrMessageType.jsx){
     return <div className="mt-0 input-group input-group-sm invalid-feedback">{`date de ${label1} < date de ${label2}`}</div>
  }
}
