import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles({
  table: {
    //        minWidth: 650,
  },
});



export default function DisplayTable(props) {
  const { header, rows, caption, firstColumn, damageRate } = props;
  const classes = useStyles();

  const getCellClass = (j) => {
    let className =""
    if (j === 0 && firstColumn)
      className = "firstCol";
    return className;
  }
  
  const getRowClass = (i) => {
    let className =""
        
    if (damageRate && i === (damageRate - 1))
      className = "cellHighlight";
          
    return className;
  }
  
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="simple table">
        {caption && 
        <caption style={{ captionSide: "top", textAlign: "center", height: "4em" }}>
          {caption.map((label, i) => <Fragment key={i}>{label}<br /></Fragment>)}
        </caption>
        }
        <TableHead>
          <TableRow>
            {header.map((label, i) => (
              <TableCell key={i} align="center">{label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow key={i} className={getRowClass(i)}>
              {row.map((value, j) => (
                <TableCell key={j} className={getCellClass(j)} align="center">
                  {value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer >
  );
}
