import React from 'react';
import { Link } from "react-router-dom";


const SmallScreens = () => {
  return (
    <div className="p-5 text-center">
      <p>Dans cette version de Quantum, la création et la consultation des dossiers dans Quantum ne sont pas adaptées aux petits écrans.</p>
      <p>Si ce message s'affiche sur votre écran d'ordinateur, élargissez votre fenêtre de navigateur au maximum.</p>
      <p>Nous vous recommandons d'y accéder à partir d'un ordinateur plutôt que d'une tablette ou d'un téléphone.</p>
      <Link to="/">Retour à la liste des dossiers</Link>
    </div >
  )
}

export default SmallScreens
