var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { formatDateFieldUTC, intervalUTC } from "./utils";
import { paosFigures } from "./constants";
export function calculateInterest(interestData, legalInterestRates) {
    var _a;
    var interestResults = {
        summary: {
            interestsTotal: 0,
            total: 0,
            baseAmount: Number(interestData.baseAmount) || 0
        },
        periods: []
    };
    if (!interestData.startDate || !interestData.endDate || !((_a = interestData.referenceRate) === null || _a === void 0 ? void 0 : _a.selected)) {
        return interestResults;
    }
    var baseAmount = Number(interestData.baseAmount) || 0;
    var referenceRate = interestData.referenceRate;
    // For PAOS case, handle special monthly calculations
    if (referenceRate.selected === 'paos') {
        var startDate_1 = new Date(interestData.startDate + 'T12:00:00.000Z');
        var endDate = new Date(interestData.endDate + 'T12:00:00.000Z');
        // Find the initial rate from paosFigures based on startDate
        var initialRate = paosFigures.find(function (period) {
            var periodStart = new Date(period.startDate + 'T12:00:00.000Z');
            var periodEnd = new Date(period.endDate + 'T12:00:00.000Z');
            return startDate_1.getTime() >= periodStart.getTime() && startDate_1.getTime() <= periodEnd.getTime();
        });
        if (!initialRate) {
            return interestResults;
        }
        // Start from the first month
        var currentDate = new Date(startDate_1.getTime());
        currentDate.setUTCDate(1); // Start of month
        // Ensure end date covers the whole month
        var calculationEnd = new Date(endDate.getTime());
        calculationEnd.setUTCDate(1); // Move to start of month
        calculationEnd.setUTCMonth(calculationEnd.getUTCMonth() + 1); // Move to start of next month
        calculationEnd.setUTCDate(0); // Move to end of original month
        if (currentDate.getTime() >= calculationEnd.getTime()) {
            return interestResults;
        }
        var monthlyRate = initialRate.professional; // Use professional rate from startDate
        var monthCount = 0;
        while (currentDate.getTime() <= calculationEnd.getTime()) {
            var periodEnd = new Date(currentDate.getTime());
            periodEnd.setUTCMonth(periodEnd.getUTCMonth() + 1);
            periodEnd.setUTCDate(0); // Last day of current month
            // Get French month name
            var monthNames = ["janvier", "février", "mars", "avril", "mai", "juin",
                "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
            var monthName = monthNames[currentDate.getUTCMonth()];
            var year = currentDate.getUTCFullYear();
            var baseRate = monthlyRate;
            var appliedRate = 0;
            var interestAmount = 0;
            var comment = "";
            if (monthCount < 3) {
                // First 3 months: no interest
                baseRate = 0;
                comment = "Pas d'intérêts pour ce mois";
                if (monthCount === 2) {
                    // Format deadline date in UTC
                    interestResults.summary.deadline = formatDateFieldUTC(periodEnd);
                }
            }
            else if (monthCount === 3 || monthCount === 4) {
                // 4th month: rate + 50%
                appliedRate = monthlyRate * 1.5;
                interestAmount = Number((baseAmount * appliedRate / 100).toFixed(2));
                comment = new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(baseAmount) +
                    " x " +
                    new Intl.NumberFormat("fr-FR", {
                        style: "percent",
                        minimumFractionDigits: 4,
                        maximumFractionDigits: 4
                    }).format(appliedRate / 100);
                if (monthCount === 3) {
                    interestResults.summary.majoriseStartMonth = monthName;
                    comment += "\nTaux major\u00E9 de 50 % \u00E0 compter du mois d".concat(['a', 'o'].includes(monthName[0]) ? "'" : "e ").concat(monthName, " ").concat(year);
                }
            }
            else {
                // 5th month and beyond: double rate
                if (monthCount === 5)
                    interestResults.summary.doubleStartMonth = monthName;
                appliedRate = monthlyRate * 2;
                interestAmount = Number((baseAmount * appliedRate / 100).toFixed(2));
                comment = new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(baseAmount) +
                    " x " +
                    new Intl.NumberFormat("fr-FR", {
                        style: "percent",
                        minimumFractionDigits: 4,
                        maximumFractionDigits: 4
                    }).format(appliedRate / 100) +
                    (monthCount === 5 ? "\nTaux doubl\u00E9 \u00E0 compter du mois d".concat(['a', 'o'].includes(monthName[0]) ? "'" : "e ").concat(monthName, " ").concat(year) : "");
            }
            interestResults.summary.interestsTotal = Number((interestResults.summary.interestsTotal + interestAmount).toFixed(2));
            interestResults.periods.push({
                startDate: "".concat(monthName, " ").concat(year), // Use French month name instead of date
                endDate: "".concat(monthName, " ").concat(year), // Same month for PAOS
                periodBaseAmount: baseAmount,
                nbDays: undefined, // Hide nbDays column for PAOS
                baseInterestRate: baseRate,
                baseInterestRateSource: "Taux PAOS mensuel de ".concat(monthlyRate, "%"),
                baseInterestRateSourceUrl: '',
                interestRate: appliedRate, // Use modified rate when applicable
                interestAmount: interestAmount,
                cumulativeInterestAmount: interestResults.summary.interestsTotal,
                comment: comment
            });
            monthCount++;
            currentDate.setUTCMonth(currentDate.getUTCMonth() + 1);
            currentDate.setUTCDate(1);
        }
        interestResults.summary.total = Number((baseAmount + interestResults.summary.interestsTotal).toFixed(2));
        return interestResults;
    }
    // Non-PAOS case continues with original logic
    // Add T12:00:00.000Z to ensure dates are interpreted in UTC
    var interestStart = new Date(interestData.startDate + 'T12:00:00.000Z');
    var interestEnd = new Date(interestData.endDate + 'T12:00:00.000Z');
    var currentBaseAmount = baseAmount;
    var accruedInterestSinceLastCompounding = 0;
    var majoriseStartFrom = interestData.majoriseRate && interestData.majoriseStartFrom
        ? new Date(interestData.majoriseStartFrom + 'T12:00:00.000Z')
        : null;
    var anatocismStartFrom = interestData.anatocism && interestData.anatocismStartFrom
        ? new Date(interestData.anatocismStartFrom + 'T12:00:00.000Z')
        : null;
    var multiplyStartFrom = interestData.multiplyRate && interestData.multiplyStartFrom
        ? new Date(interestData.multiplyStartFrom + 'T12:00:00.000Z')
        : null;
    // Determine periods to process
    var processingPeriods = [];
    if (referenceRate.selected === 'custom') {
        // Create a single period covering the entire duration for custom rate
        processingPeriods = [{
                startDate: formatDateFieldUTC(new Date(interestData.startDate + 'T12:00:00.000Z')),
                endDate: formatDateFieldUTC(new Date(interestData.endDate + 'T12:00:00.000Z')),
                individual: 0,
                professional: 0,
                sourceLbl: "Taux personnalisé",
                sourceUrl: ""
            }];
    }
    else {
        // Ensure we're using the raw date strings from legalInterestRates
        processingPeriods = legalInterestRates;
    }
    var majoriseComment = "";
    var anatocismComment = "";
    var multiplyComment = "";
    var majoriseBy = interestData.majoriseBy || { value: 0, unit: 'points' };
    // Handle both new format and legacy format
    var majoriseValue = typeof majoriseBy === 'object' ? majoriseBy.value : parseFloat(majoriseBy.toString());
    var majoriseUnit = typeof majoriseBy === 'object' ? majoriseBy.unit : 'points';
    if (interestData.multiplyRate && interestData.multiplyBy && multiplyStartFrom) {
        var multiplyByStr = new Intl.NumberFormat("fr-FR", {
            style: "decimal",
            maximumFractionDigits: 2
        }).format(interestData.multiplyBy);
        multiplyComment = "Taux d'int\u00E9r\u00EAt de base multipli\u00E9 par ".concat(multiplyByStr, " \u00E0 compter du ").concat(multiplyStartFrom.toLocaleDateString('fr-FR'));
    }
    if (majoriseBy && majoriseStartFrom) {
        var majoriseValueStr = "";
        if (majoriseUnit === 'percent')
            majoriseValueStr = new Intl.NumberFormat("fr-FR", {
                style: "percent",
                maximumFractionDigits: 2
            }).format(majoriseValue / 100);
        else
            majoriseValueStr = new Intl.NumberFormat("fr-FR", {
                style: "decimal",
                maximumFractionDigits: 2
            }).format(majoriseValue) + " point".concat(majoriseValue >= 2 ? "s" : "");
        majoriseComment = "Taux d'int\u00E9r\u00EAt major\u00E9 de ".concat(majoriseValueStr, " \u00E0 compter du ").concat(majoriseStartFrom.toLocaleDateString('fr-FR'));
    }
    if (anatocismStartFrom && anatocismStartFrom.getTime() !== interestStart.getTime())
        anatocismComment = "Les intérêts échus de l'année écoulée s'ajoutent à la créance";
    processingPeriods.forEach(function (period) {
        // Create UTC dates from the YYYY-MM-DD strings
        var periodStart = new Date(period.startDate + 'T12:00:00.000Z');
        var periodEnd = new Date(period.endDate + 'T12:00:00.000Z');
        // Compare using UTC dates
        var start = periodStart.getTime() < interestStart.getTime() ? new Date(interestStart.getTime()) : new Date(periodStart.getTime());
        var end = periodEnd.getTime() > interestEnd.getTime() ? new Date(interestEnd.getTime()) : new Date(periodEnd.getTime());
        if (start.getTime() >= end.getTime())
            return;
        var baseInterestRate = 0;
        var baseInterestRateSource = "";
        var baseInterestRateSourceUrl = "";
        switch (referenceRate.selected) {
            case "individual":
                baseInterestRate = period.individual;
                baseInterestRateSource = period.sourceLbl;
                baseInterestRateSourceUrl = period.sourceUrl;
                break;
            case "professional":
                baseInterestRate = period.professional;
                baseInterestRateSource = period.sourceLbl;
                baseInterestRateSourceUrl = period.sourceUrl;
                break;
            case "custom":
                baseInterestRate = referenceRate.input || 0;
                baseInterestRateSource = period.sourceLbl; // "Taux personnalisé"
                baseInterestRateSourceUrl = period.sourceUrl; // Empty
                break;
        }
        var interestRate = baseInterestRate;
        var events = [];
        if (interestData.majoriseRate && majoriseValue && majoriseStartFrom &&
            majoriseStartFrom.getTime() >= start.getTime() && majoriseStartFrom.getTime() <= end.getTime()) {
            events.push({ date: new Date(majoriseStartFrom.getTime()), type: 'majoration' });
        }
        if (interestData.multiplyRate && interestData.multiplyBy && multiplyStartFrom &&
            multiplyStartFrom.getTime() >= start.getTime() && multiplyStartFrom.getTime() <= end.getTime()) {
            events.push({ date: new Date(multiplyStartFrom.getTime()), type: 'multiplication' });
        }
        if (interestData.anatocism && anatocismStartFrom) {
            var currentAnniversary = new Date(anatocismStartFrom.getTime());
            while (currentAnniversary.getTime() <= end.getTime()) {
                if (currentAnniversary.getTime() >= start.getTime()) {
                    events.push({ date: new Date(currentAnniversary.getTime()), type: 'anatocism' });
                }
                // Handle February 29th special case before adding a year
                if (currentAnniversary.getUTCMonth() === 1 && currentAnniversary.getUTCDate() === 29) {
                    // Next year won't be a leap year, so use Feb 28th
                    currentAnniversary = new Date(Date.UTC(currentAnniversary.getUTCFullYear() + 1, 1, 28));
                }
                else {
                    currentAnniversary.setUTCFullYear(currentAnniversary.getUTCFullYear() + 1);
                }
            }
        }
        var groupedEvents = [];
        if (events.length > 0) {
            events.sort(function (a, b) { return a.date.getTime() - b.date.getTime(); });
            var currentDate = events[0].date;
            var currentTypes = [events[0].type];
            for (var i = 1; i < events.length; i++) {
                var event_1 = events[i];
                if (event_1.date.getTime() === currentDate.getTime()) {
                    currentTypes.push(event_1.type);
                }
                else {
                    groupedEvents.push({ date: currentDate, types: currentTypes });
                    currentDate = event_1.date;
                    currentTypes = [event_1.type];
                }
            }
            groupedEvents.push({ date: currentDate, types: currentTypes });
        }
        var subIntervals = [];
        var currentSubStart = new Date(start);
        var currentEvents = [];
        groupedEvents.forEach(function (group) {
            var eventDate = group.date;
            if (eventDate.getTime() > currentSubStart.getTime() && eventDate.getTime() <= end.getTime()) {
                var newEnd = new Date(eventDate.getTime());
                newEnd.setUTCDate(newEnd.getUTCDate() - 1);
                subIntervals.push({
                    start: new Date(currentSubStart.getTime()),
                    end: newEnd,
                    event: __spreadArray([], currentEvents, true)
                });
                currentEvents = group.types;
                currentSubStart = new Date(eventDate.getTime());
            }
        });
        // Add the final sub-interval
        subIntervals.push({
            start: new Date(currentSubStart.getTime()),
            end: new Date(end.getTime()),
            event: __spreadArray([], currentEvents, true)
        });
        subIntervals.forEach(function (subInterval) {
            var subStart = subInterval.start;
            var subEnd = subInterval.end;
            var periodInterestRate = interestRate;
            if (subStart.getTime() >= subEnd.getTime())
                return;
            var days = intervalUTC(subStart, subEnd);
            var originalBase = currentBaseAmount;
            if (interestData.multiplyRate && interestData.multiplyBy && multiplyStartFrom && subStart.getTime() >= multiplyStartFrom.getTime()) {
                periodInterestRate = Number((baseInterestRate * interestData.multiplyBy).toFixed(2));
            }
            if (interestData.majoriseRate && majoriseValue && majoriseStartFrom && subStart.getTime() >= majoriseStartFrom.getTime()) {
                var rateToModify = periodInterestRate; // Use the potentially multiplied rate
                if (majoriseUnit === 'percent') {
                    var increaseAmount = (rateToModify * majoriseValue) / 100;
                    periodInterestRate = Number((rateToModify + increaseAmount).toFixed(2));
                }
                else {
                    periodInterestRate = Number((rateToModify + majoriseValue).toFixed(2));
                }
            }
            var interestAmount = Number((originalBase * days * periodInterestRate / 36500).toFixed(2));
            interestResults.summary.interestsTotal = Number((interestResults.summary.interestsTotal + interestAmount).toFixed(2));
            accruedInterestSinceLastCompounding += interestAmount;
            var isCompoundingDate = interestData.anatocism && interestData.anatocismStartFrom &&
                events.some(function (event) { return (event.date.getTime() - 86400000 === subEnd.getTime()) &&
                    event.type === 'anatocism'; });
            if (isCompoundingDate) {
                currentBaseAmount = Number((currentBaseAmount + accruedInterestSinceLastCompounding).toFixed(2));
                accruedInterestSinceLastCompounding = 0;
            }
            var comment = new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(originalBase) +
                " x " + days + " jours / 365 x " +
                new Intl.NumberFormat("fr-FR", {
                    style: "percent",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(periodInterestRate / 100);
            // Format dates in YYYY-MM-DD format
            var periodStartDate = formatDateFieldUTC(subStart);
            var periodEndDate = formatDateFieldUTC(subEnd);
            var multiplyStartFromStr = multiplyStartFrom ? formatDateFieldUTC(multiplyStartFrom) : null;
            var majoriseStartFromStr = majoriseStartFrom ? formatDateFieldUTC(majoriseStartFrom) : null;
            if ((subInterval.event.includes('multiplication') ||
                (multiplyStartFromStr && periodStartDate === multiplyStartFromStr && (multiplyStartFrom === null || multiplyStartFrom === void 0 ? void 0 : multiplyStartFrom.getTime()) === interestStart.getTime())) &&
                multiplyComment) {
                comment += "\n" + multiplyComment;
            }
            if ((subInterval.event.includes('majoration') ||
                (majoriseStartFromStr && periodStartDate === majoriseStartFromStr && (majoriseStartFrom === null || majoriseStartFrom === void 0 ? void 0 : majoriseStartFrom.getTime()) === interestStart.getTime())) && // take into account the case where majoration starts from the first day
                majoriseComment) {
                comment += "\n" + majoriseComment;
            }
            if (subInterval.event.includes('anatocism') && anatocismComment) {
                comment += "\n" + anatocismComment;
            }
            interestResults.periods.push({
                startDate: periodStartDate,
                endDate: periodEndDate,
                periodBaseAmount: originalBase,
                nbDays: days,
                baseInterestRate: baseInterestRate,
                baseInterestRateSource: baseInterestRateSource,
                baseInterestRateSourceUrl: baseInterestRateSourceUrl,
                interestRate: periodInterestRate,
                interestAmount: interestAmount,
                cumulativeInterestAmount: interestResults.summary.interestsTotal,
                comment: comment
            });
        });
    });
    interestResults.summary.total = Number((currentBaseAmount + accruedInterestSinceLastCompounding).toFixed(2));
    return interestResults;
}
