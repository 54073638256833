import React, { useState } from 'react';
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const PasswordInitForm = (props) => {
  const { token } = props;
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const submitDisabled = password1 !== password2 || !password1 || password1.length < 8;
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [displayResetLink, setDisplayResetLink] = useState(false);
  const [displaySupport, setDisplaySupport] = useState(false);


  const initPassword = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let password = "";
    if (!submitDisabled) password = password1;

    const body = JSON.stringify({ token, password });

    axios
      .post("/api/auth/password_init", body, config)
      .then(res => {
        if (res.data) {
          setResult("OK");
          localStorage.setItem("token", res.data.token);
        }
      })
      .catch(err => {
        setResult("KO");
        if (err.response.data && err.response.data.status === "notfound") {
          setErrorMsg("Il semble que votre code d'initialisation de mot de passe n'est pas valide ou a expiré. ")
          setDisplayResetLink(true);
          setDisplaySupport(true);
        }
        else if (err.response.data && err.response.data.password && err.response.data.password.length > 0)
          setErrorMsg(err.response.data.password[0].slice(0, -1));
        else {
          setErrorMsg("OUPS ! Une erreur s'est produite, votre mot de passe n'a pas été initialisé. Réessayez ou faites une demande de réinitialisation de mot de passe.");
          setDisplaySupport(true);
        }
      });

  }

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitted(true);
    initPassword();
  }

  return (
    <>
      <h3 className="text-center pt-3">Cr&eacute;ez votre mot de passe</h3>
      {result === "OK" ?
        <div className="pt-3">
          <p><strong>Et voil&agrave; !</strong></p>
          <p>Votre mot de passe a bien &eacute;t&eacute; pris en compte, vous pouvez d&eacute;sormais&nbsp;<a className="btn btn-sm btn-primary" role="button" href="/">acc&eacute;der &agrave; Quantum</a>.</p>
          <p>Créez autant de dossiers que vous le souhaitez.</p>
          <p>Afin de vous faciliter la d&eacute;couverte de Quantum, nous avons ajout&eacute; <strong>un dossier pr&eacute;rempli intitul&eacute; « Exemple de dossier »</strong>, accessible depuis l'interface.</p>
          <p>Ce dossier fictif comprend diff&eacute;rents pr&eacute;judices afin de vous permettre de vous familiariser avec notre solution. Modifiez librement les taux, montants, intitulés, et fusionnez autant que vous le souhaitez !</p>
        </div>
        :
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="pb-2">
              <p>Saisissez un mot de passe contenant au moins 8 caractères&nbsp;: </p>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">Mot de passe</label>
              <div className="col-sm-9">
                <input
                  type="password"
                  className="form-control"
                  name="password1"
                  autoFocus
                  onChange={e => setPassword1(e.target.value)}
                  value={password1}
                  required
                  autoComplete="new-password"
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">Confirmation du mot de passe</label>
              <div className="col-sm-9">
                <input
                  type="password"
                  className="form-control"
                  name="password2"
                  onChange={e => setPassword2(e.target.value)}
                  value={password2}
                  required
                  autoComplete="new-password"
                />
              </div>
            </div>
          </div>
          {result === "KO" &&
          <div className="pb-2">
            <span>{errorMsg}
              {displayResetLink && <Link to="/reinitialisation">Faites une demande de modification de mot de passe</Link>}.<br />
              {displaySupport && <>Si le problème persiste, vous pouvez nous contacter à <a href="mailto:quantum@juri-solutions.fr">quantum@juri-solutions.fr</a>.</>}
            </span>
          </div>
          }
          <div className="form-group">
            {submitted ?
              <button className="btn btn-primary" type="button" disabled>
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                Demande en cours de traitement...
              </button>
              :
              <button className="btn btn-primary" type="submit" disabled={submitDisabled}>
                                Poursuivre
              </button>
            }
          </div>
        </form>
      }
    </>
  )
}

export default connect()(PasswordInitForm);
