import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CalcIcon(props) {
  return (
    <SvgIcon {...props} style={{ fontSize: 24 }} viewBox="0 0 96 96">
      <g>
        <path d="M87,26c0-7.2-5.8-13-13-13H26c-7.2,0-13,5.8-13,13v48c0,7.2,5.8,13,13,13h48c7.2,0,13-5.8,13-13V26z M85,26v23H51V15h23   C80.1,15,85,19.9,85,26z M26,15h23v34H15V26C15,19.9,19.9,15,26,15z M15,74V51h34v34H26C19.9,85,15,80.1,15,74z M74,85H51V51h34v23   C85,80.1,80.1,85,74,85z" />
        <polygon points="40.4,65 32.7,65 32.7,57.4 30.7,57.4 30.7,65 23.1,65 23.1,67 30.7,67 30.7,74.7 32.7,74.7 32.7,67 40.4,67  " />
        <rect x="23.1" y="31.7" width="17.3" height="2" />
        <rect x="58.7" y="71" width="17.3" height="2" />
        <rect x="58.7" y="62" width="17.3" height="2" />
        <polygon points="60.5,38.1 61.9,39.5 67.4,34.1 72.8,39.5 74.2,38.1 68.8,32.7 74.2,27.2 72.8,25.8 67.4,31.3 61.9,25.8 60.5,27.2    65.9,32.7  " />
      </g>
    </SvgIcon>)
}
