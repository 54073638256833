import { TEAM_LOADED, TEAM_LOADING } from "../actions/types";


const initialState = {
  teams: [], isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TEAM_LOADED:
      return {
        ...state, teams: action.payload, isLoading: false,
      };
    case TEAM_LOADING:
      return {
        ...state, isLoading: true, teams: [],
      };
    default:
      return state;
  }
}



