import React, { useState, memo } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import PasswordInitForm from "./PasswordInitForm";
import LegalFooter from "../layout/LegalFooter";

const Verify = memo(props => {
  const transitionTimeout = 750;
  const [email, setEmail] = useState("");
  const [verified, setVerified] = useState(false);
  const [userPassword, setUserPassword] = useState(false);
  const [initPassword, setInitPassword] = useState(false);
  const token = props.match.params.token;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ token });

  axios
    .post("/api/auth/verify", body, config)
    .then(res => {
      if (res.data && res.data.email) {
        setEmail(res.data.email);
        setVerified(true);
        setUserPassword(res.data.user_password);
      }
    })
    .catch(err => console.log(err));


  return (

    <div>
      {email.length > 0 &&
      <div className="col-md-8 m-auto">
        {verified ? <div className="card card-body mt-5">
          <h2 className="text-center">Votre compte est activé !</h2>
          {!userPassword ?
            <>
              <p>Bienvenue !</p>
              <p><strong>Votre identifiant pour accéder à Quantum est {email}.</strong></p>
              <p>Une fois votre mot de passe renseigné, vous pourrez accéder à Quantum à l'adresse <a href="https://quantum.juri-solutions.fr/">https://quantum.juri-solutions.fr/</a>.</p>
              <p>N'hésitez pas à nous contacter en cas de question ou problème, nous sommes à votre disposition pour vous accompagner. Il suffit de cliquer sur le bouton Chat disponible en bas à droite de votre écran ou de nous écrire à <a href="mailto:quantum@juri-solutions.fr">quantum@juri-solutions.fr</a>.</p>
              <PasswordInitForm token={token} />
            </>
            :
            <div className="text-center pt-3">
              <p><strong>Vous avez déjà spécifié un mot de passe pour le compte {email}, vous pouvez <Link to="/identification">vous identifier</Link> avec votre adresse email et votre mot de passe.</strong></p>
              <p>Si vous l'avez oublié (ça arrive...), vous pouvez <Link to="/reinitialisation">réinitialiser votre mot de passe</Link>.</p>
            </div>
          }
        </div>
          :
        <p className="text-danger">
            <strong>Une erreur s'est produite, nous ne sommes pas parvenus à vérifier votre compte.</strong>
          </p>}
      </div>
      }
      <LegalFooter />
    </div >
  )
})

export default Verify;
