import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { videoDemos } from "../constants";
import VideoDemo from "./VideoDemo";
import DisplayCompensationScale from "../../compensation_scales/DisplayCompensationScale";
import { CallSplit } from '@material-ui/icons';
import { incomeLossCalcMethods } from "./household_income/constants";
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from "@material-ui/icons/Help";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useDispatch } from "react-redux";
import { change } from "redux-form";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

function EntryTitle(props) {
  const { entry, minimizeEntry, entries, minimized, victim, entryValues, indirect, setMethodChoiceOpen, incomeLossCalcMethod, indirectVictims } = props;

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let entryTitle = entry.label;
  let additionalTitle = "";
  let anchorClassName = "anchor"
  if (indirect) anchorClassName += " indirect"

  entry.additionalLabel && (entryTitle += " " + entry.additionalLabel);
  (entry.abbr || entry.additionalLabel2) &&
    (additionalTitle = (
      <span className="abbreviation">
        &nbsp;({entry.abbr}
        {entry.additionalLabel2})
      </span>
    ));

  const handleConfirm = () => {
    dispatch(
      change(
        "caseDataForm",
        "incomeLossCalcMethod",
        "",
      ),
    );
    if (indirectVictims)
      indirectVictims.forEach((victim, i) => {
        dispatch(
          change(
            "caseDataForm",
            `indirectVictims[${i}].caseData.PRev0`,
            [],
          ),
        )
      },
      )

    // supprimer les données des PRev de toutes les victimes indirectes

    setOpen(false)
  }
  
  return (
    <Fragment>
      {entry.parentId === 0 && isNaN(entry.id) ? (
        <h3 className="display-4 card-header entry-title" onClick={() => minimizeEntry()}>
          <a className={anchorClassName} id={entry.id}></a>
          {entryTitle}
          {additionalTitle}
        </h3>
      ) : isNaN(entry.id) ? (
        <h4 className="display-5 card-header entry-title" onClick={() => minimizeEntry()}>
          <div className="d-flex">
            <a className={anchorClassName} id={entry.id}></a>
            <span className="align-self-center">{entryTitle}{additionalTitle}</span>
            {!minimized && videoDemos[entry.id] &&
              <VideoDemo entryId={entry.id} entryTitle={entry.label} />
            }
            {entry.id === "PRev" && !minimized && 
              <div className="ml-2" onClick={((e) => e.stopPropagation())}>
                {(incomeLossCalcMethod && incomeLossCalcMethods[incomeLossCalcMethod]) ? 
                  <div className="ml-4">
                    <small>Méthode de calcul : {incomeLossCalcMethods[incomeLossCalcMethod].label.toLowerCase()}</small>        
                    <Tooltip title={incomeLossCalcMethods[incomeLossCalcMethod].description} leaveDelay={200}>
                      <HelpIcon
                        fontSize="small"
                        variant="contained"
                        className="text-secondary smallIcon"
                      />
                    </Tooltip>
                    <button
                      type="button"
                      className="btn btn-link btn-sm p-0 ml-2"
                      style={{ color:"#999" }}
                      title="Supprimer cette méthode de calcul"
                      onClick={handleClickOpen}
                    >
                      <HighlightOffIcon />
                    </button>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">Souhaitez-vous vraiment supprimer la méthode de calcul {incomeLossCalcMethods[incomeLossCalcMethod].shortLabel} ?</DialogTitle>
                      <DialogContent>
                        <p className="font-weight-bold">Attention : la suppression de la méthode de calcul entraînera la suppression des calculs déjà réalisés pour le préjudice économique de l'ensemble des victimes par ricochet du foyer.</p>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary" size="small" title="Supprimer le dossier">
                            Annuler
                        </Button>
                        <Button onClick={() => handleConfirm()} variant="contained" color="secondary" size="small" startIcon={<HighlightOffIcon />} autoFocus>
                            Confirmer la suppression
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                  :
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary p-1"
                    onClick={()=>setMethodChoiceOpen(true)}
                    title="Sélectionner la méthode de calcul de la perte de revenus des proches"
                  >
                    <CallSplit />&nbsp; Sélectionner la méthode de calcul
                  </button>}
              </div>
            }
            {!minimized && (entry.id === "SE" || entry.id === "PEP" || entry.id === "PSUF") &&
              <DisplayCompensationScale
                entryId={entry.id}
                entryTitle={entryTitle}
                victim={victim}
                entryValues={entryValues}
              />
            }
          </div>
        </h4>
      ) : (
        entry.parentId !== 0 && (
          <h3 className="display-4">
            <a className={anchorClassName} id={entry.id}></a>
            {entries.filter(e => e.id === entry.parentId).map(e => e.label) + " "}
            {entryTitle.toLowerCase()}
            {additionalTitle}
          </h3>
        )
      )}
    </Fragment>
  );
}

EntryTitle.propTypes = {
  entry: PropTypes.object,
  copyData: PropTypes.func,
  entries: PropTypes.array,
  minimizeEntry: PropTypes.func,
  minimized: PropTypes.bool,
  setMethodChoiceOpen: PropTypes.func,
  incomeLossCalcMethod: PropTypes.string,
  indirectVictims: PropTypes.array,
};



export default EntryTitle;
