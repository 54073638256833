import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { LoadingComponent } from "./LoadingComponent";
// import PropTypes from "prop-types";

const PrivateRoute = ({ component: Component, auth, ...rest }) => {

  const isAuthenticated = auth && auth.isAuthenticated;
  /* */
  /* Set the page title for authenticated users */
  /* */
  /* The default page title is set in index.html */
  useEffect(() => {
    if (isAuthenticated) {
      document.title = 'Jurisolutions - Gestion des dossiers';
    }
  }, [isAuthenticated]);

  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isLoading) {
          return <LoadingComponent/>;
        }
        else if (!auth.isAuthenticated) {
          return <Redirect to="/identification" />;
        }
        else {
          return <Component {...props} />;
        }
      }}
    />
  )
};

// PrivateRoute.propTypes = {
//   auth: PropTypes.object
//   component: PropTypes.object
// };

const mapStateToProps = state => ({ auth: state.auth });

export default connect(mapStateToProps)(PrivateRoute);
