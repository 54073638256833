import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { EventAvailable } from '@material-ui/icons';
import { useDispatch, connect } from "react-redux";
import { change } from "redux-form";
import { Field } from "redux-form";
import { refIndexes, lastYear, firstYear } from './constants';
import NumberFormat from 'react-number-format';
import { discountMonthlyAmounts, getDiscountedTotals } from "../physical_injury/calculations"
import { getFrenchDuration, frenchPeriod, getFrenchDurationAdj } from "../utils"

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'left',
    maxWidth:900,
    minWidth:900,
  },

}));

const years = Array.from({ length: lastYear[0] - firstYear + 1 }, (_, i) => firstYear + i).reverse();

const frenchMonths = [
  [1,"janvier"],
  [2,"février"],
  [3,"mars"],
  [4,"avril"],
  [5,"mai"],
  [6,"juin"],
  [7,"juillet"],
  [8,"août"],
  [9,"septembre"],
  [10,"octobre"],
  [11,"novembre"],
  [12,"décembre"],
]

const refIndexKeys = Object.keys(refIndexes);

function DisplayDiscountingFormPGP(props) {
  const { values, entry, index, disabled } = props;
  const today = new Date();
  const line = values[entry][index];
  const currentMonth = today.getMonth()+1;
  let discountingValues = {
    refIndex:"double",
    endMonth:currentMonth,
    endYear:lastYear[0],
  }
    
  if (line.discounting)
    discountingValues = line.discounting
    
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [endMonth, setEndMonth] = React.useState(discountingValues.endMonth)
  const [endYear, setEndYear] = React.useState(discountingValues.endYear)
  const [refIndex, setRefIndex] = React.useState(discountingValues.refIndex)

  const handleOpen = () => {
    if (!line.discounting)
      dispatch(change("caseDataForm", `${entry}[${index}].discounting`, { refIndex: refIndex, endMonth: endMonth, endYear: endYear }));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEndMonthChange = (event, newValue) => {
    setEndMonth(newValue)
  }
  const handleEndYearChange = (event, newValue) => {
    setEndYear(newValue)
  }
  const handleRefIndexChange = (event, newValue) => {
    setRefIndex(newValue)
  }
  let endIndexValue = refIndexes["inflation"]
    .data
    .filter(ent => ent[0] === parseInt(endYear) && ent[1] === parseInt(endMonth))
    .map(entry => entry[2])[0] || 1;
  let discountedRefIncome = discountMonthlyAmounts(line, endIndexValue)
  let discountedTotals = getDiscountedTotals(discountedRefIncome)
  let discountedTotal = discountedTotals.discountedTotal
  let revaluedIncomeTotal = discountedTotals.revaluedIncomeTotal

  let startSmic = refIndexes["smic"]
    .data
    .filter(ent => ent[0] === parseInt(endYear) && ent[1] === parseInt(endMonth))
    .map(entry => entry[2])[0] || 1;
  let startIpc = refIndexes["inflation"].data[0][2];
    
  if (discountedRefIncome.length > 0)
    startSmic = refIndexes["smic"]
      .data
      .filter(ent => ent[0] === discountedRefIncome[0][0] && ent[1] === discountedRefIncome[0][1])
      .map(entry => entry[2])[0] || 1;

  if (discountedRefIncome.length > 0)
    startIpc = refIndexes["inflation"]
      .data
      .filter(ent => ent[0] === discountedRefIncome[0][0] && ent[1] === discountedRefIncome[0][1])
      .map(entry => entry[2])[0] || 1;

  const addDiscounting = () => {
    let discounting = {
      refIndex: refIndex,
      endYear: endYear,
      endMonth: endMonth,
      endIndexValue: endIndexValue,
      discountedRefIncome: discountedRefIncome,
      discountedTotal: discountedTotal,
      indexValue: endIndexValue,
    }
    handleClose();
    dispatch(change("caseDataForm", `${entry}[${index}].discounting`, discounting));
  }

  return (
    <div onClick={((e) => e.stopPropagation())}>
      <button
        type="button"
        disabled={disabled}
        className="btn btn-outline-success btn-sm p-0 ml-1"
        onClick={()=>handleOpen()}
        title={line.discounting ? "Modifier l'actualisation":"Actualiser le montant"}
      >
        <EventAvailable />
      </button>      
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <fieldset className={classes.paper}>
            <h2 id="transition-modal-title" style={{ textAlign:'center' }}>Actualisation d'un revenu&nbsp;<small style={{ fontSize:"0.8rem" }}><i>(version bêta)</i></small></h2>
            <div>
              <strong>Revenu à actualiser :&nbsp;
                {line.periodRate && (line.startDate || line.endDate)&& <>
                                    
                  <NumberFormat
                    displayType="text"
                    thousandSeparator=" "
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={true}
                    type="text"
                    value={line.periodRate}
                    suffix=" €"
                  /> par {getFrenchDuration(line.durationType)}&nbsp;{frenchPeriod(line.startDate, line.endDate)}
                </>}
              </strong>
            </div>
            <div className='form-group input-group pt-2'>

              <div className='row align-items-center' style={{ marginLeft: "0px" }}>
                <label htmlFor="refIndex" className="control-label mb-0">Choix de l'indice et de la méthode d'actualisation :</label>
                <div className='d-flex input-group-sm ml-1'>
                  <Field
                    name={`${entry}[${index}].discounting.refIndex`}
                    id="refIndex"
                    component="select"
                    className="custom-select form-control discounting"
                    style={{ fontSize: "0.8125rem", width: "500px" }}
                    onChange={handleRefIndexChange}
                  >
                    <option value="double">Revalorisation (SMIC horaire brut) et actualisation (IPC hors tabac)</option>
                    {/* <option value="doubleipc">Revalorisation et actualisation (IPC hors tabac)</option> */}
                    <option value="inflation">Actualisation simple ({refIndexes.inflation.label})</option>
                    {/* <option value="smic">Actualisation simple ({refIndexes.smic.label})</option> */}
                                            
                  </Field>
                </div>
              </div>
              {((refIndex === "double") || !refIndex) && `Méthodologie en deux temps : le revenu ${getFrenchDurationAdj(line.durationType)} de référence est revalorisé à chaque évolution du SMIC horaire brut durant la période du préjudice. Le revenu est ensuite actualisé mensuellement jusqu'à la date de votre choix suivant l'indice des prix à la consommation afin de compenser l'érosion monétaire.`}
              {((refIndex === "doubleipc") || !refIndex) && `Méthodologie en deux temps : le revenu ${getFrenchDurationAdj(line.durationType)} de référence est revalorisé pour chaque mois selon l'indice des prix à la consommation durant la période du préjudice. Le revenu est ensuite actualisé mensuellement jusqu'à la date de votre choix suivant l'indice des prix à la consommation afin de compenser l'érosion monétaire.`}
              {(refIndex === "inflation") && `Actualisation IPC : le revenu ${getFrenchDurationAdj(line.durationType)} de référence est actualisé mensuellement jusqu'à la date de votre choix suivant l'indice des prix à la consommation afin de compenser l'érosion monétaire.`}
              {(refIndex === "smic") && `Actualisation SMIC : le revenu ${getFrenchDurationAdj(line.durationType)} de référence est actualisé mensuellement jusqu'à la date de votre choix suivant le SMIC horaire brut afin de compenser l'érosion monétaire.`}
            </div>
            <div className='form-group input-group pt-2'>
              <div className='row align-items-center' style={{ marginLeft: "0px" }}>
                <label htmlFor="endYear" className="control-label mb-0">Année de fin :</label>
                <div className='d-flex input-group-sm ml-1'>
                  <Field
                    name={`${entry}[${index}].discounting.endYear`}
                                            
                    id="endYear"
                    component="select"
                    style={{ fontSize: "0.8125rem", minWidth:"60px" }}
                    className="custom-select form-control"
                    onChange={handleEndYearChange}
                  >
                    {years.map(value =>
                      <option value={value} key={value} default>{value}</option>,
                    )}
                  </Field>
                </div>
                <label htmlFor="endMonth" className="control-label mb-0 ml-4">Mois de fin :</label>
                <div className='d-flex input-group-sm ml-1'>
                  <Field
                    name={`${entry}[${index}].discounting.endMonth`}
                    id="endMonth"
                    component="select"
                    style={{ fontSize: "0.8125rem", minWidth:"100px" }}
                    className="custom-select form-control"
                    onChange={handleEndMonthChange}
                  >                            
                    {frenchMonths.map((value) =>
                      <option value={value[0]} key={value[0]}>{value[1]}</option>,
                    )}
                  </Field>
                </div>
              </div>
            </div>
            <div className='d-flex'>
              <div className='form-group pr-0 pt-1' style={{ minHeight:"300px" }}>
                <div className='mt-2'>
                  <table className="table discounting">
                    <thead style={{ width: "calc( 100% - 1em )" }}>
                      <tr style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                        <th scope="col">Mois</th>
                        <th scope="col" className='text-center'>Revenu de référence</th>
                        {(refIndex === "smic" || refIndex === "double" || !refIndex) &&
                        <>
                          <th scope="col" className='text-center'>Smic horaire brut de référence</th>
                          <th scope="col" className='text-center'>Smic horaire brut</th>
                        </>
                        }
                        {(refIndex === "doubleipc" || !refIndex) &&
                        <>
                          <th scope="col" className='text-center'>Indice de référence</th>
                          <th scope="col" className='text-center'>Indice de revalorisation</th>
                        </>
                        }
                        {(refIndex === "double" || refIndex === "doubleipc" || !refIndex) &&
                        <th scope="col" className='text-center'>Revenu revalorisé</th>
                        }
                        {(refIndex === "inflation" || refIndex === "double" || refIndex === "doubleipc" || !refIndex) &&
                        <>
                          <th scope="col" className='text-center'>Indice de référence</th>
                          <th scope="col" className='text-center'>Indice d'actualisation</th>
                        </>
                        }
                        <th scope="col" className='text-center'>Revenu actualisé</th>
                      </tr>
                    </thead>
                    <tbody className="menu" style={{ overflow:"auto", height:"300px", display: "block" }}>
                      {discountedRefIncome.map((month,i) =>
                        <tr key={i} style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                          <td>{month[1]<10 ? "0":""}{month[1]}/{month[0]}</td>
                          <td className='text-right'>
                            <div className='pr-4'>
                              {/* Revenu de référence */}
                              <NumberFormat
                                displayType="text"
                                thousandSeparator=" "
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                type="text"
                                value={month[2]}
                                suffix=" €"
                              />
                            </div>
                          </td>
                          {(refIndex === "smic" || refIndex === "double" || !refIndex) &&
                          <>
                            <td className="text-center">
                              {/* Smic horaire brut de référence */}
                              <NumberFormat
                                displayType="text"
                                thousandSeparator=" "
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                type="text"
                                value={startSmic}
                                suffix=" €/h"
                              />
                            </td>
                            <td className="text-center">
                              {/* Smic horaire brut */}
                              <NumberFormat
                                displayType="text"
                                thousandSeparator=" "
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                type="text"
                                value={month[3]}
                                suffix=" €/h"
                              />
                            </td>
                          </>
                          }
                          {(refIndex === "doubleipc") &&
                          <>
                            <td className="text-center">
                              {/* IPC de référence */}
                              <NumberFormat
                                displayType="text"
                                thousandSeparator=" "
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                type="text"
                                value={startIpc}
                              />
                            </td>
                            <td className="text-center">
                              {/* IPC à date */}
                              <NumberFormat
                                displayType="text"
                                thousandSeparator=" "
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                type="text"
                                value={month[5]}
                              />
                            </td>
                          </>
                          }
                          {(refIndex === "double" || refIndex === "doubleipc" || !refIndex) && 
                          <td className='text-right'>
                            {/* Revenu revalorisé */}
                            <div className='pr-4'>
                              <NumberFormat
                                displayType="text"
                                thousandSeparator=" "
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                type="text"
                                value={month[4]}
                                suffix=" €"
                              />
                            </div>
                          </td>}
                          {(refIndex === "inflation" || refIndex === "double" || refIndex === "doubleipc" || !refIndex) && 
                          <>
                            <td className='text-center'>
                              {/* Indice de référence */}
                              <NumberFormat
                                displayType="text"
                                thousandSeparator=" "
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                type="text"
                                value={month[5]}
                                suffix=""
                              />
                            </td>
                            <td className="text-center">
                              {/* Indice d'actualisation */}
                              <NumberFormat
                                displayType="text"
                                thousandSeparator=" "
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                type="text"
                                value={endIndexValue}
                                suffix=""
                              />
                            </td></>}
                          <td className='text-right'>
                            {/* Revenu actualisé */}
                            <div className='pr-4'>
                              <NumberFormat
                                displayType="text"
                                thousandSeparator=" "
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                type="text"
                                value={month[6]}
                                suffix=" €"
                              /></div>
                          </td>

                        </tr>,
                      )}
                    </tbody>
                    <tfoot>
                      <tr  style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                        {(refIndex === "double" || refIndex === "doubleipc" || !refIndex) && 
                        <td colSpan={5} className="text-right">
                          <div className='pr-2'>
                            <strong className='pr-4'>
                                                            Revenu revalorisé total :&nbsp;<NumberFormat
                                                              displayType="text"
                                                              thousandSeparator=" "
                                                              decimalSeparator=","
                                                              decimalScale={2}
                                                              fixedDecimalScale={true}
                                                              type="text"
                                                              value={revaluedIncomeTotal}
                                                              suffix=" €"
                              />
                            </strong></div>
                        </td>}
                        <td colSpan={(refIndex === "double" || refIndex === "doubleipc" || !refIndex) ? 3 : 8} className="text-right">
                          <div className='pr-2'>
                            <strong className='pr-4'>
                                                            Montant actualisé :&nbsp;<NumberFormat
                                                              displayType="text"
                                                              thousandSeparator=" "
                                                              decimalSeparator=","
                                                              decimalScale={2}
                                                              fixedDecimalScale={true}
                                                              type="text"
                                                              value={discountedTotal}
                                                              suffix=" €"
                              />
                            </strong></div>
                        </td>
                      </tr>

                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
            <div className='text-right'>
              <button className="btn btn-sm btn-primary mt-auto" onClick={()=>addDiscounting(index)}>Enregistrer l'actualisation</button>
            </div>
            <div className='pt-2' style={{ minHeight:"50px" }}>
              <em>Sources : <a href={refIndexes["smic"].sourceUrl} target="_blank" rel="noopener noreferrer">{refIndexes["smic"].sourceLabel}</a> et <a href={refIndexes["inflation"].sourceUrl} target="_blank" rel="noopener noreferrer">{refIndexes["inflation"].sourceLabel}</a></em>
            </div>
            <div className='pt-2' style={{ minHeight:"50px" }}>
              <em className="small">Les indices sont actualisés dans Quantum dès leur parution. Pour les indices de prix à la consommation, cette parution est postérieure de quelques jours / semaines par rapport au mois concerné.</em>
            </div>
          </fieldset>
        </Fade>
      </Modal>
    </div>
  );
}


export default connect()(DisplayDiscountingFormPGP);
