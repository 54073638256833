import { TableCell, TableRow, Table, TableContainer } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { palette } from "./theme";
export var StyledTableCell = withStyles(function (localTheme) { return ({
    head: {
        backgroundColor: localTheme.palette.secondary.light,
        color: localTheme.palette.secondary.main,
        padding: '6px 16px',
        lineHeight: '18px',
    },
    body: {
        color: localTheme.palette.secondary.main,
        padding: '6px 16px',
    },
    footer: {
        color: localTheme.palette.secondary.main,
        padding: '6px 16px',
    },
}); })(TableCell);
export var StyledTableRow = withStyles(function () { return ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: palette.secondary.extraLight, // workaround as we can't add shades to palette colors with MUI v4
        },
    },
}); })(TableRow);
export var ResultsTable = withStyles({
    root: {
        position: 'relative',
        border: 'none',
        borderCollapse: 'collapse',
        '& table': {
            width: '100%',
        },
        '& td, & th': {
            border: 'none',
        },
        '& th': {
            textAlign: 'left',
        },
        '&:first-child': {
            borderLeft: 'none!important', // Add left border for the first cell
        },
    },
})(Table);
export var ResultsTableWithRoundedCorners = withStyles(function (localTheme) { return ({
    root: {
        border: "1px solid ".concat(localTheme.palette.secondary.light),
        borderRadius: '8px',
        overflow: 'hidden',
    },
}); })(TableContainer);
export var ResultsTableCell = withStyles(function (localTheme) { return ({
    body: {
        color: localTheme.palette.secondary.main,
    },
    root: {
        '&:first-child': {
            borderLeft: 'none', // Add left border for the first cell
        },
    },
}); })(TableCell);
export var ResultsTableRow = withStyles(function (localTheme) { return ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: localTheme.palette.info.light,
        },
    },
}); })(TableRow);
