import React from 'react'
import GridOnIcon from '@material-ui/icons/GridOn';
import DescriptionIcon from '@material-ui/icons/Description';
import axios from "axios";


const downloadFile = (url, token) => {
  return () => {
    const header = {
      Authorization: `Token ${token}`,
    }
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
      headers: header,
    })
      .then((response) => {
        const [, filename] = response.headers['content-disposition'].split('filename=');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };
}

let MergeButton = props => {
  const { caseId, token, victimId } = props;
  let baseURL = `/api/gen/${caseId}/?v=`
  if (victimId) baseURL += victimId
  baseURL += "&t="

  return (
    <div className="pl-1 pt-1 pr-4">
      <div className="text-center">Fusionner pour</div>
      <div className="d-flex">
        <div className="text-center"><button onClick={downloadFile(baseURL + "xlsx", token)} className="btn btn-primary btn-sm" title="Télécharger le dossier au format Excel">
          <GridOnIcon />
        </button><br /><span className="merge-label" onClick={downloadFile(baseURL + "xlsx", token)}>Excel</span></div>
        <div className="text-center ml-1"><button onClick={downloadFile(baseURL + "full", token)} className="btn btn-primary btn-sm ml-1" title="Télécharger le dossier au format Word">
          <DescriptionIcon />
        </button><br /><span className="merge-label" onClick={downloadFile(baseURL + "full", token)}>Word</span></div>
      </div>
    </div>
  )
}

export default MergeButton;
