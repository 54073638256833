import React from "react";
import PropTypes from "prop-types";
import TpIcon from "../../layout/icons/TpIcon"

const BtnAddTPPLine = props => {
  const { addTPPLine, additionalTitle } = props;
  return (
    <button
      type="button"
      className="btn btn-outline-warning btn-sm p-0 mr-1"
      onClick={addTPPLine}
      title={`Ajouter un paiement de tiers soumis à recours${additionalTitle ? additionalTitle : ""}`}
    >
      <TpIcon />
    </button>
  );
};

BtnAddTPPLine.propTypes = {
  addTPPLine: PropTypes.func.isRequired,
};

export default BtnAddTPPLine;
