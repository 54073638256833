import React from "react";
import { Close, Edit } from '@material-ui/icons/';
import { localTheme, useStyles } from "./theme";
export var EditableTabLabel = function (_a) {
    var tabId = _a.tabId, currentTitle = _a.currentTitle, onSave = _a.onSave, onDelete = _a.onDelete;
    var _b = React.useState(false), isEditing = _b[0], setIsEditing = _b[1];
    var _c = React.useState(currentTitle), newTitle = _c[0], setNewTitle = _c[1];
    var classes = useStyles();
    var handleSave = function () {
        onSave(tabId, newTitle);
        setIsEditing(false);
    };
    var displayContent;
    if (isEditing) {
        displayContent = (React.createElement("div", { style: { display: 'flex', alignItems: 'center', gap: 4 }, title: currentTitle },
            React.createElement("input", { value: newTitle, onChange: function (e) { return setNewTitle(e.target.value); }, onBlur: handleSave, onKeyDown: function (e) {
                    if (e.key === 'Enter') {
                        handleSave();
                        setIsEditing(false);
                    }
                }, style: {
                    border: "1px solid ".concat(localTheme.palette.primary.main),
                    backgroundColor: 'white',
                    color: localTheme.palette.primary.main,
                    height: '28px',
                    borderRadius: '4px',
                    padding: '0 8px',
                    fontSize: '0.875rem'
                }, autoFocus: true })));
    }
    else {
        var displayedTitle = currentTitle.length > 43 ? currentTitle.slice(0, 43).trim() + '...' : currentTitle;
        displayContent = (React.createElement("div", { style: { display: 'flex', alignItems: 'center', gap: 4 }, title: currentTitle },
            React.createElement("span", { style: { fontWeight: 500 } }, displayedTitle),
            React.createElement("div", { title: "Modifier le titre de ce calcul", className: classes.editTabButton },
                React.createElement(Edit, { fontSize: "small", style: { fontSize: '0.9rem', marginBottom: '8px' }, onClick: function () { return setIsEditing(true); } }))));
    }
    return (React.createElement("div", { className: classes.tabLabel },
        displayContent,
        React.createElement("div", { className: classes.deleteTabButton, title: "Supprimer ce calcul", onClick: function (e) {
                e.stopPropagation();
                onDelete(tabId);
            } },
            React.createElement(Close, { fontSize: "small" }))));
};
