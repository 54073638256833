import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useDispatch } from "react-redux";
import { change } from "redux-form";


const useStyles = makeStyles({
  table: {
    //        minWidth: 650,
  },
});



export default function DisplayTableDFP(props) {
  const { header, rows, caption, firstColumn, damageRate, age, lineIndex, currentTab, handleClose } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  let cellTitle = "";
  if (currentTab !== 2) cellTitle = "Cliquez sur cette valeur pour la sélectionner sur cette ligne de DFP";

  let columnHighlight, rowHighlight;
    
  if (currentTab === 0 || currentTab === 1) {
    if (age !== null && age !== undefined)
      columnHighlight = Math.min(Math.trunc(Math.max(0, age - 1) / 10) + 1, 9);
    if (damageRate !== undefined && damageRate > 0)
      rowHighlight = Math.min(Math.trunc(Math.max(0, damageRate - 1) / 5), 19);
  } else {
    if (age !== null && age !== undefined)
      rowHighlight = Math.min(Math.trunc(Math.max(0, age - 1) / 10), 9)
    if (damageRate !== undefined && damageRate > 0)
      if (damageRate <= 5)
        columnHighlight = 1;
      else if (damageRate <= 15)
        columnHighlight = 2;
      else if (damageRate <= 25)
        columnHighlight = 3;
      else if (damageRate <= 35)
        columnHighlight = 4;
      else if (damageRate <= 45)
        columnHighlight = 5;
      else if (damageRate <= 55)
        columnHighlight = 6;
      else if (damageRate <= 65)
        columnHighlight = 7;
      else if (damageRate <= 75)
        columnHighlight = 8;
      else if (damageRate <= 85)
        columnHighlight = 9;
      else
        columnHighlight = 10;
  }

  const setPointValue = amount => {
    if (currentTab !== 2){
      dispatch(change("caseDataForm", `DFP0[${lineIndex}].amountDirectInput`, false));
      dispatch(change("caseDataForm", `DFP0[${lineIndex}].pointValue`, amount));
    } else {
      dispatch(change("caseDataForm", `DFP0[${lineIndex}].amountDirectInput`, true));
      dispatch(change("caseDataForm", `DFP0[${lineIndex}].lineAmount`, amount));
    }
    handleClose();
  }
  
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="simple table">
        <caption style={{ captionSide: "top", textAlign: "center", height: "4em" }}>
          {caption.map((label, i) => <Fragment key={i}>{label}<br /></Fragment>)}
        </caption>
        <TableHead>
          {currentTab !== 2 && <TableRow><TableCell colSpan={header.length} align="center"><strong style={{ color:"red" }}>NOUVEAU ! Cliquez sur une valeur du point du barème pour la sélectionner automatiquement sur cette ligne de DFP</strong></TableCell></TableRow>}
          <TableRow>
            {header.map((label, i) => (
              <TableCell key={i} align="center" className={i === columnHighlight ? "cellHighlight" : ""}>{label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow key={i} className={(i === rowHighlight ? "cellHighlight" : "")}>
              {row.map((value, j) => (
                <TableCell key={j} className={(j === 0 && firstColumn) ? "firstCol" : (j === columnHighlight) ? ((i === rowHighlight) ? "crossCell" : "cellHighlight") : ""} style={{ cursor: "pointer" }} onClick={() => setPointValue(value)} align="center" title={cellTitle}>
                  {value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer >
  );
}
